import { memo } from 'react'
import NoEntriesIcon from '@/assets/icons/NoEntriesIcon'
import { Container, SupportFile } from './styles'

const SupportGuide = ({ url }) => {
  return (
    <Container>
      {url ? (
        <SupportFile title="support-guide" src={url} />
      ) : (
        <NoEntriesIcon />
      )}
    </Container>
  )
}

export default memo(SupportGuide)
