import styled from 'styled-components'
import { Table } from 'antd'

export const PaymentTable = styled(Table)`
  white-space: pre;

  .ant-table-thead .ant-table-cell {
    color: #8c8f9f;
    font-size: 0.75em;
    text-align: left;
  }

  .ant-table-tbody {
    color: #272d4e;
    font-size: 0.875em;
    letter-spacing: 0.0075em;
    text-align: left;
  }

  .ant-pagination-item-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.125rem 0.25rem;
    gap: 0.5rem;
    width: 1.25rem;
    height: 1.25em;
    background: #ffffff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16),
      0 0.125rem 0.25rem rgba(89, 96, 120, 0.1);
    border-radius: 0.625rem;
  }

  .ant-pagination-item {
    min-width: 22px;
    height: 1.25em;
    line-height: 21px;
    border: 0;
    backgroud-color: #f7fafe;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 1.25em;
    border: 0;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 19px;
  }

  .ant-select-arrow {
    color: #272d4e;
  }

  .ant-table-column-title {
    z-index: 0;
  }

  .table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
    background-color: #f7fafe;
  }

  .table-row-red {
    background-color: #fff7fa;
  }
  width: 100%;
`

export const EmptyText = styled.div`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 1.25em;
  color: #a3a6b9;
`

export const EmptyDescription = styled.div`
  font-family: 'DM Sans';
  font-size: 1rem;
  color: #a3a6b9;
`

export const VectorIcon = styled.img``

export const PaymentTableWrapper = styled.div``

export const ProductWrapper = styled.div`
  font-family: 'DM Sans';
  color: #272d4e;
  cursor: pointer;
`

export const BulletTextWrapper = styled.ul`
  padding: 0.625rem 0 0.625rem 0.875rem;
  margin: 0;
`

export const SurveyMain = styled.div`
  display: flex;
  flex-direction: column;
`

export const SurveyNameWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1.25em;
  letter-spacing: 0.0075em;
  color: #272d4e;
  cursor: pointer;
`

export const SurveyCodeWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.25em;
  color: #8c8f9f;
`

export const ProductTypeWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.25em;
  text-transform: uppercase;
  color: #8c8f9f;
`

export const CountryWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.25em;
  color: #272d4e;
`

export const UnprocessedWrapper = styled.div`
  display: flex;
  flex-basis: content;
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1rem;
  color: #4f5aed;
  padding: 0.125rem 0.625rem;
  background: #f0f1fa;
  border-radius: 0.625rem;
`

export const NoEntriesWrapper = styled.div`
  padding: 0.125rem 0.625rem;
  background: #e5eaf4;
  border-radius: 0.625rem;
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1rem;
  color: #8c8f9f;
`

export const LiveWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: 'DM Sans';
  font-weight: 700;
  font-size: 0.625rem;
  color: #367f98;
`

export const UnprocessedMain = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const NoEntriesMain = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
`

export const LastUpdatedWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 0.75em;
  line-height: 1rem;
  color: #272d4e;
  opacity: 0.6;
  margin-top: 0.5rem;
`

export const TotalValidWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.875em;
  text-align: right;
  letter-spacing: 0.0075em;
  color: #4ab9bb;
  text-align: left;
`

export const TotalInvalidWrapper = styled(TotalValidWrapper)`
  color: #da2f58;
`

export const TotalEscalatedWrapper = styled(TotalValidWrapper)`
  color: #a969fc;
`

export const Text = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
