import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getValidatedByList } from '@/apis/validationAPI'
import messageError from '@/utils/messageError'
import { Wrapper, Text, SelectBox } from './styles'

const FilterValidatedBy = ({ validators, setFilterValidatedBy }) => {
  const { t } = useTranslation()
  const [validatorList, setValidatorList] = useState([])

  const fetchValidatedByListData = async () => {
    getValidatedByList()
      .then(({ data }) => {
        setValidatorList(data.data)
      })
      .catch(({ errorCode }) => {
        if (errorCode.message === 'Unauthorized') {
          errorCode = errorCode.message
        }

        messageError({
          modal: true,
          code: errorCode,
          defaultError: 'There was a problem with our server. Please try again or contact support.'
        })
      })
  }

  useEffect(() => {
    fetchValidatedByListData()
  }, [])

  const filterOption = (input = '', option) => {
    const lowerCaseSearch = input.toLowerCase()
    const lowerCaseValue = option.value.toLowerCase()
    const lowerCaseLabel = option.children.toLowerCase()

    return (
      lowerCaseValue.includes(lowerCaseSearch) ||
      lowerCaseLabel.includes(lowerCaseSearch)
    )
  }

  const handleValidatorChange = (value) => {
    setFilterValidatedBy(value)
  }

  return (
    <React.Fragment>
      <Wrapper>
        <Text>{t('components.survey.entries.filters.validated')}</Text>
        <SelectBox
          defaultValue={validators}
          value={validators}
          mode="multiple"
          placeholder="All"
          showArrow
          showSearch
          filterOption={filterOption}
          onChange={handleValidatorChange}
        >
          {validatorList.map((validator) => (
            <SelectBox.Option key={validator.user_id} value={validator.user_id}>
              {validator.fullName}
            </SelectBox.Option>
          ))}
        </SelectBox>
      </Wrapper>
    </React.Fragment>
  )
}

export default FilterValidatedBy
