import styled from 'styled-components'

export const FilterWrapper = styled.div`
  border-radius: 0.75em;
  box-shadow: 0 0.125rem 0.25rem -2px rgba(0, 0, 0, 0.04),
    0 0.625rem 0.75em rgba(0, 0, 0, 0.04), 0 0.625rem 1.25em 0.25rem rgba(0, 0, 0, 0.04);
  background: white;
  width: 630px;
`

export const FilterHeader = styled.div`
  padding: 1rem 1rem 0 1rem;
  border-bottom: 1px #d9d9d9 dotted;
  display: flex;
  justify-content: space-between;
`
export const FilterBody = styled.div`
  padding: 1rem;
`
export const FilterBodyContent = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FilterFooter = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  border-top: 1px #d9d9d9 dotted;
`
