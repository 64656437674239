import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 0.75rem;
`

export const ListContainer = styled.div`
  width: 25rem;
  max-height: 30rem;
  overflow: auto;
`

export const Action = styled.span`
  cursor: pointer;
`

export const User = styled.section`
  &:not(:first-child) {
    padding-top: 0.5rem;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 0.5rem;
  }
`
export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.marginBottom || 0};
`

export const Information = styled(FlexBetween)`
  line-height: 2rem;

  label {
    color: #8c8f9f;
    font-size: 0.875rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const TextMedium = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.875rem;
  font-weight: 500;
  padding-left: 0.5rem;

  &.red {
    color: #da2f58;
  }

  &.green {
    color: #4ab9bb;
  }
`
export const Warpper = styled.div`
  display: flex;

  gap: 3px;
  align-items: center;
  &.red {
    color: #da2f58;
  }
  ${props =>
    props.check ? 'pointer-events: none; opacity: 0.4;' : 'cursor: pointer'}
`

export const GlobalIcon = styled.img``
