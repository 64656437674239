import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Popover, Button, Tooltip } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { Container, ListContainer, User, GlobalIcon, Warpper } from './styles'
import { Information, TextMedium } from '../styles'
import { updateUser } from '@/apis/userAPI'
import UserGlobe from '@/assets/images/UserIP.svg'

const Element = ({ selectedEnrollment }) => {
  const { t } = useTranslation()

  const {
    userId,
    browserInfo: { ipAddress },
    sameUsers,
    globalIpCount,
    globalSurveyCount,
    blackListed
  } = selectedEnrollment || {}

  const sameIPUsers = useMemo(() => {
    return sameUsers.filter(item => {
      return item._id !== userId
    })
  }, [userId])

  const onSubmit = id => {
    updateUser(id, { blackListed: true })
    // fetchData()
  }

  const currentUser = sameUsers?.find(item => item.userDetail._id === userId)
  return (
    <Container>
      <Information>
        <label>{t('components.sameIp')}</label>

        <Tooltip title={ipAddress}>
          <TextMedium>{ipAddress}</TextMedium>
        </Tooltip>
      </Information>

      <Information>
        <label>{t('components.sameIpUser')}</label>

        <Popover
          title={t('components.popover.head')}
          placement='left'
          content={
            <ListContainer>
              {sameIPUsers.length === 0 && <div>No same IP users</div>}

              {sameIPUsers.map(item => {
                const userDetail = item.userDetail

                return (
                  <User>
                    <Information>
                      <label>
                        {t('components.enrollmentInformation.userId')}
                      </label>
                      <TextMedium>{item._id}</TextMedium>
                    </Information>

                    <Information>
                      <label>
                        {t('components.enrollmentInformation.userName')}
                      </label>
                      <TextMedium>{userDetail.fullName}</TextMedium>
                    </Information>

                    <Information>
                      <label>
                        {t('components.enrollmentInformation.userEmail')}
                      </label>
                      <TextMedium>{userDetail.emailAddress}</TextMedium>
                    </Information>

                    <Information>
                      <label>
                        {t('components.enrollmentInformation.userPaypal')}
                      </label>
                      <TextMedium>{userDetail.paypalEmailAddress}</TextMedium>
                    </Information>

                    <Information>
                      <label>
                        {t('components.enrollmentInformation.isBlackList')}
                      </label>

                      {!userDetail.blackListed && (
                        <Button
                          type='primary'
                          size='small'
                          danger
                          onClick={() => {
                            onSubmit(item._id)
                          }}
                        >
                          {t('components.enrollmentInformation.addToBlackList')}
                        </Button>
                      )}

                      {userDetail.blackListed && (
                        <TextMedium className='red'>Yes</TextMedium>
                      )}
                    </Information>
                  </User>
                )
              })}
            </ListContainer>
          }
        >
          <TextMedium className='red'>
            <UserOutlined /> <strong>{sameIPUsers.length}</strong>
          </TextMedium>
        </Popover>
      </Information>
      <Information>
        <label>{t('components.globalCount')}</label>
        <Popover
          title={t('components.globalCount')}
          placement='left'
          content={
            <ListContainer>
              {globalIpCount === 0 && (
                <div>{t('components.noSameIpUsers')}</div>
              )}

              <User>
                <Information>
                  <label>{t('components.totalSubmissions')}</label>
                  <TextMedium>{globalIpCount}</TextMedium>
                </Information>
                <Information>
                  <label>{t('components.totalSurveys')}</label>
                  <TextMedium>{globalSurveyCount}</TextMedium>
                </Information>
                <Information>
                  <label>
                    {t('components.enrollmentInformation.isBlackList')}
                  </label>

                  {!blackListed && (
                    <Button
                      type='primary'
                      size='small'
                      danger
                      onClick={() => {
                        onSubmit(currentUser._id)
                      }}
                    >
                      {t('components.enrollmentInformation.addToBlackList')}
                    </Button>
                  )}

                  {blackListed && <TextMedium className='red'>Yes</TextMedium>}
                </Information>
              </User>
            </ListContainer>
          }
        >
          <Warpper className='red'>
            <GlobalIcon src={UserGlobe} width={14} height={17} />
            <strong>{globalIpCount}</strong>
          </Warpper>
        </Popover>
      </Information>
    </Container>
  )
}

export default Element
