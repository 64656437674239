import styled from 'styled-components'

export const VideoContainer = styled.div`
  position: relative;

  video {
    border-radius: 0.75em;
  }

  .externalButton {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;

    svg {
      border-top-right-radius: 0.75em;
    }
  }
`
