import styled from 'styled-components'

export const Container = styled.div`
  position: relative;

  a {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 1000;

    svg {
      border-top-right-radius: 0.75em;
    }
  }
`

export const Photo = styled.div`
  width: 200px;
  height: 200px;
  background-color: #e5eaf4;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 0.75em;
`
