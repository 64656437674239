import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const RoutePrivate = ({ children, ...props }) => {
  const { authenticatedUser } = useSelector(state => state.authentication)

  return (
    <Route
      {...props}
      render={() =>
        authenticatedUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/'
            }}
          />
        )
      }
    />
  )
}

export default RoutePrivate
