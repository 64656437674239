import styled from 'styled-components'
import { Checkbox } from 'antd'

export const MainLayout = styled.div`
  display: flex;
  justify-content: space-between;
`
export const SelectionHeaderMain = styled.div`
  display: flex;
  margin: 18px 0 18px 0.625rem;
`

export const AntCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background: #367f98;
    border-radius: 0.25rem;
    border-color: #367f98;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #367f98;
  }
`

export const SelectedText = styled.div`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.25em;
  margin: 0 35px 0 1rem;
  align-items: center;
  color: #272d4e;
`

export const SelectAllText = styled.div`
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1.25em;
  align-items: center;
  letter-spacing: 0.0075em;
  color: #367f98;
  cursor: pointer;
`

export const SelectedMain = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const SetSelectedText = styled.div`
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.25em;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #8c8f9f;
`

export const ValidText = styled.div`
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.25em;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #4ab9bb;
  cursor: pointer;
`

export const InvalidText = styled.div`
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.25em;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #da2f58;
  cursor: pointer;
`
