import * as React from "react"

const CommentIcon = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m10.368 13.197.13.483-.13-.483Zm2.828-2.829-.483-.129.483.13ZM2.96 13.04l-.354.354.354-.354Zm10.237-7.409-.483.13.483-.13ZM2.166 8v3.333h1V8h-1Zm2.5 5.833h3.333v-1H4.666v1Zm3.333 0c1.208 0 1.91.005 2.498-.153l-.258-.966c-.43.115-.968.12-2.24.12v1ZM12.833 8c0 1.272-.005 1.81-.12 2.239l.966.259c.158-.588.154-1.29.154-2.498h-1Zm-2.336 5.68a4.5 4.5 0 0 0 3.182-3.182l-.966-.259a3.5 3.5 0 0 1-2.474 2.475l.258.966Zm-8.331-2.347c0 .457-.001.85.041 1.164.044.328.144.642.398.897l.707-.707c-.038-.038-.085-.106-.114-.323-.03-.231-.032-.545-.032-1.03h-1Zm2.5 1.5c-.486 0-.8 0-1.03-.032-.218-.03-.285-.076-.324-.114l-.707.707c.255.255.57.354.897.398.313.042.707.041 1.164.041v-1ZM7.999 2.167c-1.207 0-1.91-.005-2.498.153l.26.966c.428-.115.966-.12 2.238-.12v-1ZM3.166 8c0-1.272.004-1.81.12-2.24l-.967-.258c-.157.588-.153 1.29-.153 2.498h1Zm2.335-5.68A4.5 4.5 0 0 0 2.32 5.502l.966.259A3.5 3.5 0 0 1 5.76 3.286l-.259-.966ZM8 3.167c1.272 0 1.81.004 2.24.119l.258-.966C9.91 2.162 9.207 2.167 8 2.167v1ZM13.833 8c0-1.208.004-1.91-.154-2.498l-.966.259c.115.429.12.967.12 2.239h1Zm-3.595-4.714a3.5 3.5 0 0 1 2.475 2.475l.966-.26a4.5 4.5 0 0 0-3.182-3.181l-.258.966Z"
      fill="url(#a)"
    />
    <path
      d="M6 6.667h4"
      stroke="url(#b)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 9.334h2"
      stroke="url(#c)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="a"
        x1={3.454}
        y1={4}
        x2={14.914}
        y2={8.546}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4EA3BF" />
        <stop offset={1} stopColor="#1C9ABA" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={6.296}
        y1={6.792}
        x2={7.709}
        y2={9.035}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4EA3BF" />
        <stop offset={1} stopColor="#1C9ABA" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={6.148}
        y1={9.459}
        x2={7.674}
        y2={10.669}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4EA3BF" />
        <stop offset={1} stopColor="#1C9ABA" />
      </linearGradient>
    </defs>
  </svg>
)

export default CommentIcon
