import { configureStore } from '@reduxjs/toolkit'

import logger from 'redux-logger' //Adding redux logger

import reducer from './combinedReducers'

const devMiddleWares = []

if (process.env.NODE_ENV === 'development') {
  devMiddleWares.push(logger)
}

export default configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(devMiddleWares)
})
