import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Popover, Button, Tooltip } from 'antd'
import UserGlobe from '@/assets/images/UserIP.svg'
import UsersModal from './UsersModal'

import { UserOutlined } from '@ant-design/icons'
import {
  Container,
  ListContainer,
  User,
  Information,
  TextMedium,
  Warpper,
  GlobalIcon
} from './styles'
import { updateUser, blackListAllUsers } from '@/apis/userAPI'

const Element = ({ selectedEnrollment, setSelectedEnrollment }) => {
  let {
    userId,
    browserInfo: { ipAddress },
    sameUsers,
    globalIpCount,
    globalData
  } = selectedEnrollment || {}

  const { t } = useTranslation()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [isGlobalData, setIsGlobalData] = useState(globalData)

  const sameIPUsersData = useMemo(() => {
    return sameUsers.filter(item => {
      return item._id !== userId
    })
  }, [userId, sameUsers])
  const globalIPUsersData = useMemo(() => {
    return globalData.filter(item => {
      return item.userId !== userId
    })
  }, [userId, globalData])

  const onSubmit = async id => {
    const updatedUser = await updateUser(id, { blackListed: true })
    if (updatedUser.data.affected > 0) {
      const sameUser = selectedEnrollment.sameUsers.find(
        user => user._id === id
      )
      // Update the blackListed value if the sameUser object is found
      if (sameUser) {
        sameUser.userDetail.blackListed = true
        setSelectedEnrollment({ ...selectedEnrollment })
      }

      const globalUser = globalData.find(user => user.userId === id)
      if (globalUser) {
        globalUser.blackListed = true
        setIsGlobalData([...globalData])
      }
    }
  }

  const blackListAll = async data => {
    const users = data
      .filter(user => !user.blackListed)
      .map(user => user.userId)
    const updatedUser = await blackListAllUsers(users)
    if (updatedUser.data.affected > 0) {
      window.location.reload()
    }
  }

  const handleClick = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <Container>
      <Information>
        <label>{t('components.sameIp')}</label>

        <Tooltip title={ipAddress}>
          <TextMedium>{ipAddress}</TextMedium>
        </Tooltip>
      </Information>

      <Information>
        <Tooltip title={t('components.sameIpUser')}>
          <label>{t('components.sameIpUser')}</label>
        </Tooltip>
        <Popover
          title={t('components.popover.head')}
          placement='left'
          content={
            <ListContainer>
              {sameIPUsersData.length === 0 && (
                <div>{t('components.noSameIpUsers')}</div>
              )}

              {sameIPUsersData.map(item => {
                const userDetail = item.userDetail

                return (
                  <User>
                    <Information>
                      <label>
                        {t('components.enrollmentInformation.userId')}
                      </label>
                      <TextMedium>{item._id}</TextMedium>
                    </Information>

                    <Information>
                      <label>
                        {t('components.enrollmentInformation.userName')}
                      </label>
                      <TextMedium>{userDetail.fullName}</TextMedium>
                    </Information>

                    <Information>
                      <label>
                        {t('components.enrollmentInformation.userEmail')}
                      </label>
                      <TextMedium>{userDetail.emailAddress}</TextMedium>
                    </Information>

                    <Information>
                      <label>
                        {t('components.enrollmentInformation.userPaypal')}
                      </label>
                      <TextMedium>{userDetail.paypalEmailAddress}</TextMedium>
                    </Information>

                    <Information>
                      <label>
                        {t('components.enrollmentInformation.isBlackList')}
                      </label>

                      {!userDetail.blackListed && (
                        <Button
                          type='primary'
                          size='small'
                          danger
                          onClick={() => {
                            onSubmit(item._id)
                          }}
                        >
                          {t('components.enrollmentInformation.addToBlackList')}
                        </Button>
                      )}

                      {userDetail.blackListed && (
                        <TextMedium className='red'>{'Blacklisted'}</TextMedium>
                      )}
                    </Information>
                  </User>
                )
              })}
            </ListContainer>
          }
        >
          <TextMedium className='red'>
            <UserOutlined />
            <strong>{sameIPUsersData.length}</strong>
          </TextMedium>
        </Popover>
      </Information>
      <Information>
        <Tooltip title={t('components.globalCount')}>
          <label>{t('components.globalCount')}</label>
        </Tooltip>
        <Tooltip title={t('components.enrollmentInformation.seeUsersList')}>
          <Warpper
            check={globalIPUsersData.length > 0 ? false : true}
            className='red'
            onClick={handleClick}
          >
            <GlobalIcon src={UserGlobe} width={14} height={17} />
            <strong>{globalIPUsersData.length}</strong>
          </Warpper>
        </Tooltip>
      </Information>
      {isModalOpen && (
        <UsersModal
          userId={userId}
          globalData={isGlobalData}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          onSubmit={onSubmit}
          blackListAll={blackListAll}
        />
      )}
    </Container>
  )
}

export default Element
