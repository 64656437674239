import React from 'react'
import { useTranslation } from 'react-i18next'
import CardOverlay from './CardOverlay'
import {
  CardLayout,
  InfoWrapper,
  KeyWrapper,
  SpaceBetween,
  ValueWrapper,
  ImageContainer
} from './styles'

const ReceiptCard = ({
  receipt,
  index,
  selectedReceipts,
  handleSingleCheckbox,
  handleReceiptClick,
  receiptDetail,
  handleCreateDuplicate,
  handleDeleteDuplicate
}) => {
  const { t } = useTranslation()
  const { value, fullName, emailAddress } = receipt

  return (
    <>
      <CardLayout>
        <ImageContainer imageUrl={value[0]}></ImageContainer>
        <CardOverlay
          receipt={receipt}
          selectedReceipts={selectedReceipts}
          handleSingleCheckbox={handleSingleCheckbox}
          handleReceiptClick={handleReceiptClick}
          receiptDetail={receiptDetail}
          handleCreateDuplicate={handleCreateDuplicate}
          handleDeleteDuplicate={handleDeleteDuplicate}
          index={index}
        />
      </CardLayout>
      <InfoWrapper>
        <SpaceBetween>
          <KeyWrapper>{t('components.receiptCard.userName')}</KeyWrapper>
          <ValueWrapper>{fullName}</ValueWrapper>
        </SpaceBetween>
        <SpaceBetween>
          <KeyWrapper>
            {t('components.enrollmentInformation.userEmail')}
          </KeyWrapper>
          <ValueWrapper>{emailAddress}</ValueWrapper>
        </SpaceBetween>
      </InfoWrapper>
    </>
  )
}

export default ReceiptCard
