import styled from 'styled-components'

export const SelectCardWrapper = styled.div`
  padding: 0px 16px 0px 16px;
`

export const ReceiptCardsWrapper = styled.div`
  height: calc(100vh - 270px);
  overflow-y: auto;
  overflow-x: hidden;
  display: ${(props) => (props.showIcon ? 'flex' : null)};
  align-items: ${(props) => (props.showIcon ? 'center' : null)};
  justify-content: ${(props) => (props.showIcon ? 'center' : null)};
`

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
