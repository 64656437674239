import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { Col, Row, Spin } from 'antd'
import moment from 'moment'

import ReceiptCard from './ReceiptCard'
import ReceiptDetail from './ReceiptDetail'
import NoEntriesIcon from '@/assets/icons/NoEntriesIcon'

import { getNextProductUser } from '@/utils/helperFunctions'
import {
  updateOptions,
  openMarkEntryNotification,
  openMarkedDuplicateNotification,
  openUnmarkDuplicateNotification
} from '@/utils/receipts'
import {
  getSurveyProductsReceipts,
  getProductsReceipts,
  createDuplicate,
  deleteDuplicate,
  checkProductUsers,
  markEntryForReceipts
} from '@/apis/validationAPI'

import SelectProduct from './Products'
import SelectActions from './SelectActions'
import { SelectCardWrapper, ReceiptCardsWrapper, MenuWrapper } from './styles'

const Receipts = ({ activeTab }) => {
  const { id } = useParams()
  const { userEmail, authenticatedUser } = useSelector(
    state => state.authentication
  )
  const [products, setProducts] = useState([])
  const [receipts, setReceipts] = useState([])

  const [selectedReceipts, setSelectedReceipts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [receiptDetail, setReceiptDetail] = useState({})
  const [productUsers, setProductUsers] = useState([])
  const [approveValue, setApproveValue] = useState()
  const [showValidInvalidEntry, setShowValidInvalidEntry] = useState(false)
  const [nextDisable, setNextDisable] = useState(false)
  const [previousDisable, setPreviousDisable] = useState(false)
  const [validValue, setValidValue] = useState('')

  // Fetching Products.
  const query = useQuery({
    queryKey: ['survey-receipts'],
    queryFn: () => getSurveyProductsReceipts(id),
    refetchOnWindowFocus: false,
    onSuccess: response => {
      setProducts(response.allSurveyProducts)
      setSelectedProduct(response?.allSurveyProducts[0]?._id)
    }
  })

  // Fetching Receipts.
  const { refetch, isFetching: isReceiptsLoading } = useQuery({
    queryKey: ['product-receipts'],
    queryFn: () => getProductsReceipts(id, selectedProduct, activeTab),
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: response => {
      const updatedReceipts = updateOptions(response.receipts)
      setReceipts(updatedReceipts)
    }
  })

  // Mutation fire on mark duplicate.
  const { mutate: mutateCreateDuplicate } = useMutation({
    mutationFn: createDuplicate,
    onSuccess: () => {
      refetch()
      setSelectedReceipts([])
      openMarkedDuplicateNotification()
    }
  })

  // Mutation fire on unmark duplicate.
  const { mutate: mutateDeleteDuplicate } = useMutation({
    mutationFn: deleteDuplicate,
    onSuccess: () => {
      refetch()
      openUnmarkDuplicateNotification()
    }
  })

  // Mutation fire to check similar users in others products.
  const { mutate: mutateCheckProductUsers } = useMutation({
    mutationFn: checkProductUsers,
    onSuccess: response => {
      setProductUsers(response.productUsers)
    }
  })

  // Mutation fire to approve/disapprove any user.
  const { mutate: approveDisapproveUsers } = useMutation({
    mutationFn: checkProductUsers,
    onSuccess: res => {
      const { productUsers } = res
      const nextProduct = getNextProductUser(productUsers, selectedProduct)
      if (nextProduct === undefined) {
        setShowValidInvalidEntry(true)
      } else {
        setSelectedProduct(nextProduct.id)
      }
    }
  })

  // Mutation fire to mark user as valid/Invalid/Escalate.
  const { mutate: mutateMarkEntry } = useMutation({
    mutationFn: markEntryForReceipts,
    onSuccess: res => {
      refetch()
      openMarkEntryNotification(res.option)
    }
  })

  // Handling checkbox to select all receipts
  const handleSelectAll = e => {
    console.log('e: ', e)
    const { checked } = e.target
    checked
      ? setSelectedReceipts(receipts.map(item => item.enrollmentid))
      : setSelectedReceipts([])
  }

  // Handling checkbox to check single receipt
  const handleSingleCheckbox = (e, enrollId) => {
    const { checked } = e.target
    checked
      ? setSelectedReceipts([...selectedReceipts, enrollId])
      : setSelectedReceipts(selectedReceipts.filter(id => id !== enrollId))
  }

  // Handling product selection
  const handleSelectProduct = value => {
    setSelectedProduct(value)
    setReceiptDetail({})
  }

  // Handling actions to mark user as duplicate/valid/invalid/escalate.
  const handleActions = value => {
    const selectedReceiptsData = receipts.filter(item =>
      selectedReceipts.includes(item.enrollmentid)
    )
    if (value === 'duplicate') {
      let filteredData = selectedReceiptsData.filter(item => !item.duplicate)
      //check if found any duplicate Ipaddress
      let finalData = filteredData.map((item, index) => {
        const {
          browserInfo: { ipAddress }
        } = item
        const isDuplicate = filteredData.filter(
          ele => ele.browserInfo?.ipAddress === ipAddress
        )
        if (isDuplicate.length > 1) {
          return {
            ...item,
            surveyid: id,
            productid: selectedProduct,
            options: { ipAddress, value: index }
          }
        } else {
          return { ...item, surveyid: id, productid: selectedProduct }
        }
      })
      mutateCreateDuplicate({
        receipts: finalData,
        userId: authenticatedUser?.id
      })
    } else {
      let finalData = {
        surveyId: id,
        enrollments: selectedReceipts,
        option: value,
        userId: authenticatedUser?.id
      }
      mutateMarkEntry(finalData)
    }
  }

  // Handling create duplicate
  const handleCreateDuplicate = (receipt, index) => {
    const {
      browserInfo: { ipAddress }
    } = receipt
    const duplicateReceipts = receipts?.filter(
      ele => ele.browserInfo.ipAddress === ipAddress
    )
    if (duplicateReceipts?.length > 1) {
      //if found any same ipAdress
      const finalData = {
        ...receipt,
        surveyid: id,
        productid: selectedProduct,
        options: { ipAddress, value: index }
      }
      mutateCreateDuplicate({
        receipts: [finalData],
        userId: authenticatedUser?.id
      })
    } else {
      const finalData = {
        ...receipt,
        surveyid: id,
        productid: selectedProduct
      }
      mutateCreateDuplicate({
        receipts: [finalData],
        userId: authenticatedUser?.id
      })
    }
  }

  // handling delete duplicate
  const handleDeleteDuplicate = duplicateId => {
    mutateDeleteDuplicate(duplicateId)
  }

  // when clicks on receipt
  const handleReceiptClick = receipt => {
    const payload = {
      surveyEnrollmentInfoPayload: {
        id: receipt.enrollmentid
      },

      productIds: products.map(ele => ele._id),
      userId: authenticatedUser?.id
    }
    setReceiptDetail(receipt)
    setNextDisable(false)
    setPreviousDisable(false)
    setShowValidInvalidEntry(false)
    setValidValue('')
    mutateCheckProductUsers(payload)
  }

  // when approve or disapprove user
  const handleApproveDisapprove = e => {
    const { value } = e.target
    setApproveValue(value)
    const { savedRewards } = receiptDetail
    const updatedSavedRewards = savedRewards?.map(item => {
      if (item.id === selectedProduct) {
        item.approve = value
        item.approveAt = moment().toDate().getTime()
        item.approvedBy = userEmail
      }
      return item
    })
    const payload = {
      surveyEnrollmentInfoPayload: {
        savedRewards: updatedSavedRewards,
        id: receiptDetail.enrollmentid
      },
      productIds: products.map(ele => ele._id),
      userId: authenticatedUser?.id
    }
    approveDisapproveUsers(payload)
    setShowValidInvalidEntry(true)
  }

  // when click on submit button
  const handleSubmit = value => {
    const payload = {
      enrollments: [receiptDetail?.enrollmentid],
      option: value,
      userId: authenticatedUser?.id
    }
    mutateMarkEntry(payload)
  }

  // when receipts changes and details tab is open it'll same user in next product.
  useEffect(() => {
    if (receiptDetail && Object.keys(receiptDetail).length) {
      const selectedReceipt = receipts?.filter(
        item => item.userid === receiptDetail.userid
      )
      setReceiptDetail(selectedReceipt[0])
    }
  }, [receipts])

  // refetch receipts when product or tab changes
  useEffect(() => {
    if (selectedProduct) {
      refetch()
    }
  }, [selectedProduct, activeTab])

  useEffect(() => {
    setSelectedReceipts([])
  }, [activeTab])

  useEffect(() => {
    if (validValue === 'valid') setApproveValue('approved')
    if (validValue === 'invalid') setApproveValue('unapproved')
  }, [validValue])

  return (
    <Row>
      <Col span={receiptDetail && Object.keys(receiptDetail).length ? 16 : 24}>
        <SelectCardWrapper>
          <MenuWrapper className='select-bar'>
            <SelectProduct
              handleChange={handleSelectProduct}
              products={products}
              selectedProduct={selectedProduct}
            />
            <SelectActions
              handleActions={handleActions}
              handleSelectAll={handleSelectAll}
              receipts={receipts}
              selectedReceipts={selectedReceipts}
            />
          </MenuWrapper>

          <ReceiptCardsWrapper showIcon={!receipts.length ? true : false}>
            <Spin spinning={isReceiptsLoading}>
              <Row gutter={16}>
                {receipts.length ? (
                  receipts?.map((receipt, index) => (
                    <Col
                      span={
                        receiptDetail && Object.keys(receiptDetail).length
                          ? 12
                          : 8
                      }
                      key={index}
                    >
                      <ReceiptCard
                        key={index}
                        receipt={receipt}
                        index={index}
                        receiptDetail={receiptDetail}
                        selectedReceipts={selectedReceipts}
                        handleSingleCheckbox={handleSingleCheckbox}
                        handleReceiptClick={handleReceiptClick}
                        handleCreateDuplicate={handleCreateDuplicate}
                        handleDeleteDuplicate={handleDeleteDuplicate}
                      />
                    </Col>
                  ))
                ) : (
                  <NoEntriesIcon />
                )}
              </Row>
            </Spin>
          </ReceiptCardsWrapper>
        </SelectCardWrapper>
      </Col>
      {receiptDetail && !!Object.keys(receiptDetail)?.length && (
        <Col span={8}>
          <ReceiptDetail
            receiptDetail={receiptDetail}
            setReceiptDetail={setReceiptDetail}
            products={products}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            productUsers={productUsers}
            handleApproveDisapprove={handleApproveDisapprove}
            setApproveValue={setApproveValue}
            approveValue={approveValue}
            showValidInvalidEntry={showValidInvalidEntry}
            handleSubmit={handleSubmit}
            receipts={receipts}
            nextDisable={nextDisable}
            setNextDisable={setNextDisable}
            previousDisable={previousDisable}
            setPreviousDisable={setPreviousDisable}
            validValue={validValue}
            setValidValue={setValidValue}
            setShowValidInvalidEntry={setShowValidInvalidEntry}
          />
        </Col>
      )}
    </Row>
  )
}

export default Receipts
