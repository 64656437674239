import styled from 'styled-components'

export const Header = styled.header`
  text-align: center;
  padding: 0 0.625rem;
`

export const Title = styled.h2`
  color: #ffffff;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-family: Montserrat;
`

export const Description = styled.div`
  color: #ffffff;
  font-size: 1rem;
  font-family: DM Sans;
  line-height: 1.25rem;
`
