import React from 'react'
import { ImageHolder, ImageContainer } from './styles'

const ImageSlider = ({ currentProduct, currentImage, setCurrentImage }) => {
  return (
    <ImageHolder>
      {currentProduct?.images.map((imageUrl, index) => (
        <ImageContainer
          key={index}
          selected={imageUrl === currentImage ? true : false}
          onClick={() => setCurrentImage(imageUrl)}
        >
          <img src={imageUrl} alt='' />
        </ImageContainer>
      ))}
    </ImageHolder>
  )
}

export default ImageSlider
