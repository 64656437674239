import styled from 'styled-components'
import { Tag, Checkbox } from 'antd'

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`
export const MainContainer = styled.div`
  border-radius: 8px;
  border: ${props =>
    props.selectedReceipt
      ? '2px solid #367F98'
      : props.duplicateReceipt
      ? '3px solid #E7A72A'
      : '0px'};
  background-color: transparent;
  height: 22.5rem;
  width: 100%;
  z-index: 999;
  position: absolute;
`

export const UpperLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const CheckboxIconWrapper = styled.div`
  display: flex;
  margin: 12px 12px 0px 0px;
  gap: 15px;
  cursor: pointer;
  position: 'absolute';
  top: '0';
  right: '0';
`

export const StyledWaitingTag = styled(Tag)`
  margin: 12px 0px 0px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: #fafafa;
  color: #272d4e;
`

export const StyledInprocessTag = styled(Tag)`
  margin: 12px 0px 0px 12px;
  border: 1px solid #66beda;
  border-radius: 4px;
  background: #f7fafe;
  color: #367f98;
`

export const StyledInvalidTag = styled(Tag)`
  margin: 12px 0px 0px 12px;
  border: 1px solid #ffa39e;
  border-radius: 4px;
  background: #ffe7ec;
  color: #da2f58;
`

export const StyledValidTag = styled(Tag)`
  margin: 12px 0px 0px 12px;
  border: 1px solid #4ab9bb;
  border-radius: 4px;
  background: #e3faf1;
  color: #4ab9bb;
`

export const StyledPaidTag = styled(Tag)`
  margin: 12px 0px 0px 12px;
  border: 1px solid #b7eb8f;
  border-radius: 4px;
  background: #e3faf1;
  color: #52c41a;
`

export const StyledDuplicateTag = styled(Tag)`
  margin: 12px 0px 0px 12px;
  border: 1px solid #e7a72a;
  border-radius: 4px;
  background: rgba(255, 248, 216, 0.9);
  color: #e7a72a;
`

export const StyledEscalatedTag = styled(Tag)`
  margin: 12px 0px 0px 12px;
  border: 1px solid #a969fc;
  border-radius: 4px;
  background: #e7d5ff;
  color: #a969fc;
`

export const StyledCheckbox = styled(Checkbox)``

export const ClickableContainer = styled.div`
  height: 90%;
  width: 100%;
  cursor: pointer;
`

export const TagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const DuplicateCountWrapper = styled.div`
  display: flex;
  alignitems: center;
  gap: 6px;
  padding: 4px 8px;
  background: #e7a72a;
  border-radius: 12px 0px 0px 0px;
  position: absolute;
  bottom: 0;
  right: 0;

  span {
    color: #ffffff;
  }
`
