import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SameIPUsers from '@/components/ModalValidation/SameIPUsers'
import { Tooltip } from 'antd'
import { InfoCircleOutlined, DownOutlined, UpOutlined } from '@ant-design/icons'
import {
  Container,
  Information,
  TextMedium,
  StyledLabelWrapper,
  BreakLine,
  StyledShowCollapseWrapper
} from './styles'

const ReceiptInformation = ({ receiptDetail }) => {
  const { t } = useTranslation()

  const {
    fullName,
    emailAddress,
    paypalEmail,
    enrollmentid,
    userid,
    browserInfo
  } = receiptDetail
  const { ipAddress } = browserInfo

  const [showMoreDetails, setShowMoreDetails] = useState(false)
  return (
    <React.Fragment>
      <Container>
        <Information>
          <label>{t('components.enrollmentInformation.userName')}</label>
          <TextMedium>{fullName}</TextMedium>
        </Information>

        <Information>
          <label>{t('components.enrollmentInformation.userEmail')}</label>
          <TextMedium>{emailAddress}</TextMedium>
        </Information>
        {showMoreDetails && (
          <>
            <Information>
              <label>{t('components.enrollmentInformation.userpPaypal')}</label>
              <TextMedium>{paypalEmail}</TextMedium>
            </Information>

            <Information>
              <StyledLabelWrapper>
                <label>
                  {t('components.enrollmentInformation.tasterRespondentLabel')}
                </label>
                <Tooltip
                  placement='right'
                  title={t(
                    'components.enrollmentInformation.tasterRespondentTooltip'
                  )}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </StyledLabelWrapper>
              <Tooltip title={enrollmentid}>
                <TextMedium>{enrollmentid}</TextMedium>
              </Tooltip>
            </Information>

            <Information>
              <StyledLabelWrapper>
                <label>{t('components.enrollmentInformation.userId')}</label>
                <Tooltip
                  placement='right'
                  title={t('components.enrollmentInformation.userIdTooltip')}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </StyledLabelWrapper>
              <Tooltip title={userid}>
                <TextMedium>{userid}</TextMedium>
              </Tooltip>
            </Information>

            <BreakLine />

            <SameIPUsers remoteAddress={ipAddress} />
          </>
        )}
      </Container>
      {showMoreDetails ? (
        <StyledShowCollapseWrapper onClick={() => setShowMoreDetails(false)}>
          <span>{t('components.enrollmentInformation.collapse')}</span>
          <UpOutlined />
        </StyledShowCollapseWrapper>
      ) : (
        <StyledShowCollapseWrapper onClick={() => setShowMoreDetails(true)}>
          <span>{t('components.enrollmentInformation.showMore')}</span>
          <DownOutlined />
        </StyledShowCollapseWrapper>
      )}
    </React.Fragment>
  )
}

export default ReceiptInformation
