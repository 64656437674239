export const mapAnswerWithReward = (savedReward, answers) => {
  const mappedAnswers = answers?.filter(
    answer => answer.product_id === savedReward.id
  )

  return {
    ...savedReward,
    answers: mappedAnswers
  }
}
