import styled from 'styled-components'

export const Section = styled.section`
  background-color: #f7fafe;
  border: 1px solid #e5eaf4;
  border-radius: 1.25rem;
  padding: 1rem;
  margin-bottom: 1rem;
`

export const Title = styled.h5`
  color: #272d4e;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0075em;
`

export const StyledValueContainer = styled.div`
  display: flex;
  gap: 1rem;
  overflow-x: auto;
`

export const ApprovedInfo = styled.div`
  font-size: 0.625rem;

  span:nth-child(1) {
    color: #8c8f9f;
    margin-right: 0.25rem;
  }
`
