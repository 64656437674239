import React from 'react'

const DuplicateCountIcon = props => (
  <svg
    width='19'
    height='19'
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_2143_6017)'>
      <path
        d='M11.3774 5.92188C8.34144 5.92188 5.87744 8.38587 5.87744 11.4219C5.87744 14.4579 8.34144 16.9219 11.3774 16.9219C14.4134 16.9219 16.8774 14.4579 16.8774 11.4219C16.8774 8.38587 14.4134 5.92188 11.3774 5.92188ZM10.8274 15.7834C8.65494 15.5139 6.97744 13.6659 6.97744 11.4219C6.97744 11.0809 7.02144 10.7564 7.09294 10.4374L9.72744 13.0719V13.6219C9.72744 14.2269 10.2224 14.7219 10.8274 14.7219V15.7834ZM14.6224 14.3864C14.4794 13.9409 14.0724 13.6219 13.5774 13.6219H13.0274V11.9719C13.0274 11.6694 12.7799 11.4219 12.4774 11.4219H9.17744V10.3219H10.2774C10.5799 10.3219 10.8274 10.0744 10.8274 9.77188V8.67188H11.9274C12.5324 8.67188 13.0274 8.17688 13.0274 7.57188V7.34638C14.6389 8.00088 15.7774 9.57938 15.7774 11.4219C15.7774 12.5659 15.3374 13.6054 14.6224 14.3864Z'
        fill='white'
      />
      <path
        d='M4.87744 10.9219L4.49839 11.2479L4.87744 11.6886L5.25649 11.2479L4.87744 10.9219ZM4.87744 10.9219C5.25649 11.2479 5.25656 11.2479 5.25663 11.2478L5.25684 11.2475L5.25747 11.2468L5.25953 11.2444L5.26684 11.2358L5.29376 11.204C5.31697 11.1764 5.3505 11.1362 5.39288 11.0846C5.47762 10.9813 5.59786 10.832 5.74179 10.6458C6.02924 10.2739 6.41321 9.75207 6.79809 9.15311C7.18209 8.55549 7.57265 7.87242 7.86858 7.17884C8.16191 6.49134 8.37744 5.75887 8.37744 5.07187C8.37744 3.0775 6.83486 1.42188 4.87744 1.42188C2.92002 1.42188 1.37744 3.0775 1.37744 5.07187C1.37744 5.75887 1.59297 6.49134 1.8863 7.17884C2.18223 7.87242 2.57279 8.55549 2.9568 9.15311C3.34167 9.75207 3.72564 10.2739 4.01309 10.6458C4.15702 10.832 4.27726 10.9813 4.362 11.0846C4.40438 11.1362 4.43791 11.1764 4.46113 11.204L4.48804 11.2358L4.49535 11.2444L4.49741 11.2468L4.49804 11.2475L4.49825 11.2478C4.49832 11.2479 4.49839 11.2479 4.87744 10.9219ZM4.87744 5.69688C4.58486 5.69688 4.30601 5.44 4.30601 5.07187C4.30601 4.70375 4.58486 4.44687 4.87744 4.44687C5.17002 4.44687 5.44887 4.70375 5.44887 5.07187C5.44887 5.44 5.17002 5.69688 4.87744 5.69688Z'
        fill='white'
        stroke='#E7A72A'
      />
    </g>
    <defs>
      <clipPath id='clip0_2143_6017'>
        <rect
          width='18'
          height='18'
          fill='white'
          transform='translate(0.877441 0.921875)'
        />
      </clipPath>
    </defs>
  </svg>
)

export default DuplicateCountIcon
