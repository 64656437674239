import { useLocation } from 'react-router-dom'
import ShowAttachment from '@/components/ShowAttachment'

const Container = () => {
  const location = useLocation()

  const pathname = location.pathname
  const params = new URLSearchParams(location.search)
  const resourcePath = pathname.split('/attachment/')[1]
  const type = params.get('type')

  return <ShowAttachment url={resourcePath} type={type} />
}

export default Container