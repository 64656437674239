import styled from 'styled-components'
import { DatePicker } from 'antd'
const { RangePicker } = DatePicker

export const AntPicker = styled(RangePicker)`
  position: absolute;
  left: 0;
  visibility: hidden;
`

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`
