import styled from 'styled-components'
import { Modal, Table, Tooltip } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
export const AntModal = styled(Modal)`
  .ant-modal-body {
    height: 300px;
    overflow: auto;
    padding: 15px;
  }

  .ant-modal-content {
    border-radius: 15px;
  }

  .ant-modal-header {
    border-radius: 15px;
  }
`

export const AntTable = styled(Table)`
  .ant-table-tbody > tr > td {
    padding: 10px 20px;
  }
`

export const EmailWrapper = styled.div`
  font-family: 'DM Sans';
  line-height: 1.25em;
  color: #8c8f9f;
  margin-top: 0.25rem;
  cursor: pointer;
  &:hover {
    color: #1c9aba;
  }
`

export const AntTooltip = styled(Tooltip)``

export const AntCheckOutlined = styled(CheckOutlined)`
  color: #1c9aba;
  margin-right: 0.625rem;
`
