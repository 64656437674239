import React, { useState, useEffect } from 'react'
import moment from 'moment'
import useCustomHistory from '@/hooks/useCustomHistory'
import { Button } from 'antd'
import { CalendarOutlined } from '@ant-design/icons'
import DatePickerCustom from './RangePicker'
import { Wrapper } from './styles'

const FilterCalendar = () => {
  const {
    location,
    addSearchKeyValue,
    deleteSearchKey,
    checkSearchKeyExisted,
    getValueBySearchKey
  } = useCustomHistory()
  const [defaultText, setDefaultText] = useState('All')
  const [openPicker, setOpenPicker] = useState(false)
  const [sDate, setSDate] = useState('')
  const [eDate, setEDate] = useState('')

  useEffect(() => {
    setSDate('')
    setEDate('')
    setDefaultText('All')
    if (
      checkSearchKeyExisted('startDate') &&
      checkSearchKeyExisted('endDate')
    ) {
      setSDate(moment(getValueBySearchKey('startDate')))
      setEDate(moment(getValueBySearchKey('endDate')))
      setDefaultText('Custom')
    }
  }, [location])

  const handleCalendarChange = (dates, dateStrings, info) => {
    setDefaultText('Custom')
    if (dates[0]) {
      setSDate(dates[0])
    }
    if (dates[1]) {
      setEDate(dates[1])
    }
  }

  const handleSave = () => {
    if (sDate && eDate) {
      addSearchKeyValue('startDate', moment(sDate).format('YYYY-MM-DD'))
      addSearchKeyValue('endDate', moment(eDate).format('YYYY-MM-DD'))
      addSearchKeyValue('option', 'Custom')
      deleteSearchKey('page')
    }

    handleClosePicker()
  }

  const handleClear = () => {
    if (
      sDate &&
      eDate &&
      checkSearchKeyExisted('startDate') &&
      checkSearchKeyExisted('endDate')
    ) {
      deleteSearchKey('startDate')
      deleteSearchKey('endDate')
      deleteSearchKey('option')
      deleteSearchKey('page')
    }
    setSDate('')
    setEDate('')
    setDefaultText('All')
    handleClosePicker()
  }

  const handleClosePicker = () => {
    setOpenPicker(false)
    if (
      checkSearchKeyExisted('startDate') &&
      checkSearchKeyExisted('endDate')
    ) {
      setSDate(moment(getValueBySearchKey('startDate')))
      setEDate(moment(getValueBySearchKey('endDate')))
    } else {
      setSDate('')
      setEDate('')
      setDefaultText('All')
    }
  }

  return (
    <Wrapper>
      <Button
        id="calendar"
        icon={<CalendarOutlined />}
        type="text"
        onClick={() => setOpenPicker(true)}
      >
        {defaultText}&ensp;
        {sDate ? moment(sDate).format('DD/MM/YYYY') : 'Start date'} -{' '}
        {eDate ? moment(eDate).format('DD/MM/YYYY') : 'End date'}
      </Button>
      {openPicker && (
        <DatePickerCustom
          openPanel={openPicker}
          selectedDates={[sDate, eDate]}
          handleClosePanel={handleClosePicker}
          handleSave={handleSave}
          handleClear={handleClear}
          handleCalendarChange={handleCalendarChange}
        />
      )}
    </Wrapper>
  )
}
export default FilterCalendar
