import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useCustomHistory from '@/hooks/useCustomHistory'
import { SearchOutlined } from '@ant-design/icons'
import { AntdInput } from './styles'
import { Tooltip } from 'antd'

const InputSearch = () => {
  const {
    location,
    addSearchKeyValue,
    deleteSearchKey,
    checkSearchKeyExisted,
    getValueBySearchKey
  } = useCustomHistory()
  const [searchValue, setSearchValue] = useState('')
  const { t } = useTranslation()

  const onInputChange = (e) => {
    const value = e.target.value
    setSearchValue(value)

    //handle clear input
    if (e.type === 'click') {
      deleteSearchKey('keyword')
      deleteSearchKey('page')
    }
  }

  const handleSearch = () => {
    const encoded = encodeURIComponent(searchValue)
    addSearchKeyValue('keyword', encoded)
    deleteSearchKey('page')
  }

  useEffect(() => {
    setSearchValue('')
    if (checkSearchKeyExisted('keyword')) {
      setSearchValue(decodeURIComponent(getValueBySearchKey('keyword')))
    }
  }, [location])

  return (
    <Tooltip
      title={t('components.inputSearch.enterToSearch')}
      placement="topLeft"
    >
      <AntdInput
        placeholder={t('components.survey.search')}
        prefix={<SearchOutlined />}
        bordered={false}
        allowClear
        value={searchValue}
        onChange={onInputChange}
        onPressEnter={handleSearch}
      />
    </Tooltip>
  )
}

export default InputSearch
