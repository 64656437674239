import styled from 'styled-components'

export const Container = styled.div`
  text-align: center;
  padding: 1rem;
`

export const SupportFile = styled.iframe`
  height: 80vh;
  width: 100%;
`
