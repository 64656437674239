import styled from 'styled-components'
import { Radio } from 'antd'

export const Wrapper = styled.div`
  margin-top: 25px;
`

export const Text = styled.h3`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1.25em;

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  color: #272d4e;
`

export const AntRadio = styled(Radio)`
  .ant-radio-checked .ant-radio-inner {
    border-color: #367f98 !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #367f98;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #367f98;
  }
`
