import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Button } from 'antd'
import FormItemEmail from '@/components/FormItems/Email'
import FormItemPassword from '@/components/FormItems/Password'

const FormSignin = ({ loading, onSubmit }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const onFormSubmit = async () => {
    const values = await form.validateFields()
    onSubmit(values)
  }

  return (
    <Form form={form} layout='vertical'>
      <FormItemEmail autoComplete='username' onPressEnter={onFormSubmit} />

      <FormItemPassword
        autoComplete='current-password'
        onPressEnter={onFormSubmit}
      />

      <Button
        type='primary'
        loading={loading}
        shape='round'
        block
        onClick={onFormSubmit}
      >
        {t('components.welcome.signIn')}
      </Button>
    </Form>
  )
}

export default FormSignin
