import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 0.75rem;
`

export const ListContainer = styled.div`
  width: 25rem;
  max-height: 30rem;
  overflow: auto;
`

export const User = styled.section`
  &:not(:first-child) {
    padding-top: 0.5rem;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 0.5rem;
  }
`

export const GlobalIcon = styled.img``

export const Warpper = styled.span`
  display: flex;
  gap: 3px;
  align-items: center;
  &.red {
    color: #da2f58;
  }
`
