import React, { useEffect, useState, useMemo } from "react"
import { useTranslation } from "react-i18next"
import useCustomHistory from "@/hooks/useCustomHistory"
import { AntTabs } from "./styles"

const TabsEnrollment = ({ isFraudCheck, isPreRecruit }) => {
  const { t } = useTranslation()
  const {
    addSearchKeyValue,
    checkSearchKeyExisted,
    getValueBySearchKey,
    resetFiltersInUrl,
  } = useCustomHistory()

  const [active, setActive] = useState("all")

  const tabs = useMemo(() => {
    let validTab = t("components.survey.tabs.valid")
    let invalidTab = t("components.survey.tabs.invalid")

    if (isFraudCheck) {
      validTab = "Fraud Check Valid"
      invalidTab = "Fraud Check Invalid"
    }

    if (isPreRecruit) {
      validTab = "Pre Recruit Valid"
      invalidTab = "Pre Recruit Invalid"
    }

    return [
      {
        label: t("components.survey.tabs.filters.surveystatus.all"),
        key: "all",
      },
      {
        label: t("components.survey.tabs.unprocessed"),
        key: "unprocessed",
      },
      {
        label: validTab,
        key: "valid",
      },
      {
        label: invalidTab,
        key: "invalid",
      },
      {
        label: "Escalated",
        key: "escalated",
      },
      {
        label: "Archived",
        key: "archived",
      },
    ]
  }, [isPreRecruit, isFraudCheck])

  const onChange = (key) => {
    setActive(key)
    addSearchKeyValue("tab", key)
    resetFiltersInUrl()
  }

  useEffect(() => {
    if (checkSearchKeyExisted("tab")) {
      setActive(getValueBySearchKey("tab"))
    }
  }, [active])

  return <AntTabs activeKey={active} onChange={onChange} items={tabs} />
}

export default TabsEnrollment
