import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import {
  AntModal,
  AntTable,
  EmailWrapper,
  AntTooltip,
  AntCheckOutlined
} from './styles'

const UsersModal = ({
  userId,
  globalData,
  isModalOpen,
  handleCancel,
  onSubmit,
  blackListAll
}) => {
  const filteredGlobalData = globalData.filter(user => user.userId !== userId)
  const [tooltipText, setTooltipText] = useState('Click to copy')
  const { t } = useTranslation()

  const copyToClipBoard = text => {
    navigator.clipboard.writeText(text)
    setTooltipText('Copied to clipboard')
    setTimeout(() => setTooltipText('Click to copy'), 1000)
  }

  const columns = [
    {
      title: t('components.enrollmentInformation.userId'),
      dataIndex: 'userId',
      key: 'userId'
    },
    {
      title: t('components.enrollmentInformation.ipAddress'),
      dataIndex: 'ipaddress',
      key: 'ipaddress'
    },
    {
      title: t('components.enrollmentInformation.name'),
      dataIndex: 'fullName',
      key: 'fullName'
    },
    {
      title: t('components.enrollmentInformation.email'),
      dataIndex: 'userEmail',
      key: 'userEmail',

      render: userEmail => (
        <EmailWrapper onClick={() => copyToClipBoard(userEmail)}>
          <AntTooltip
            overlayInnerStyle={{
              color: ' #ffffff',
              backgroundColor: 'rgba(39, 45, 78, 0.9)',
              borderRadius: '3px'
            }}
            title={
              <div>
                {tooltipText.includes('clipboard') && <AntCheckOutlined />}
                {tooltipText}
              </div>
            }
          >
            {userEmail}
          </AntTooltip>
        </EmailWrapper>
      )
    },
    {
      title: t('components.enrollmentInformation.paypalEmail'),
      dataIndex: 'paypalemail',
      key: 'paypalemail',

      render: paypalemail => (
        <EmailWrapper onClick={() => copyToClipBoard(paypalemail)}>
          <AntTooltip
            overlayInnerStyle={{
              color: ' #ffffff',
              backgroundColor: 'rgba(39, 45, 78, 0.9)',
              borderRadius: '3px'
            }}
            title={
              <div>
                {tooltipText.includes('clipboard') && <AntCheckOutlined />}
                {tooltipText}
              </div>
            }
          >
            {paypalemail}
          </AntTooltip>
        </EmailWrapper>
      )
    },
    {
      title: t('components.enrollmentInformation.globalIpCount'),
      dataIndex: 'count',
      key: 'count'
    },
    {
      title: t('components.enrollmentInformation.surveySubmission'),
      dataIndex: 'surveycount',
      key: 'surveycount'
    },
    {
      title: t('components.enrollmentInformation.blacklisted'),
      key: 'blackListed',
      render: record =>
        record?.blackListed ? (
          'Blacklisted'
        ) : (
          <Button
            type='primary'
            size='small'
            danger
            onClick={() => {
              onSubmit(record.userId)
            }}
          >
            {t('components.enrollmentInformation.addToBlackList')}
          </Button>
        )
    }
  ]

  return (
    <AntModal
      title={
        <>
          {t('components.globalCount')}
          {filteredGlobalData.length > 1 ? (
            <Button
              style={{ float: 'right', marginRight: '20px' }}
              type='primary'
              size='small'
              danger
              disabled={
                filteredGlobalData.filter(user => !user.blackListed).length
                  ? false
                  : true
              }
              onClick={() => {
                blackListAll(filteredGlobalData)
              }}
            >
              {t('components.enrollmentInformation.blackListAll')}
            </Button>
          ) : (
            ''
          )}
        </>
      }
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      closeIcon={<CloseCircleOutlined />}
      width={1500}
    >
      <AntTable
        bordered
        footer={null}
        pagination={false}
        columns={columns}
        dataSource={filteredGlobalData}
      />
    </AntModal>
  )
}

export default UsersModal
