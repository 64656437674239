import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { AntPicker, FooterWrapper } from './styles'
import { Button } from 'antd'

const DatePickerCustom = ({
  openPanel,
  selectedDates,
  handleClosePanel,
  handleSave,
  handleClear,
  handleCalendarChange
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    const rootElement = document.getElementById('root')
    if (rootElement) {
      rootElement.addEventListener('click', handleClosePanel)
    }
    return () => {
      if (rootElement) {
        rootElement.removeEventListener('click', handleClosePanel)
      }
    }
  }, [])

  const disabledDate = (current) => {
    // Can not select days after today
    return current && current >= moment().endOf('day')
  }

  return (
    <AntPicker
      open={openPanel}
      autoFocus={true}
      bordered={false}
      disabledDate={disabledDate}
      defaultValue={selectedDates}
      value={selectedDates}
      onCalendarChange={handleCalendarChange}
      renderExtraFooter={() => (
        <FooterWrapper>
          <Button type="text" onClick={handleClear}>
            <strong>{t('components.survey.tabs.calander.clear')}</strong>
          </Button>
          <Button shape="round" onClick={handleSave}>
            {t('components.survey.tabs.filters.save')}
          </Button>
        </FooterWrapper>
      )}
    />
  )
}

export default DatePickerCustom
