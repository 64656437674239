import { createSlice } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'
import cache from '@/utils/cache'

let cacheAuthenticatedUser = null
let userEmail = ''
let cacheImpersonatedUser = null

const checkCacheAuthenticatedUser = () => {
  try {
    const token = cache.getItem('flavorwiki-validation-token')
    cacheAuthenticatedUser = token && jwt_decode(token)
  } catch (error) {
    console.error(error)
  }
}

const checkCacheUserEmail = () => {
  try {
    const email = cache.getItem('flavorwiki-validation-user-email')
    userEmail = email ? email : ''
  } catch (error) {
    console.error(error)
  }
}

checkCacheAuthenticatedUser()
checkCacheUserEmail()

export const slice = createSlice({
  name: 'authentication',
  initialState: {
    isAdminView: false,
    authenticatedUser: cacheAuthenticatedUser,
    impersonatedUser: cacheImpersonatedUser,
    userEmail: userEmail
  },
  reducers: {
    setAuthenticatedUser: (state, action) => {
      cache.setItem('flavorwiki-validation-token', action.payload)

      const authenticatedUser = jwt_decode(action.payload)
      state.authenticatedUser = authenticatedUser
    },
    removeAuthenticatedUser: (state, action) => {
      cache.removeItem('flavorwiki-validation-token')
      state.authenticatedUser = null
    },
    setUserEmail: (state, action) => {
      cache.setItem('flavorwiki-validation-user-email', action.payload)

      state.userEmail = action.payload
    }
  }
})

export const { setAuthenticatedUser, removeAuthenticatedUser, setUserEmail } =
  slice.actions

export default slice.reducer
