import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 4rem 1fr;
  background: linear-gradient(
      180deg,
      rgba(229, 239, 255, 0) -18.06%,
      rgba(229, 239, 255, 0.2627) 11.32%,
      rgba(229, 239, 255, 0.8) 111.61%
    ),
    #ffffff;
`

export const Content = styled.div`
  width: calc(100vw - 4rem);
  height: 100vh;
  overflow: auto;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 2rem;
`

export const Main = styled.main`
  padding: 2rem;
`

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`
