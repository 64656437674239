import React from 'react'
import { getMarkAsDuplicateIcon, getReceiptTag } from './utils'
import DuplicateCountIcon from '@/assets/icons/DuplicateCountIcon'
import {
  Container,
  MainContainer,
  StyledCheckbox,
  ClickableContainer,
  UpperLayout,
  CheckboxIconWrapper,
  TagsWrapper,
  DuplicateCountWrapper
} from './styles'

const CardOverlay = ({
  receipt,
  selectedReceipts,
  handleSingleCheckbox,
  handleReceiptClick,
  receiptDetail,
  handleCreateDuplicate,
  handleDeleteDuplicate,
  index
}) => {
  const { options } = receipt
  const receiptTags = getReceiptTag(receipt)
  return (
    <MainContainer
      selectedReceipt={
        receipt.enrollmentid === receiptDetail?.enrollmentid ? true : false
      }
      duplicateReceipt={receipt.duplicate ? true : receipt?.show ? true : false}
    >
      <Container>
        <UpperLayout>
          <TagsWrapper>
            {receiptTags.map((item, index) => (
              <span key={index}>{item}</span>
            ))}
          </TagsWrapper>
          <CheckboxIconWrapper>
            {getMarkAsDuplicateIcon(
              receipt,
              index,
              handleCreateDuplicate,
              handleDeleteDuplicate
            )}
            <StyledCheckbox
              checked={selectedReceipts.includes(receipt.enrollmentid)}
              onChange={e => handleSingleCheckbox(e, receipt.enrollmentid)}
            />
          </CheckboxIconWrapper>
        </UpperLayout>
        <ClickableContainer
          onClick={() => handleReceiptClick(receipt)}
        ></ClickableContainer>
        {!!Object.keys(options).length && (
          <DuplicateCountWrapper>
            <DuplicateCountIcon /> <span>{options.value}</span>
          </DuplicateCountWrapper>
        )}
      </Container>
    </MainContainer>
  )
}

export default CardOverlay
