import styled from 'styled-components'
import { Modal as AntdModal } from 'antd'
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons'

export const Modal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 1.25em;
  }

  .ant-modal-header {
    border-top-right-radius: 1.25em;
    border-top-left-radius: 1.25em;

    .ant-modal-title {
      font-weight: 700;
      font-size: 1rem;
      color: #272d4e;
    }
  }

  .ant-modal-body {
    padding: 1.5rem 2rem;
  }

  .ant-modal-footer {
    button {
      border-radius: 3.75rem;
    }
  }
`

export const Label = styled.div`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-transform: capitalize;
  color: #272d4e;
  margin-bottom: 0.5rem;
`

export const SurveyName = styled.div`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.75rem;
  color: #8c8c8c;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  margin-bottom: 1.5rem;
`

export const InputFile = styled.input`
  display: none;
`

export const ButtonUpload = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #ebedf1;
  border-radius: 0.25rem;
  border: none;
  padding: 1.375rem 2rem;
  margin-bottom: 1.5rem;
`

export const FileUploaded = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #3dba4a;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  margin-bottom: 1rem;
`

export const CheckIcon = styled(CheckOutlined)`
  svg {
    color: #3dba4a;
  }
`

export const DeleteIcon = styled(DeleteOutlined)`
  cursor: pointer;

  svg {
    color: #d62e1f;
  }
`

export const FileName = styled.span`
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #272d4e;
  margin-left: 1rem;
`

export const FilePreview = styled.iframe`
  height: 40vh;
  width: 100%;
`
