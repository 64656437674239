import { useLocation } from 'react-router-dom'
import Receipts from '@/components/Receipts'

const Container = () => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const activeTab = searchParams.get('tab') || 'all'

  return <Receipts activeTab={activeTab} />
}

export default Container
