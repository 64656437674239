import { useMemo } from 'react'
import moment from 'moment'

import { groupLogsByDay, formatDate } from '@/utils/helperFunctions'
import { formatLogsSentences, formatHoursMinutes } from './utils'
import {
  Container,
  Date,
  Line,
  Timestamp,
  Spin,
  LogSentence,
  ValidatorName
} from './styles'

const Logs = ({ logs, enrollment, isLoading }) => {
  const formulatedLogs = useMemo(() => groupLogsByDay(logs), [logs])

  if (isLoading) return <Spin />

  return (
    <Container>
      {Object?.values(formulatedLogs)?.map(({ label, values }) => {
        return (
          <div key={label}>
            <Date>{label}</Date>

            {values?.map((log) => {
              const { createdat } = log

              return (
                <Line key={createdat}>
                  <Timestamp>{formatHoursMinutes(createdat)}</Timestamp>

                  {formatLogsSentences(log)}
                </Line>
              )
            })}
          </div>
        )
      })}
      <div>
        <Date>{formatDate(Number(enrollment?.createdat))}</Date>

        <Line>
          <Timestamp>
            {formatHoursMinutes(Number(enrollment?.createdat))}
          </Timestamp>
          <LogSentence>
            <ValidatorName>{enrollment?.username}</ValidatorName> finished this
            product entry
          </LogSentence>
        </Line>
      </div>
    </Container>
  )
}

export default Logs
