import { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'
import { Input } from './styles'

const SearchInput = () => {
  const history = useHistory()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const key = search.get('key')

  const [value, setValue] = useState(key || '')

  const handleConfirm = (e) => {
    const value = e.target.value

    if (value?.trim()) {
      history.push(`/search?key=${value}`)
    }
  }

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <Input
      value={value}
      placeholder="Search"
      prefix={<SearchOutlined />}
      onPressEnter={handleConfirm}
      onChange={handleChange}
    />
  )
}

export default SearchInput
