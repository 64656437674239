import styled from 'styled-components'

export const Container = styled.div`
  background: rgba(247, 250, 254, 0.9);
  border-radius: 8px;
  padding: 12px 16px;
`

export const Label = styled.div`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8c8f9f;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
