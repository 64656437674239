import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import DocumentIcon from '@/assets/icons/DocumentIcon'
import ApprovalMain from '@/components/ModalValidation/ApprovalMain'
import Receipts from '@/containers/Receipts'
import SideMenu from '@/components/ModalValidation/SideMenu'
import TabsReceipts from '@/components/Tabs/Receipts'
import {
  StyledModalTitle,
  StyledModal,
  TabsWrapper,
  ReceiptsWrapper
} from './styles'

const ModalValidation = ({
  onNextOrClose,
  onClose,
  selectedEnrollment,
  setSelectedEnrollment,
  onUpdate,
  guideUrl,
  isFraudCheck,
  isPreRecruit,
  isValidateModalOpen,
  setIsValidateModalOpen
}) => {
  const { t } = useTranslation()
  const [showReceipts, setShowReceipts] = useState(false)
  const isArchived = useMemo(
    () => selectedEnrollment?.enrollmentstate === 'archived',
    [selectedEnrollment]
  )

  const ModalTitle = (
    <StyledModalTitle>
      <DocumentIcon />

      <div className='textTitle'>
        {showReceipts
          ? t('components.userInfo.productReceipt')
          : t('components.userInfo.userProduct')}
      </div>
    </StyledModalTitle>
  )

  return (
    <StyledModal
      open={isValidateModalOpen}
      width='90%'
      title={ModalTitle}
      maskClosable={false}
      centered
      footer={null}
      padding={!showReceipts}
      onCancel={onClose}
    >
      <Row>
        <Col span={2}>
          <SideMenu
            showReceipts={showReceipts}
            setShowReceipts={setShowReceipts}
          />
        </Col>
        <Col span={22}>
          {!showReceipts ? (
            <ApprovalMain
              onClose={onNextOrClose}
              selectedEnrollment={selectedEnrollment}
              setSelectedEnrollment={setSelectedEnrollment}
              guideUrl={guideUrl}
              isFraudCheck={isFraudCheck}
              isPreRecruit={isPreRecruit}
              isArchived={isArchived}
              onUpdate={onUpdate}
            />
          ) : (
            <>
              <TabsWrapper>
                <TabsReceipts />
              </TabsWrapper>

              <ReceiptsWrapper>
                <Receipts />
              </ReceiptsWrapper>
            </>
          )}
        </Col>
      </Row>
    </StyledModal>
  )
}

export default ModalValidation
