import styled from 'styled-components'

export const Section = styled.section`
  width: 384px;
  padding: 2.5rem 2.5rem 1.25rem;
  background: #ffffff;
  border: 1px solid #e5eaf4;
  box-sizing: border-box;
  box-shadow: 0 0.125rem 0.25rem rgba(40, 41, 61, 0.04),
    0 0.5rem 1rem rgba(96, 97, 112, 0.16);
  border-radius: 1.25em;
  margin: 0 auto;
  transform: translateY(-84px);
  @media screen and (max-width: 600px) {
    width: calc(100% - 50px);
    padding: 25px 0.875rem;
    transform: translateY(-50px);
  }
`

export const SectionFooter = styled.footer`
  border-top: 1px solid rgba(13, 37, 60, 0.05);
  padding-top: 1rem;
  margin-top: 2rem;
`
