import styled from 'styled-components'
import src from '@/assets/images/bg-banner.jpg'

export const Container = styled.section`
  min-height: 100vh;
  background-color: #e5eaf4;
  padding: 1.5rem;

  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
`

export const WhiteSection = styled.div`
  position: relative;

  @media screen and (min-width: 1200px) {
    background-color: #e5eaf4;
  }
`

export const Header = styled.header`
  padding: 2.5rem 10% 55px;
`

export const Screen = styled.div`
  background-image: url(${src});
  height: 371px;
  border-radius: 1rem;
  background-repeat: no-repeat;
  background-size: cover;
`
