import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  UnprocessedMain,
  UnprocessedWrapper,
  LiveWrapper,
  NoEntriesMain,
  NoEntriesWrapper
} from '@/components/Tables/Surveys/styles'
import { PrimaryText, SubTitle, SecondaryText, Button } from '../styles'
import { Wrapper } from './styles'

const Survey = ({ data = {} }) => {
  const { t } = useTranslation()

  const {
    name,
    management,
    updatedAt,
    country,
    products,
    unprocessed,
    state,
    id
  } = data
  const { code } = management || {}
  const productType =
    products.length > 1 ? 'Multiple Products' : 'Single Product'

  return (
    <Wrapper>
      <div>
        <PrimaryText>{name}</PrimaryText>
        {code && <SubTitle>{code}</SubTitle>}
        <SecondaryText>{productType}</SecondaryText>
      </div>

      <div>
        <SecondaryText>LAST UPDATE</SecondaryText>
        <PrimaryText>
          {moment(updatedAt * 1000).format('MM/DD/YY, HH:MM')}
        </PrimaryText>
      </div>

      <div>
        <SecondaryText>COUNTRY</SecondaryText>
        <PrimaryText>{country || 'N/A'}</PrimaryText>
      </div>

      <div>
        <SecondaryText>PRODUCT</SecondaryText>
        <PrimaryText>{products.join(', ') || 'N/A'}</PrimaryText>
      </div>

      {unprocessed > 0 ? (
        <UnprocessedMain>
          <UnprocessedWrapper>
            {unprocessed} {t('components.survey.tabs.unprocessed')}
          </UnprocessedWrapper>
          <LiveWrapper>
            {state === 'active'
              ? t('components.survey.tabs.filters.surveystatus.live')
              : t('components.survey.tabs.filters.surveystatus.suspended')}
          </LiveWrapper>
        </UnprocessedMain>
      ) : (
        <NoEntriesMain>
          <NoEntriesWrapper>
            {t('components.survey.tabs.noentries')}
          </NoEntriesWrapper>
          <LiveWrapper>
            {state === 'active'
              ? t('components.survey.tabs.filters.surveystatus.live')
              : t('components.survey.tabs.filters.surveystatus.suspended')}
          </LiveWrapper>
        </NoEntriesMain>
      )}

      <Button>
        <Link to={`/survey/${id}/enrollments`}>View</Link>
      </Button>
    </Wrapper>
  )
}

export default Survey
