import Layout from '@/layouts/Default'
import SearchResults from '@/containers/SearchResults'

const Page = () => {
  return (
    <Layout>
      <SearchResults />
    </Layout>
  )
}

export default Page
