import { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Spin } from 'antd'
import ExternalIcon from '@/assets/icons/ExternalIcon'
import { Container, Photo } from './styles'

const ImageHolder = ({ url }) => {
  const [isLoading, setIsLoading] = useState(true)
  const route = `/attachment/${url}?type=image`

  useEffect(() => {
    axios.get(url).finally(() => {
      setIsLoading(false)
    })
  }, [url])

  return (
    <Container>
      <Spin spinning={isLoading}>
        <Photo style={{ backgroundImage: `url(${url})` }} />
      </Spin>

      {route && (
        <Link
          to={route}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ExternalIcon />
        </Link>
      )}
    </Container>
  )
}

export default ImageHolder
