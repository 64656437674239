import styled from 'styled-components'
import { Tabs } from 'antd'

export const AntTabs = styled(Tabs)`
  .ant-tabs-tab-btn {
    color: #8c8f9f;
  }

  .ant-tabs-ink-bar {
    background: linear-gradient(111.64deg, #4ea3bf 8.84%, #1c9aba 97.87%);
    border-radius: 1px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #272d4e;
  }

  .ant-tabs-nav {
    margin: 0;
  }

  .selected-dates {
    color: #367f98;
  }

  background: #f7fafe;
  box-shadow: inset 0px -1px 0px #e5eaf4;
  width: 100%;
`
