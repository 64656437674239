import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { getSurvey } from "@/apis/surveyAPI"
import useSurveyInfo from "@/hooks/useSurveyInfo"
import Layout from "@/layouts/Default"
import Table from "@/containers/Tables/Enrollments"
import FilterCalendar from "@/components/Filters/FilterCalander"
import CaptionEnrollment from "@/components/Caption/Enrollments"
import ButtonFilters from "@/components/Filters/FilterValidations/ButtonFilters"
import InputSearch from "@/components/InputSearch"
import TabsEnrollment from "@/components/Tabs/Enrollments"
import { ButtonViewBy } from "@/components/Buttons"
import { EnrollmentsProvider } from "../../contexts/enrollmentsContext"
import { MenuBarWrapper, Wrapper } from "./styles"

const Page = () => {
  const { id } = useParams()
  const surveyInfo = useSurveyInfo()
  const [survey, setSurvey] = useState({})

  const { isFraudCheck, isPreRecruit } = useMemo(
    () => ({
      isFraudCheck: survey?.settings?.fraudCheck && !survey?.preRecruitSurvey,
      isPreRecruit: survey?.preRecruitSurvey,
    }),
    [survey]
  )

  useEffect(() => {
    getSurvey(id).then((res) => {
      surveyInfo.setSurvey(res.data)
      setSurvey(res.data)
    })
  }, [])

  return (
    <EnrollmentsProvider>
      <Layout>
        <CaptionEnrollment data={survey} />

        <Wrapper>
          <ButtonViewBy />

          <TabsEnrollment
            isFraudCheck={isFraudCheck}
            isPreRecruit={isPreRecruit}
          />

          <MenuBarWrapper>
            <ButtonFilters />
            <FilterCalendar />
            <InputSearch />
          </MenuBarWrapper>

          <Table
            survey={survey}
            isFraudCheck={isFraudCheck}
            isPreRecruit={isPreRecruit}
          />
        </Wrapper>
      </Layout>
    </EnrollmentsProvider>
  )
}

export default Page
