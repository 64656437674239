import styled from 'styled-components'
import { RightOutlined } from '@ant-design/icons'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1.75rem;
`

export const CaptionTagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75em;
`

export const Caption = styled.div`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.25em;
  color: #272d4e;
`

export const TagWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 700;
  font-size: 0.875em;
  padding: 0.25rem 0.75em;
  background: #eef2f9;
  border-radius: 2.5rem;
  width: 54px;
  height: 28px;
  color: #1c9aba;
  margin-top: 3px;
`

export const RouteWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
`

export const SurveyListText = styled.div`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 0.75em;
  line-height: 1rem;
  color: #8c8f9f;
  cursor: pointer;
`
export const AntRightOutlined = styled(RightOutlined)`
  display: flex;
  align-items: center;
  color: #8c8f9f;
  font-size: 0.625rem;
`

export const ValidationListText = styled.div`
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1rem;
  color: #1c9aba;
`
export const CodeWrapper = styled.div`
  font-family: 'DM Sans';
  line-height: 22px;
  color: #8c8f9f;
  margin: 0.25rem 0 0.5rem 0;
`

export const Text = styled.div`
  margin-left: 5px;
`

export const EditSupportGuide = styled.div`
  cursor: pointer;

  span {
    font-family: 'DM Sans';
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #8c8f9f;
    margin-right: 0.5rem;
  }

  .anticon {
    svg {
      width: 1rem;
      height: 1rem;
      color: #367f98;
    }
  }
`
