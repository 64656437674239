import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useFormFields = () => {
  const { t } = useTranslation()

  const formFields = useMemo(() => {
    return {
      email: {
        label: 'Email',
        placeholder: 'Email'
      },
      password: {
        label: 'Password',
        placeholder: 'Password'
      }
    }
  }, [t])

  return formFields
}

export default useFormFields
