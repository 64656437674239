import styled from 'styled-components'

export const ImageHolder = styled.div`
  display: flex;
  gap: 17px;
  overflow-x: auto;
  margin-top: 8px;
`

export const ImageContainer = styled.div`
  min-width: 80px;
  min-height: 80px;
  background-color: ${props => (props.selected ? '#E0EBF0' : '#F7FAFE')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;

  img {
    max-width: 80px;
    max-height: 80px;
  }
}
`
