import styled from 'styled-components'
import { Select } from 'antd'
export const Wrapper = styled.div`
  margin-top: 0.875rem;
`

export const Text = styled.h3`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1.25em;

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  color: #272d4e;
`

export const SelectBox = styled(Select)`
  width: 262px;
`
