import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getEnrollmentAnswers as getEnrollmentAnswersApi } from '@/apis/validationAPI'

export const getEnrollmentAnswers = createAsyncThunk(
  'validationResponse/getEnrollmentAnswers',
  async (id) => {
    try {
      const response = await getEnrollmentAnswersApi(id)
      return response
      // eslint-disable-next-line no-empty
    } catch {}
  }
)

export const slice = createSlice({
  name: 'validationResponse',
  initialState: {
    enrollmentAnswers: [],
    enrollmentAnswersLoading: false,
    enrollmentAnswersError: false,
    selectedEnrollment: null
  },
  reducers: {
    setCurrentEnrollment: (state, action) => {
      state.selectedEnrollment = action.payload
    }
  },
  extraReducers: {
    [getEnrollmentAnswers.pending]: (state) => {
      state.enrollmentAnswersError = false
      state.enrollmentAnswersLoading = true
    },
    [getEnrollmentAnswers.fulfilled]: (state, action) => {
      state.enrollmentAnswers = action.payload || []
      state.enrollmentAnswersError = false
      state.enrollmentAnswersLoading = false
    },
    [getEnrollmentAnswers.rejected]: (state) => {
      state.enrollmentAnswersError = true
      state.enrollmentAnswersLoading = false
    }
  }
})

export const { setSurvey, setCurrentEnrollment } = slice.actions

export default slice.reducer
