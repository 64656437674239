import jwt_decode from 'jwt-decode'
import cache from '@/utils/cache'

let userSnapLoaded = false

const loadUserSnap = (user) => {
  if (
    !user ||
    !user.id ||
    userSnapLoaded ||
    !process.env.REACT_APP_USERSNAP_KEY
  ) {
    return false
  }

  userSnapLoaded = true
  let script = document.createElement('script')
  script.defer = 1
  script.src = `https://widget.usersnap.com/global/load/${process.env.REACT_APP_USERSNAP_KEY}?onload=onUsersnapCXLoad`
  document.getElementsByTagName('head')[0].appendChild(script)
  const { id, email } = user

  window.onUsersnapCXLoad = (api) => {
    api.init({
      user: {
        userId: id,
        email: email
      }
    })
  }
}

export const integrateUsersnap = () => {
  try {
    const token = cache.getItem('flavorwiki-validation-token')
    const decodedUser = jwt_decode(token)

    loadUserSnap(decodedUser)
  } catch (error) {
    console.error(error)
  }
}
