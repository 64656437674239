import styled from 'styled-components'
import { Tabs } from 'antd'

export const AntTabs = styled(Tabs)`
  .ant-tabs-tab-btn {
    color: ${(props) => props.theme.STATS_GREY_COLOR};
  }

  .ant-tabs-ink-bar {
    background: linear-gradient(111.64deg, #4ea3bf 8.84%, #1c9aba 97.87%);
    border-radius: 1px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #272d4e;
  }

  .selected-dates {
    color: #367f98;
  }
  width: 100%;
`
