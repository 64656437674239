import React from 'react'
import { useTranslation } from 'react-i18next'
import useCustomHistory from '@/hooks/useCustomHistory'
import { PAGES_SIZE } from '@/utils/constants/constants'
import { Dropdown, Menu, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Layout, Caption } from './styles'

const SizeChanger = ({ limit }) => {
  const { t } = useTranslation()
  const { addSearchKeyValue, deleteSearchKey } = useCustomHistory()

  const handleLimitChange = (item) => {
    addSearchKeyValue('limit', item.key)
    deleteSearchKey('page')
  }

  const menuItems = PAGES_SIZE.map((item) => ({
    label: item.value,
    key: item.key
  }))

  return (
    <Dropdown
      overlay={<Menu items={menuItems} onClick={handleLimitChange} />}
      trigger={['click']}
    >
      <Layout>
        <Space>
          <Caption>{t('components.survey.footer.rows')}</Caption>
          {limit}
          <DownOutlined />
        </Space>
      </Layout>
    </Dropdown>
  )
}

export default SizeChanger
