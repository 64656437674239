import { createSlice } from '@reduxjs/toolkit'

const surveySlice = createSlice({
  name: 'surveyInfo',
  initialState: {
    survey: {}
  },
  reducers: {
    setSurvey: (state, action) => {
      state.survey = action.payload
    }
  }
})

export const { setSurvey } = surveySlice.actions

export default surveySlice.reducer
