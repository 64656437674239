import { useState, useMemo } from 'react'
import { Spin } from 'antd'
import Tab from '@/components/Tabs/SearchResults'
import NoEntriesIcon from '@/assets/icons/NoEntriesIcon'
import Survey from './Survey'
import Enrollment from './Enrollment'
import { Title, Wrapper, SectionTitle, SectionContent } from './styles'

const SearchResults = ({ data, isLoading }) => {
  const [activeTab, setActiveTab] = useState('all')

  const shownData = useMemo(() => {
    if (activeTab === 'all') return data
    if (activeTab === 'surveys') return { surveys: data.surveys || [] }
    if (activeTab === 'enrollments')
      return { enrollments: data.enrollments || [] }
  }, [activeTab, data])

  if (isLoading)
    return (
      <Wrapper>
        <Spin />
      </Wrapper>
    )

  if (!Object.values(data).length) {
    return (
      <Wrapper>
        <NoEntriesIcon />
      </Wrapper>
    )
  }

  return (
    <section>
      <Title>Results</Title>

      <Tab active={activeTab} setActive={setActiveTab} data={data} />

      {shownData.surveys && shownData.surveys.length && (
        <div>
          <SectionTitle>Survey</SectionTitle>

          <SectionContent>
            {shownData.surveys.map((survey) => (
              <Survey data={survey} key={survey.id} />
            ))}
          </SectionContent>
        </div>
      )}

      {shownData.enrollments && shownData.enrollments.length && (
        <div>
          <SectionTitle>Validation entry</SectionTitle>

          <SectionContent>
            {shownData.enrollments.map((enrollment) => (
              <Enrollment data={enrollment} key={enrollment.id} />
            ))}
          </SectionContent>
        </div>
      )}
    </section>
  )
}

export default SearchResults
