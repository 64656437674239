import { Row, Col, Switch, Form, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'
import { formatDateTimeString } from '@/utils/helperFunctions'
import UniversalValidator from '../UniversalValidator'
import ImageHolder from './ImageHolder'
import VideoHolder from './VideoHolder'
import {
  AdditionalInformation,
  StyledWrapper,
  StyledSwitchRow,
  StyledIconRadio,
  StyledIconWrapper,
  StyledApprovedContainer,
  StyledLabelWrapper,
  StyledValueContainer
} from './styles'

const AnswerValidation = ({
  form,
  savedReward,
  isArchived,
  isFraudCheck,
  isPreRecruit,
  onChangeUniversal
}) => {
  const { t } = useTranslation()

  const surveyInfoResponse = useSelector(state => state.surveyInfo)
  const { survey } = surveyInfoResponse || {}
  const { products } = survey || []
  const { answers, reward, id, approvedAt, approvedBy } = savedReward || {}

  const productName = products?.find(product => product._id === id)?.name
  const watchedApproveValue = Form.useWatch(['rewards', id, 'approve'], form)
  const watchedPayableValue = Form.useWatch(['rewards', id, 'payable'], form)
  const watchedShowValue = Form.useWatch(['rewards', id, 'showOnCharts'], form)

  return (
    <StyledWrapper>
      <div className='answerHeader'>
        <Form.Item name={['rewards', id, 'approve']}>
          <StyledIconRadio.Group
            defaultValue={watchedApproveValue}
            disabled={isArchived}
          >
            <StyledIconRadio value='approved'>
              <StyledIconWrapper value={watchedApproveValue}>
                <CheckCircleFilled className='checkIcon' />
              </StyledIconWrapper>
            </StyledIconRadio>

            <StyledIconRadio value='unapproved'>
              <StyledIconWrapper value={watchedApproveValue}>
                <CloseCircleFilled className='closeIcon' />
              </StyledIconWrapper>
            </StyledIconRadio>
          </StyledIconRadio.Group>
        </Form.Item>

        <div className='answerTitle'>
          <div className='mainTitle'>{productName}</div>
          <div className='subTitle'>Rewards: {reward}</div>
        </div>
      </div>

      <div className='answerContent'>
        <Row gutter={5}>
          <Col span={8}>
            <AdditionalInformation>
              <StyledSwitchRow>
                <Form.Item name={['rewards', id, 'payable']}>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={watchedPayableValue}
                    disabled={isArchived}
                  />
                </Form.Item>

                <StyledLabelWrapper>
                  <span>Payment</span>

                  <Tooltip
                    placement='right'
                    title={t('components.answerValidation.allowPaid')}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </StyledLabelWrapper>
              </StyledSwitchRow>

              <StyledSwitchRow>
                <Form.Item name={['rewards', id, 'showOnCharts']}>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={watchedShowValue}
                    disabled={isArchived}
                  />
                </Form.Item>

                <StyledLabelWrapper>
                  <span>Show data</span>

                  <Tooltip
                    placement='right'
                    title={t('components.answerValidation.allowChart')}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </StyledLabelWrapper>
              </StyledSwitchRow>
            </AdditionalInformation>

            <UniversalValidator
              form={form}
              isFraudCheck={isFraudCheck}
              isPreRecruit={isPreRecruit}
              isArchived={isArchived}
              onChangeUniversal={onChangeUniversal}
            />

            {approvedBy && (
              <StyledApprovedContainer>
                <span>Approved by:</span>
                <span>{approvedBy}</span>
              </StyledApprovedContainer>
            )}

            {approvedAt && (
              <StyledApprovedContainer>
                <span>Approved at:</span>
                <span>{formatDateTimeString(approvedAt)}</span>
              </StyledApprovedContainer>
            )}
          </Col>

          <Col span={16}>
            {answers?.length > 0 && (
              <StyledValueContainer>
                {answers.map(answer => {
                  const { value, typeOfQuestion } = answer
                  return value?.map((url, index) => {
                    const isUrl = typeof url === 'string'

                    if (isUrl && typeOfQuestion === 'upload-picture') {
                      return <ImageHolder key={index} url={url} />
                    }

                    if (isUrl && typeOfQuestion === 'upload-video') {
                      return <VideoHolder key={index} url={url} />
                    }

                    return null
                  })
                })}
              </StyledValueContainer>
            )}
          </Col>
        </Row>
      </div>
    </StyledWrapper>
  )
}

export default AnswerValidation
