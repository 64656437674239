import React from 'react'
import { Popover } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { Container, Text } from './styles'
import { Information, TextMedium } from '../styles'
import { useTranslation } from 'react-i18next'

const UserInformation = ({ data }) => {
  const { t } = useTranslation()

  return (
    <Popover
      title={t('components.userInfo.title')}
      placement='left'
      content={
        <Container>
          <Information>
            <label>{t('components.userInfo.gender')}</label>
            <TextMedium>{data.gender}</TextMedium>
          </Information>
          <Information>
            <label>{t('components.userInfo.country')}</label>
            <TextMedium>{data.country}</TextMedium>
          </Information>
          <Information>
            <label>{t('components.userInfo.state')}</label>
            <TextMedium>{data.state}</TextMedium>
          </Information>
          <Information>
            <label>{t('components.userInfo.foodAlergies')}</label>
            <TextMedium>{data.foodAllergies}</TextMedium>
          </Information>
          <Information>
            <label>{t('components.userInfo.childrens')}</label>
            <TextMedium>{data.numberOfChildren}</TextMedium>
          </Information>
          <Information>
            <label>{t('components.userInfo.marketResearch')}</label>
            <TextMedium>{data.marketResearchParticipation}</TextMedium>
          </Information>
          <Information>
            <label>{t('components.userInfo.smoker')}:</label>
            <TextMedium>{data.smokerKind}</TextMedium>
          </Information>
        </Container>
      }
    >
      <Text>
        <UserOutlined /> {t('components.userInfo.viewUserInfo')}
      </Text>
    </Popover>
  )
}

export default UserInformation
