import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Spin } from 'antd'
import { Container } from './styles'
import { getUserCountRecords } from '@/apis/userAPI'
import { Information, TextMedium } from '../styles'
import { useTranslation } from 'react-i18next'

const Element = ({ userId }) => {
  const { t } = useTranslation()

  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    setLoading(true)

    getUserCountRecords(userId, id)
      .then(res => {
        setData(res.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [userId, id])

  return (
    <Spin spinning={loading}>
      <Container>
        <Information>
          <TextMedium>{t('components.userCount.submission')}</TextMedium>
          <div>
            <label>{t('components.userCount.surveyCount')}</label>{' '}
            <TextMedium>{data.submissionCount}</TextMedium>
          </div>
        </Information>
        <Information>
          <div>
            <label>{t('components.userCount.validCount')}</label>{' '}
            <TextMedium className='green'>{data.valid}</TextMedium>
          </div>
          <div>
            <label>{t('components.userCount.invalidCount')}</label>{' '}
            <TextMedium className='red'>{data.invalid}</TextMedium>
          </div>
        </Information>
      </Container>
    </Spin>
  )
}

export default Element
