import React from 'react'
import Flavorwiki from '@/components/Flavorwiki'
import Footer from './Footer'
import ScreenHeader from './ScreenHeader'
import ScreenMain from './ScreenMain'

import { Container, WhiteSection, Header, Screen } from './styles'

const Layout = ({
  title,
  description,
  mainComponent,
  extraRedirect
}) => {
  return (
    <Container>
      <Screen>
        <Header left>
          <Flavorwiki />
        </Header>
        <ScreenHeader title={title} description={description} />
      </Screen>
      <WhiteSection>
        <main>
          <ScreenMain
            mainComponent={mainComponent}
            extraRedirect={extraRedirect}
          />
        </main>

        <Footer />
      </WhiteSection>
    </Container>
  )
}

export default Layout
