import React from 'react'
import useCustomHistory from '@/hooks/useCustomHistory'
import { useTranslation } from 'react-i18next'
import { Wrapper, AntSyncOutlined } from './styles'

const Caption = () => {
  const { t } = useTranslation()
  const { refreshHistory } = useCustomHistory()

  const handleRefresh = () => {
    refreshHistory()
  }

  return (
    <Wrapper>
      {t('components.survey.header')}
      <AntSyncOutlined onClick={handleRefresh} />
    </Wrapper>
  )
}

export default Caption
