import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useCustomHistory from '@/hooks/useCustomHistory'
import ProductFilter from '../SelectProduct'
import FilterContainImage from './ContainImage'
import FilterRedFlagUsers from './RedFlagUsers'
import FilterValidatedBy from './ValidatedBy'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import {
  FilterWrapper,
  FilterHeader,
  FilterBody,
  FilterBodyContent,
  FilterFooter
} from './styles'

const FilterValidations = ({ setOpenFilter }) => {
  const { t } = useTranslation()
  const {
    location,
    addSearchKeyValue,
    deleteSearchKey,
    checkSearchKeyExisted,
    getValueBySearchKey
  } = useCustomHistory()

  const [products, setProducts] = useState([])
  const [validators, setValidators] = useState([])
  const [containImg, setContainImg] = useState('all')
  const [redflagged, setRedflagged] = useState(false)

  useEffect(() => {
    setValidators([])
    setProducts([])
    setContainImg('all')
    setRedflagged(false)
    if (checkSearchKeyExisted('validators')) {
      setValidators(getValueBySearchKey('validators'))
    }
    if (checkSearchKeyExisted('products')) {
      setProducts(getValueBySearchKey('products'))
    }
    if (checkSearchKeyExisted('containImg')) {
      setContainImg(getValueBySearchKey('containImg'))
    }
    if (checkSearchKeyExisted('redflagged')) {
      setRedflagged(getValueBySearchKey('redflagged') === 'true')
    }
  }, [location])

  const handleSaveFilters = () => {
    addSearchKeyValue('products', products)
    addSearchKeyValue('validators', validators)
    addSearchKeyValue('containImg', containImg)
    addSearchKeyValue('redflagged', redflagged)
    deleteSearchKey('page')

    setOpenFilter(false)
  }

  const handleClearFilter = () => {
    setValidators([])
    setProducts([])
    setContainImg('all')
    setRedflagged(false)

    deleteSearchKey('products')
    deleteSearchKey('validators')
    deleteSearchKey('containImg')
    deleteSearchKey('redflagged')
    deleteSearchKey('page')

    setOpenFilter(false)
  }

  return (
    <FilterWrapper>
      <FilterHeader>
        <h3>{t('components.survey.tabs.filters.header')}</h3>
        <Button
          type="danger"
          size="small"
          icon={<CloseOutlined />}
          onClick={() => setOpenFilter(false)}
        ></Button>
      </FilterHeader>
      <FilterBody>
        <FilterBodyContent>
          <ProductFilter
            filterType="validation"
            products={products}
            setFilterProduct={setProducts}
          />
          <FilterValidatedBy
            validators={validators}
            setFilterValidatedBy={setValidators}
          />
        </FilterBodyContent>
        <FilterBodyContent>
          <FilterContainImage
            containImg={containImg}
            setFilterContainImg={setContainImg}
          />
          <FilterRedFlagUsers
            redflagged={redflagged}
            setFilterRedFlag={setRedflagged}
          />
        </FilterBodyContent>
      </FilterBody>
      <FilterFooter>
        <Button type="text" onClick={handleClearFilter}>
          <strong>{t('components.survey.tabs.calander.clear')}</strong>
        </Button>
        <Button shape="round" onClick={handleSaveFilters}>
          {t('components.survey.tabs.filters.save')}
        </Button>
      </FilterFooter>
    </FilterWrapper>
  )
}

export default FilterValidations
