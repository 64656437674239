import React from 'react'
import { useTranslation } from 'react-i18next'
import { Information, TextMedium } from '../styles'

const Element = ({ data = {} }) => {
  const { t } = useTranslation()

  const { finishedAt, screenerFinishedAt } = data

  return (
    <React.Fragment>
      <Information>
        <label>{t('components.enrollmentInformation.finishedAt')}</label>
        <TextMedium>{finishedAt}</TextMedium>
      </Information>

      <Information>
        <label>
          {t('components.enrollmentInformation.screenerFinishedAt')}
        </label>
        <TextMedium>{screenerFinishedAt}</TextMedium>
      </Information>
    </React.Fragment>
  )
}

export default Element
