import { useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import { getSignedUrl, updateSurvey } from '@/apis'
import {
  Modal,
  Label,
  SurveyName,
  InputFile,
  ButtonUpload,
  FileUploaded,
  FileName,
  CheckIcon,
  DeleteIcon,
  FilePreview
} from './styles'

const ModalGuide = ({ open, onClose, survey }) => {
  const [file, setFile] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const handleOnClick = () => {
    const elementInput = document.querySelector('.input-support-guide')
    elementInput.click()
  }

  const handleUploadFile = (e) => {
    const data = e.target.files[0]

    if (data) {
      setFile(data)
    }
  }

  const handleRemoveFile = () => {
    setFile(null)
  }

  const handleSaveFile = async () => {
    try {
      if (file) {
        setIsLoading(true)
        const fileResp = await getSignedUrl(file.type)
        await axios.put(fileResp.signedUrl, file, {
          headers: {
            'Content-Type': file.type
          }
        })
        const guideUrl = fileResp.fileLink
        await updateSurvey(survey?._id, { guideUrl })
        message.success('Update support guide successfully')
        setIsLoading(false)
        onClose()
        history.go(0)
      }
    } catch (error) {
      message.error('Unable to update support guide, please try again later')
      setIsLoading(false)
    }
  }

  const fileUrl = useMemo(() => {
    if (file) {
      const src = URL.createObjectURL(file)
      return src
    }
    return survey?.guideUrl
  }, [file, survey])

  return (
    <Modal
      centered
      width="50%"
      title="Edit support guide"
      okText="Save"
      butt
      open={open}
      onCancel={onClose}
      onOk={handleSaveFile}
      okButtonProps={{
        disabled: !file,
        loading: isLoading
      }}
      cancelButtonProps={{
        loading: isLoading
      }}
    >
      <Label>survey name</Label>
      <SurveyName>{survey?.name}</SurveyName>

      <Label>attach document</Label>
      <InputFile
        className="input-support-guide"
        type="file"
        accept="application/pdf"
        onChange={handleUploadFile}
      />
      <ButtonUpload onClick={handleOnClick}>
        <UploadOutlined />
        <span>Upload a Document</span>
      </ButtonUpload>

      <Label>Uploaded</Label>

      {file && (
        <FileUploaded>
          <div>
            <CheckIcon />
            <FileName>{file?.name}</FileName>
          </div>

          <DeleteIcon onClick={handleRemoveFile} />
        </FileUploaded>
      )}

      {fileUrl && (
        <FilePreview title="guide-file" src={fileUrl || survey?.guideUrl} />
      )}
    </Modal>
  )
}

export default ModalGuide
