import styled from 'styled-components'

export const Container = styled.div`
  background-color: #FFFFFF;
  text-align: center;
  padding: 1rem 1rem;

  button {
    margin-bottom: 1rem;
  }
`

