import React from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import TablePagination from '@/components/Pagination'
import { message, Tooltip } from 'antd'
import FormatCountry from '@/utils/getCountryName'
import Vector from '@/assets/images/Vector.svg'
import {
  PaymentTable,
  PaymentTableWrapper,
  VectorIcon,
  EmptyText,
  EmptyDescription,
  ProductWrapper,
  BulletTextWrapper,
  SurveyMain,
  SurveyNameWrapper,
  SurveyCodeWrapper,
  ProductTypeWrapper,
  CountryWrapper,
  UnprocessedWrapper,
  NoEntriesWrapper,
  LiveWrapper,
  UnprocessedMain,
  NoEntriesMain,
  MainLayout,
  LastUpdatedWrapper,
  TotalValidWrapper,
  TotalInvalidWrapper,
  TotalEscalatedWrapper,
  Text
} from './styles'

const SurveysTable = ({ surveyList, pagination }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const navigateToValidation = record => {
    const { unprocessed, valid, invalid } = record

    if (unprocessed === 0 && invalid === 0 && valid === 0) {
      message.warning(t('components.table.survey.columns.warningText'))
    } else {
      history.push(`/survey/${record._id}/enrollments`)
    }
  }

  const columns = [
    {
      title: t('components.table.survey.columns.survey'),
      key: 'survey',
      width: '30%',
      ellipsis: {
        showTitle: false
      },
      render: record => (
        <Tooltip title={record.name} placement='topLeft'>
          <SurveyMain>
            <SurveyNameWrapper onClick={() => navigateToValidation(record)}>
              {record.name}
            </SurveyNameWrapper>
            <SurveyCodeWrapper>{record.code}</SurveyCodeWrapper>
            <ProductTypeWrapper>{record.productType}</ProductTypeWrapper>
          </SurveyMain>
        </Tooltip>
      )
    },
    {
      title: t('components.table.survey.columns.country'),
      key: 'country',
      width: '15%',
      dataIndex: 'country',
      ellipsis: {
        showTitle: false
      },
      render: country => (
        <Tooltip title={FormatCountry(country)} placement='topLeft'>
          <CountryWrapper>{FormatCountry(country)}</CountryWrapper>
        </Tooltip>
      )
    },
    {
      title: t('components.table.survey.columns.status'),
      key: 'status',
      width: '15%',
      render: record => (
        <div>
          {record.unprocessed > 0 ? (
            <MainLayout>
              <UnprocessedMain>
                <UnprocessedWrapper>
                  {record.unprocessed} {t('components.survey.tabs.unprocessed')}
                </UnprocessedWrapper>
                <LiveWrapper>
                  {record.state === 'active'
                    ? t('components.survey.tabs.filters.surveystatus.live')
                    : t(
                        'components.survey.tabs.filters.surveystatus.suspended'
                      )}
                </LiveWrapper>
              </UnprocessedMain>
              <LastUpdatedWrapper>{record.lastupdate}</LastUpdatedWrapper>
            </MainLayout>
          ) : (
            <MainLayout>
              <NoEntriesMain>
                <NoEntriesWrapper>
                  {t('components.survey.tabs.noentries')}
                </NoEntriesWrapper>
                <LiveWrapper>
                  {record.state === 'active'
                    ? t('components.survey.tabs.filters.surveystatus.live')
                    : t(
                        'components.survey.tabs.filters.surveystatus.suspended'
                      )}
                </LiveWrapper>
              </NoEntriesMain>
              <LastUpdatedWrapper>{record.lastupdate}</LastUpdatedWrapper>
            </MainLayout>
          )}
        </div>
      )
    },
    {
      title: 'SURVEY TYPE',
      key: 'surveyType',
      width: '10%',
      render: record => {
        if (record.fraudCheck) return 'Fraud Check'
        if (record.preRecruitSurvey) return 'Pre Recruit'
        return 'Normal'
      }
    },
    {
      title: t('components.table.survey.columns.totalValid'),
      key: 'totalValid',
      width: '10%',
      render: record => <TotalValidWrapper>{record.valid}</TotalValidWrapper>
    },
    {
      title: t('components.table.survey.columns.totalInValid'),
      key: 'totalInvalid',
      width: '10%',
      render: record => (
        <TotalInvalidWrapper>{record.invalid}</TotalInvalidWrapper>
      )
    },
    {
      title: 'TOTAL ESCALATED',
      key: 'totalEscalated',
      width: '10%',
      render: record => (
        <TotalEscalatedWrapper>{record.escalated}</TotalEscalatedWrapper>
      )
    },
    {
      title: t('components.table.survey.columns.product'),
      key: 'products',
      dataIndex: 'products',
      width: '20%',
      ellipsis: {
        showTitle: false
      },
      render: product => (
        <ProductWrapper>
          <Tooltip
            trigger='hover'
            placement='top'
            overlayInnerStyle={{
              color: '#E6EFFB',
              backgroundColor: '#161D34',
              borderRadius: '0.625rem'
            }}
            title={
              <div>
                <BulletTextWrapper>
                  {product.map(item => (
                    <li>{item}</li>
                  ))}
                </BulletTextWrapper>
              </div>
            }
          >
            <Text>{product[0]}</Text>
          </Tooltip>
        </ProductWrapper>
      )
    }
  ]

  const notFoundSmiley = {
    emptyText: (
      <span>
        <VectorIcon src={Vector} />
        <EmptyText>{t('component.survey.table.notfound.text')}</EmptyText>
        <EmptyDescription>
          {t('component.survey.table.notfound.description')}
        </EmptyDescription>
      </span>
    )
  }

  return (
    <PaymentTableWrapper>
      <PaymentTable
        rowClassName={(record, index) =>
          index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
        }
        rowKey={record => `${record._id}-${record.code}`}
        columns={columns}
        dataSource={surveyList}
        locale={!surveyList.length && notFoundSmiley}
        scroll={{ y: 400 }}
        pagination={false}
        footer={() => <TablePagination pagination={pagination} />}
      />
    </PaymentTableWrapper>
  )
}

export default SurveysTable
