import React from 'react'
import { Header, Title, Description } from './styles'

const ScreenHeader = ({ title, description }) => (
  <Header>
    <Title>{title}</Title>
    <Description dangerouslySetInnerHTML={{ __html: description }} />
  </Header>
)

export default ScreenHeader
