import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getEnrollmentAnswers as getEnrollmentAnswersApi,
  updateEnrollment as updateEnrollmentApi
} from '@/apis/validationAPI'

export const getEnrollmentAnswers = createAsyncThunk(
  'modalValidation/getEnrollmentAnswers',
  async (id) => {
    try {
      const response = await getEnrollmentAnswersApi(id)
      return response
    } catch {
      throw new Error()
    }
  }
)

export const updateEnrollment = createAsyncThunk(
  'modalValidation/updateEnrollment',
  async ({ id, payload }) => {
    try {
      await updateEnrollmentApi(id, payload)
    } catch {
      throw new Error()
    }
  }
)
