import i18n from '@/i18n'
import { Modal, message } from 'antd'
import { Content } from './styles'

const getErrorKey = (code, defaultError) => {
  switch (code) {
    case 'user.notVerified':
      return 'validate.userNotVerified'

    case 'email.invalid':
      return 'validate.email_invalid'

    case 'emailChecker.noresponse':
      return 'validate.emailChecker.noresponse'

    case 'email.unregistered':
      return 'validate.email_unregistered'

    case 'user.inactive':
      return 'validate.user_inactive'

    case 'user.invalid':
      return 'validate.user.invalid'

    case 'operator.exists':
      return 'validate.operatorExist'

    case 'token.expired':
      return 'validate.token.expired'

    case 'password.notMatch':
      return 'validate.password.notMatch'

    case 'password.invalid':
      return 'validate.password.invalid'

    case 'domain.blocked':
      return 'validate.domain_blocked'

    case 'INVALID:Username or password are not correct.':
      return 'validate.Username or password are not correct'

    default:
      return defaultError
  }
}

const ErrorMessage = ({ modal = false, text, code, defaultError }) => {
  if (!text && code && defaultError) {
    const errorKey = getErrorKey(code, defaultError)

    const textFromCode = i18n.t(errorKey)

    if (!modal) {
      message.destroy()
      message.error(textFromCode)
    }

    if (modal) {
      Modal.error({
        okButtonProps: { danger: true },
        content: <Content>{textFromCode}</Content>
      })
    }
  }

  if (!modal && text) {
    message.destroy()
    message.error(text)
  }

  if (modal && text) {
    Modal.error({
      okButtonProps: { danger: true },
      content: <Content>{text}</Content>
    })
  }
}

export default ErrorMessage
