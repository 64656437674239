import { useDispatch } from 'react-redux'
import {
  setAuthenticatedUser,
  removeAuthenticatedUser,
  setUserEmail
} from '@/store/authentication'

const useAuthentication = () => {
  const dispatch = useDispatch()

  const methods = {
    setUserToken: token => {
      dispatch(setAuthenticatedUser(token))
    },
    setUserEmailData: data => {
      dispatch(setUserEmail(data))
    },

    remove: () => {
      dispatch(removeAuthenticatedUser())
    }
  }

  return methods
}

export default useAuthentication
