import { GET } from '@/utils/axios'

export const getSearchSurveys = async (key) => {
  const response = await GET(`/global-search/surveys`, {
    useAuthorization: true,
    params: { key }
  })

  return response.data
}

export const getSearchEnrollments = async (key) => {
  const response = await GET(`/global-search/enrollments`, {
    useAuthorization: true,
    params: { key }
  })

  return response.data
}
