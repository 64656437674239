import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import './App.less'
import RoutePublic from '@/containers/RoutePublic'
import RoutePrivate from '@/containers/RoutePrivate'
import Welcome from '@/pages/Welcome'
import Surveys from '@/pages/Surveys'
import Enrollments from '@/pages/Enrollments'
import ShowAttachment from '@/pages/ShowAttachment'
import Receipts from '@/pages/Receipts'
import GlobalSearch from '@/pages/GlobalSearch'

function App() {
  return (
    <Router>
      <Switch>
        <RoutePublic exact path="/">
          <Welcome />
        </RoutePublic>
        <RoutePrivate path="/dashboard">
          <Surveys />
        </RoutePrivate>
        <RoutePrivate path="/survey/:id/enrollments">
          <Enrollments />
        </RoutePrivate>
        <RoutePrivate path="/survey/:id/receipts">
          <Receipts />
        </RoutePrivate>
        <RoutePrivate path="/attachment">
          <ShowAttachment />
        </RoutePrivate>
        <RoutePrivate path="/search">
          <GlobalSearch />
        </RoutePrivate>
      </Switch>
    </Router>
  )
}

export default App
