import { useTranslation } from 'react-i18next'
import { Select, Checkbox } from 'antd'

import { SELECT_ACTION_OPTIONS } from '@/utils/constants/constants'

const SelectActions = ({
  receipts,
  selectedReceipts,
  handleActions,
  handleSelectAll
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <Checkbox
        onChange={handleSelectAll}
        checked={
          receipts.length !== 0 && selectedReceipts.length === receipts.length
        }
      >
        {t('components.productSelect.selectAll')}
      </Checkbox>
      {!!selectedReceipts.length && (
        <Select
          placeholder={t('components.productSelect.selectAction')}
          bordered={false}
          onChange={handleActions}
          style={{
            width: 140
          }}
        >
          {SELECT_ACTION_OPTIONS.map((option) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  )
}

export default SelectActions
