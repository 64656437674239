import styled from 'styled-components'

export const MenuBarWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding-bottom: 0.625rem;
`

export const Wrapper = styled.div`
  padding: 1rem 1.25em;
  background: #f7fafe;
  border: 1px solid rgba(188, 188, 198, 0.5);
  box-shadow: inset 0 -1px 0 #e5eaf4;
  border-radius: 0.625rem;
`
