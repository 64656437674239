import { notification } from 'antd'
import { CheckCircleTwoTone } from '@ant-design/icons'

export const updateOptions = (receipts) => {
  for (const receipt of receipts) {
    if (Object.keys(receipt?.options)?.length) {
      const {
        browserInfo: { ipAddress }
      } = receipt
      const duplicateReceipts = receipts.filter(
        (ele) => ele.browserInfo.ipAddress === ipAddress
      )
      duplicateReceipts.map((item) => {
        item.show = true
        return (item.options = receipt.options)
      })
    }
  }
  return receipts
}

export const openMarkedDuplicateNotification = () => {
  notification.open({
    message: 'Mark as duplicated',
    description:
      'Entry has been mark as duplicate. You can see all of the duplicate entries easily at the duplicate tab.',
    duration: 3,
    icon: <CheckCircleTwoTone />
  })
}

export const openUnmarkDuplicateNotification = () => {
  notification.open({
    message: 'UnMarked as duplicate',
    description: 'Entry has been unmark as duplicate.',
    duration: 3,
    icon: <CheckCircleTwoTone />
  })
}

export const openMarkEntryNotification = (action) => {
  notification.open({
    message: `Marked as ${action}`,
    description: `Entry has been mark as ${action}.`,
    duration: 3,
    icon: <CheckCircleTwoTone />
  })
}
