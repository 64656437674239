import styled from 'styled-components'
import { Input, Radio, Col, Button, Tabs as AntdTabs } from 'antd'

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledAnswersContainer = styled.div`
  padding: 1rem 1rem 0;
  height: calc(100% - 5.5rem);

  @media screen and (max-width: 1300px) {
    height: calc(100% - 5.75rem);
  }
`

export const StyledMidCol = styled(Col)`
  border-left: 1px solid #eef0f4;
  padding-top: 1rem;
`

export const StyledCol = styled(Col)`
  border-left: 1px solid #eef0f4;
`

export const StyledButton = styled(Button)`
  border-radius: 60px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0.125rem 0 rgba(0, 0, 0, 0.016);
`

export const StyledAnswersFooterWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  border-top: 1px solid #eef0f4;
  padding: 0.5rem;

  button {
    margin-right: 0.5rem;
  }
`

export const BreakLine = styled.div`
  height: 1px;
  background-color: #e5eaf4;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
`

export const RadioGroup = styled(Radio.Group)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => props.marginBottom || 0};
`

export const BasicEdit = styled.div`
  margin-bottom: 0.75rem;

  .ant-form-item {
    margin-bottom: 0.5rem;
  }

  .select-hidden-from-chart {
    width: 12.5rem;
  }
`

export const StyledLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .anticon {
    color: #367f98;
  }
`

export const TextArea = styled(Input.TextArea)`
  resize: none;

  .ant-input-textarea-show-count::after {
    position: absolute;
    bottom: 1.2rem;
    right: 0.5rem;
  }
`

export const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.875rem;
  border-bottom: 1px solid #eef0f4;
  padding-left: 1rem;
`

export const StyledCountWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
  align-items: center;

  .anticon {
    color: ${(props) => (props.type === 'warning' ? '#ffe0a3' : '#4ea3bf')};
  }
`

export const StyledSupportWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;
  padding-right: 1rem;

  span {
    font-size: 0.875rem;
    color: #8c8f9f;
  }

  span:hover {
    color: #367f98;
  }

  .anticon {
    color: #367f98;
  }
`

export const Wrapper = styled.section`
  height: 100%;

  .ant-form {
    height: 100%;

    > .ant-row {
      height: 100%;
    }
  }
`

export const Tabs = styled(AntdTabs)`
  padding-top: 0.45rem;

  .ant-tabs-nav-list {
    gap: 4rem;

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
    }
  }
`

export const EntryDetails = styled.div`
  padding-left: 1rem;
`
