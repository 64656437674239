import React, { useState } from 'react'
import FormSignin from '@/components/FormSignin'
import { useHistory } from 'react-router-dom'
import { signIn } from '@/apis'
import useAuthentication from '@/hooks/useAuthentication'
import messageError from '@/utils/messageError'

const FormSigninContainer = () => {
  const history = useHistory()
  const authentication = useAuthentication()
  const [loading, setLoading] = useState(false)

  const onSubmit = (formData) => {
    formData.emailAddress = formData.email.toLowerCase()

    setLoading(true)
    signIn(formData)
      .then(({ data }) => {
        authentication.setUserToken(data.token)
        authentication.setUserEmailData(data.user.emailAddress)
        history.push('/dashboard')
      })
      .catch(({ errorCode }) => {
        setLoading(false)

        messageError({
          modal: true,
          code: errorCode,
          defaultError: 'There was a problem with the sign in process. Please try again or contact support.'
        })
      })
  }

  return <FormSignin loading={loading} onSubmit={onSubmit} />
}

export default FormSigninContainer
