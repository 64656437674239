export const FILTER_OPTIONS = ['7d', 'All', 'Custom']
export const BG_COLOR_ACTIVE = '#FFFFFF'
export const BG_COLOR_DEACTIVE = '#F7FAFE'

export const TEXT_COLOR_ACTIVE = '#367f98'
export const TEXT_COLOR_DEACTIVE = '#94a2b3'
export const DEFAULT_FILTER_OPTION = 'All'
export const CUSTOM_FILTER_OPTION = 'Custom'

export const PAGES_SIZE = [
  { key: '10', value: 10 },
  { key: '20', value: 20 },
  { key: '50', value: 50 },
  { key: '100', value: 100 }
]

export const SELECT_ACTION_OPTIONS = [
  { id: 'duplicate', name: 'Mark as duplicate' },
  { id: 'valid', name: 'Set  as Valid' },
  { id: 'invalid', name: 'Set as Invalid' },
  { id: 'escalation', name: 'Escalate' }
]

export const SHOW_USER_OPTIONS = [
  {
    value: 0,
    label: 'Show'
  },
  {
    value: 1,
    label: 'Hide'
  },
  {
    value: 2,
    label: 'Hide from shared users'
  }
]
