import { createSlice } from '@reduxjs/toolkit'

import { getEnrollmentAnswers, updateEnrollment } from './thunk'

const modalValidationSlice = createSlice({
  name: 'modalValidation',
  initialState: {
    enrollmentAnswers: [],
    enrollmentAnswersLoading: false,
    enrollmentAnswersError: false,
    selectedEnrollment: null,
    enrollmentUpdating: false,
    enrollmentUpdateSuccess: false,
    enrollmentUpdateError: false,
    logs: [],
    isLogsLoading: false,
    isLogsError: false
  },
  extraReducers: {
    [getEnrollmentAnswers.pending]: (state) => {
      state.enrollmentAnswersError = false
      state.enrollmentAnswersLoading = true
    },
    [getEnrollmentAnswers.fulfilled]: (state, action) => {
      state.enrollmentAnswers = action.payload || []
      state.enrollmentAnswersError = false
      state.enrollmentAnswersLoading = false
    },
    [getEnrollmentAnswers.rejected]: (state) => {
      state.enrollmentAnswersError = true
      state.enrollmentAnswersLoading = false
    },
    [updateEnrollment.pending]: (state) => {
      state.enrollmentUpdating = true
      state.enrollmentUpdateError = false
      state.enrollmentUpdateSuccess = false
    },
    [updateEnrollment.fulfilled]: (state) => {
      state.enrollmentUpdating = false
      state.enrollmentUpdateSuccess = true
      state.enrollmentUpdateError = false
    },
    [updateEnrollment.rejected]: (state) => {
      state.enrollmentUpdating = false
      state.enrollmentUpdateSuccess = false
      state.enrollmentUpdateError = true
    }
  }
})

const { reducer } = modalValidationSlice

export default reducer
