import { useState, useEffect } from 'react'

import Logs from '@/components/Logs'
import { getValidationLogs } from '@/apis'

const Container = ({ enrollmentId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [logs, setLogs] = useState([])
  const [enrollment, setEnrollment] = useState({})

  const fetchLogs = async (id) => {
    setIsLoading(true)
    const resp = await getValidationLogs(id)
    const { data, enrollment } = resp || {}
    setLogs(data)
    setEnrollment(enrollment)
    setIsLoading(false)
  }

  useEffect(() => {
    if (enrollmentId) {
      fetchLogs(enrollmentId)
    }
  }, [enrollmentId])

  return <Logs logs={logs} enrollment={enrollment} isLoading={isLoading} />
}

export default Container
