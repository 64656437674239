import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AntTabs } from './styles'

const TabsSearchResults = ({ active, setActive, data = {} }) => {
  const { t } = useTranslation()

  const onChange = (key) => {
    setActive(key)
  }

  const items = useMemo(() => {
    const result = [
      {
        label: t('components.table.tabs.receipts.all'),
        key: 'all'
      }
    ]

    if (data.surveys) {
      result.push({
        label: (
          <div>
            Survey <span className="total-number">{data.surveys.length}</span>
          </div>
        ),
        key: 'surveys'
      })
    }

    if (data.enrollments) {
      result.push({
        label: (
          <div>
            Validation entry{' '}
            <span className="total-number">{data.enrollments.length}</span>
          </div>
        ),
        key: 'enrollments'
      })
    }

    return result
  }, [data])

  return <AntTabs activeKey={active} onChange={onChange} items={items} />
}

export default TabsSearchResults
