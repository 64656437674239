import React, { createContext, useContext, useState, useMemo } from "react"
import { message } from "antd"

const EnrollmentsContext = createContext()

export const EnrollmentsProvider = ({ children }) => {
  const [currentEnrollment, setCurrentEnrollment] = useState(null)
  const [dataSource, setDataSource] = useState([])
  const [pagination, setPagination] = useState({ limit: 10, page: 1, total: 0 })

  const handleNextEnrollment = () => {
    for (const [index, item] of dataSource.entries()) {
      if (item.enrollmentInfoId === currentEnrollment.enrollmentInfoId) {
        if (dataSource[index + 1]) {
          return dataSource[index + 1]
        }
      }
    }

    const { limit, page, total } = pagination
    if (page * limit < total) {
      message.info('This is the last item of this page. Please go to the next page.');
    }

    return null
  }

  const value = useMemo(() => {
    return {
      currentEnrollment,
      setCurrentEnrollment,
      dataSource,
      setDataSource,
      pagination,
      setPagination,
      handleNextEnrollment,
    }
  }, [
    currentEnrollment,
    setCurrentEnrollment,
    dataSource,
    setDataSource,
    pagination,
    setPagination,
  ])

  return (
    <EnrollmentsContext.Provider value={value}>
      {children}
    </EnrollmentsContext.Provider>
  )
}

export const useEnrollments = () => {
  const context = useContext(EnrollmentsContext)
  if (!context) {
    throw new Error("useEnrollments must be used within a EnrollmentsProvider")
  }
  return context
}
