import React from 'react'
import { useTranslation } from 'react-i18next'
import { AntRadio, Wrapper, Text } from './styles'

const FilterContainImage = ({ containImg, setFilterContainImg }) => {
  const { t } = useTranslation()

  const onChange = (e) => {
    const value = e.target.value

    setFilterContainImg(value)
  }

  return (
    <Wrapper>
      <Text>{t('components.survey.entries.filters.containimage.image')}</Text>
      <AntRadio.Group value={containImg} onChange={onChange}>
        <AntRadio value="all">
          {t('components.survey.entries.filters.containimage.all')}
        </AntRadio>
        <AntRadio value="yes">
          {t('components.survey.entries.filters.containimage.yes')}
        </AntRadio>
        <AntRadio value="no">
          {t('components.survey.entries.filters.containimage.no')}
        </AntRadio>
      </AntRadio.Group>
    </Wrapper>
  )
}

export default FilterContainImage
