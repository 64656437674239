import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getSurvey } from '@/apis/surveyAPI'
import useSurveyInfo from '@/hooks/useSurveyInfo'
import Layout from '@/layouts/Default'
import CaptionEnrollment from '@/components/Caption/Enrollments'
import TabsReceipts from '@/components/Tabs/Receipts'
import Receipts from '@/containers/Receipts'
import { ButtonViewBy } from '@/components/Buttons'

import { Wrapper } from './styles'

const Page = () => {
  const { id } = useParams()
  const surveyInfo = useSurveyInfo()
  const [survey, setSurvey] = useState({})

  useEffect(() => {
    getSurvey(id).then((res) => {
      surveyInfo.setSurvey(res.data)
      setSurvey(res.data)
    })
  }, [])

  return (
    <Layout>
      <CaptionEnrollment data={survey} />

      <Wrapper>
        <ButtonViewBy />

        <TabsReceipts />

        <Receipts />
      </Wrapper>
    </Layout>
  )
}

export default Page
