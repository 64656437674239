import React from 'react'
import ReactDOM from 'react-dom'
import App from '@/App'
import store from './store'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URI,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  ignoreErrors: [
    'Network Error',
    'Large Render Blocking Asset',
  ],
});

const queryClient = new QueryClient()

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
)
