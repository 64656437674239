import React from 'react'
import { Form, Input } from 'antd'
import useValidateRules from '@/hooks/useValidateRules'
import useFormFields from '@/hooks/useFormFields'

const FormItemPassword = ({
  name = 'password',
  autoComplete,
  onPressEnter
}) => {
  const rules = useValidateRules()
  const formFields = useFormFields()
  return (
    <Form.Item
      name={name}
      label={formFields.password.label}
      rules={rules.password}
    >
      <Input.Password
        placeholder={formFields.password.placeholder}
        autoComplete={autoComplete}
        onPressEnter={onPressEnter}
      />
    </Form.Item>
  )
}

export default FormItemPassword
