import Video from './Video'
import Image from './Image'
import { Container } from './styles'

const Component = ({ url, type }) => {
  if (type === 'video') return (
    <Container>
      <Video url={url} />
    </Container>
  )

  if (type === 'image') return (
    <Container>
      <Image url={url} />
    </Container>
  )

  return <></>
}

export default Component