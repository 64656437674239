import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Menu, Dropdown, Button } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import useAuthentication from '@/hooks/useAuthentication'
import { integrateUsersnap } from '@/utils/usersnap'

const Element = () => {
  const { t } = useTranslation()
  const authentication = useAuthentication()
  const userEmail = useSelector((state) => state.authentication.userEmail)
  const { authenticatedUser } = useSelector((state) => state.authentication)

  useEffect(() => {
    if (authenticatedUser) {
      integrateUsersnap()
    }
  }, [])


  const onClick = () => {
    authentication.remove()
  }

  return (
    <Dropdown
      placement="bottom"
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item key="1" onClick={onClick}>
            {t('components.logout')}
          </Menu.Item>
        </Menu>
      }
    >
      <Button type="text" icon={<UserOutlined />}>
        {userEmail}
      </Button>
    </Dropdown>
  )
}

export default Element
