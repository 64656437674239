import * as React from "react"

const DocumentIcon = (props) => (
  <svg
    width={28}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={14} cy={14} r={14} fill="url(#a)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.207 7.333h5.587c2.06 0 3.206 1.187 3.206 3.22v6.887c0 2.067-1.147 3.227-3.206 3.227h-5.587C9.18 20.667 8 19.507 8 17.44v-6.887c0-2.033 1.18-3.22 3.207-3.22Zm.18 3.107v-.006h1.992a.522.522 0 0 1 0 1.047h-1.992a.52.52 0 0 1 0-1.04Zm0 4.053h5.226a.52.52 0 0 0 0-1.04h-5.226a.52.52 0 0 0 0 1.04Zm0 3.047h5.226a.522.522 0 0 0 .467-.52c0-.273-.2-.5-.467-.526h-5.226a.53.53 0 0 0-.5.807c.106.166.3.266.5.24Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="a"
        x1={2.069}
        y1={3.5}
        x2={32.152}
        y2={15.435}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4EA3BF" />
        <stop offset={1} stopColor="#1C9ABA" />
      </linearGradient>
    </defs>
  </svg>
)

export default DocumentIcon
