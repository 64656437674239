import styled from 'styled-components'

export const StyledProductApproval = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-left: 1rem;
  background-color: #f7fafe;

  .product {
    margin-bottom: 0.5rem;
    color: #8c8f9f;
    font-size: 0.625rem;
  }
`

export const StyledApproval = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.7rem;
  gap: 0.625rem;
  font-size: 0.75em;
  cursor: pointer;
  color: ${props => (props.selected ? '#367F98' : '#272D4E')};
`

export const StyledReceipt = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.7rem;
  gap: 0.625rem;
  font-size: 0.75em;
  cursor: pointer;
  color: ${props => (props.selected ? '#367F98' : '#272D4E')};
`
