import React from 'react'
import useCustomHistory from '@/hooks/useCustomHistory'
import SizeChanger from './SizeChanger'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { RightLayout, LeftLayout, Wrapper, AntButton } from './styles'

const TablePagination = ({ pagination }) => {
  const { addSearchKeyValue } = useCustomHistory()

  const { total, page, limit } = pagination
  const totalPages = Math.ceil(total / limit)
  const pageFrom = page > 1 ? (page - 1) * limit : 1
  const pageTo = page * limit

  const handleNextPage = () => {
    addSearchKeyValue('page', parseInt(page) + 1)
  }

  const handlePreviousPage = () => {
    addSearchKeyValue('page', parseInt(page) - 1)
  }

  return (
    <Wrapper>
      <LeftLayout>
        {pageFrom}-{pageTo > total ? total : pageTo} of {total}
      </LeftLayout>
      <RightLayout>
        <SizeChanger limit={limit} />
        <AntButton disabled={page <= 1 ? true : false}>
          <LeftOutlined onClick={handlePreviousPage} />
        </AntButton>
        <b>{page}</b>/{totalPages ? totalPages : 0}
        <AntButton
          disabled={
            parseInt(page) === totalPages ||
            isNaN(totalPages) ||
            totalPages === 0
              ? true
              : false
          }
        >
          <RightOutlined onClick={handleNextPage} />
        </AntButton>
      </RightLayout>
    </Wrapper>
  )
}

export default TablePagination
