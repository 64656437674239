import React from 'react'
import { useTranslation } from 'react-i18next'
import { AntRadio, Wrapper, Text } from './styles'

const SurveyStatusFilter = ({ surveyStatus, setFilterSurveyStatus }) => {
  const { t } = useTranslation()

  const onChange = (e) => {
    const value = e.target.value
    setFilterSurveyStatus(value)
  }

  return (
    <Wrapper>
      <Text>{t('components.survey.tabs.filters.surveystatus.status')}</Text>
      <AntRadio.Group onChange={onChange} value={surveyStatus}>
        <AntRadio value="All">
          {t('components.survey.tabs.filters.surveystatus.all')}
        </AntRadio>
        <AntRadio value="active">
          {t('components.survey.tabs.filters.surveystatus.live')}
        </AntRadio>
        <AntRadio value="suspended">
          {t('components.survey.tabs.filters.surveystatus.suspended')}
        </AntRadio>
      </AntRadio.Group>
    </Wrapper>
  )
}

export default SurveyStatusFilter
