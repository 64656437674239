import { useDispatch } from 'react-redux'
import { setSurvey } from '@/store/surveySlice'

const useSurveyInfo = () => {
  const dispatch = useDispatch()

  const methods = {
    setSurvey: (data) => {
      dispatch(setSurvey(data))
    }
  }

  return methods
}

export default useSurveyInfo
