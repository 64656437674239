import { SHOW_USER_OPTIONS } from "@/utils/constants/constants"
import {
  ValidatorName,
  LogSentence,
  Valid,
  Invalid,
  FieldName,
} from "./styles"

const formatStatus = (input) => {
  if (input === "valid") return <Valid>Valid</Valid>
  else if (input === "invalid") return <Invalid>Invalid</Invalid>
  else return input
}

const formatBoolean = (bool) => {
  if (bool) return <Valid>True</Valid>
  return <Invalid>False</Invalid>
}

const findHiddenFromChartsLabel = (value) => {
  return SHOW_USER_OPTIONS.find((option) => option.value === value).label
}

export const formatLogsSentences = (log) => {
  const { olddata, newdata, validatorname } = log

  return Object.keys(newdata).map((key) => {
    if (key === "user") {
      return (
        <LogSentence>
          <ValidatorName>{validatorname}</ValidatorName>
          {newdata[key]?.blackListed
            ? " has added this user to blacklist"
            : " has removed this user from blacklist"}
        </LogSentence>
      )
    }

    if (key === "validation") {
      if (newdata[key] && olddata[key]) {
        return (
          <LogSentence>
            <ValidatorName>{validatorname}</ValidatorName> has changed this
            entry from {formatStatus(olddata[key])} to{" "}
            {formatStatus(newdata[key])}
          </LogSentence>
        )
      }

      return (
        <LogSentence>
          <ValidatorName>{validatorname}</ValidatorName> has marked this entry
          as {formatStatus(newdata[key])}
        </LogSentence>
      )
    }

    if (key === "notes" || key === "comment") {
      if (newdata[key] && olddata[key]) {
        return (
          <LogSentence>
            <ValidatorName>{validatorname}</ValidatorName> set{" "}
            <FieldName>{key}</FieldName> from "{olddata[key]}" to "
            {newdata[key]}"
          </LogSentence>
        )
      }

      return (
        <LogSentence>
          <ValidatorName>{validatorname}</ValidatorName> set{" "}
          <FieldName>{key}</FieldName> to "{newdata[key]}""
        </LogSentence>
      )
    }

    if (key === "savedRewards") {
      return (
        <LogSentence>
          <ValidatorName>{validatorname}</ValidatorName> has updated rewards
        </LogSentence>
      )
    }

    if (
      key === "escalation" ||
      key === "cleaned" ||
      key === "processed" ||
      key === "preRecruit" ||
      key === "fraudCheck"
    ) {
      if (
        typeof newdata[key] !== "undefined" &&
        typeof olddata[key] !== "undefined"
      ) {
        return (
          <LogSentence>
            <ValidatorName>{validatorname}</ValidatorName> set {key} from{" "}
            {formatBoolean(olddata[key])} to {formatBoolean(newdata[key])}
          </LogSentence>
        )
      }

      return (
        <LogSentence>
          <ValidatorName>{validatorname}</ValidatorName> set {key} to{" "}
          {formatBoolean(newdata[key])}
        </LogSentence>
      )
    }

    if (key === "hiddenFromCharts") {
      if (
        typeof newdata[key] !== "undefined" &&
        typeof olddata[key] !== "undefined"
      ) {
        return (
          <LogSentence>
            <ValidatorName>{validatorname}</ValidatorName> set hidden from
            charts from{" "}
            <Invalid>{findHiddenFromChartsLabel(olddata[key])}</Invalid> to{" "}
            <Valid>{findHiddenFromChartsLabel(newdata[key])}</Valid>
          </LogSentence>
        )
      }

      return (
        <LogSentence>
          <ValidatorName>{validatorname}</ValidatorName> set hidden from charts
          from to <Valid>{findHiddenFromChartsLabel(newdata[key])}</Valid>
        </LogSentence>
      )
    }

    if (key === "duplicate" && newdata[key]) {
      return (
        <LogSentence>
          <ValidatorName>{validatorname}</ValidatorName> set this enrollment
          entry <Invalid>Duplicate</Invalid>
        </LogSentence>
      )
    }

    if (newdata[key] && olddata[key]) {
      return (
        <LogSentence>
          <ValidatorName>{validatorname}</ValidatorName> set {key} from{" "}
          {olddata[key]} to {newdata[key]}
        </LogSentence>
      )
    }

    return (
      <LogSentence>
        <ValidatorName>{validatorname}</ValidatorName> set {key} to{" "}
        {newdata[key]}
      </LogSentence>
    )
  })
}

export const formatHoursMinutes = (time) => {
  return new Date(time).toTimeString().slice(0, 5)
}
