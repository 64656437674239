import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseCircleOutlined } from '@ant-design/icons'
import {
  AntModal,
  AntTable,
  AntCheckOutlined,
  EmailWrapper,
  AntTooltip
} from './styles'

const WarningsModal = ({ title, open, data, updated, onOk, onCancel }) => {
  const { t } = useTranslation()
  const [tooltipText, setTooltipText] = useState('Click to copy')
  const copyToClipBoard = text => {
    navigator.clipboard.writeText(text)
    setTooltipText('Copied to clipboard')
    setTimeout(() => setTooltipText('Click to copy'), 1000)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '10%' // Specify the desired width for Column 1
    },
    {
      title: 'Email',
      dataIndex: 'value',
      key: 'value',
      width: '10%', // Specify the desired width for Column 2

      render: email => (
        <EmailWrapper onClick={() => copyToClipBoard(email)}>
          <AntTooltip
            overlayInnerStyle={{
              color: ' #ffffff',
              backgroundColor: 'rgba(39, 45, 78, 0.9)',
              borderRadius: '3px'
            }}
            title={
              <div>
                {tooltipText.includes('clipboard') && <AntCheckOutlined />}
                {tooltipText}
              </div>
            }
          >
            {email}
          </AntTooltip>
        </EmailWrapper>
      )
    }
  ]

  const tableData = data.map((value, index) => ({
    key: index,
    value: value.emailAddress,
    name: value.userName
  }))
  return (
    <AntModal
      centered={true}
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseCircleOutlined />}
      width={700}
    >
      <p>
        {'Total Validated Entries:'} <b>{updated}</b>
      </p>
      <p>{t('components.modal.warnings.heading')}</p>
      <p>{t('components.modal.warnings.verifyText')}</p>

      <AntTable
        bordered
        footer={null}
        pagination={false}
        columns={columns}
        dataSource={tableData}
      />
    </AntModal>
  )
}

export default WarningsModal
