import styled from 'styled-components'

export const CardLayout = styled.div`
  background: #eff3f9;
  border-radius: 8px;
  height: 22.5rem;
  display: flex;
  justify-content: center;
  position: relative;
`
export const ImageContainer = styled.div`
  width: 15.5rem;
  height: 22.5rem;
  background-image: url('${(props) => props.imageUrl}');
  background-size: 15.5rem 22.5rem;
  background-repeat: no-repeat;
`

export const InfoWrapper = styled.div`
  heigth: 3.7rem;
  background: #f7fafe;
  border-radius: 8px;
  padding: 8px 12px;
  margin: 14px 0px 20px 0px;
`

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`

export const KeyWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8c8f9f;
`

export const ValueWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #272d4e;
`
