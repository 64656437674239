import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'

import { Container, Label, Wrapper } from './styles'

const ProductSelect = ({ products, handleChange, selectedProduct }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Wrapper>
        <Label>{t('components.productSelect.showReceipt')}</Label>

        <Select
          value={selectedProduct}
          onChange={handleChange}
          style={{
            width: 180
          }}
        >
          {products.map((product) => (
            <Select.Option key={product._id} value={product._id}>
              {product.name}
            </Select.Option>
          ))}
        </Select>
      </Wrapper>
    </Container>
  )
}

export default ProductSelect
