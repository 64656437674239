import styled from 'styled-components'
import { Tabs } from 'antd'

export const AntTabs = styled(Tabs)`
  .ant-tabs-tab-btn {
    color: ${(props) => props.theme.STATS_GREY_COLOR};

    .total-number {
      text-align: center;
      background: #e5eaf4;
      color: #8c8f9f;
      border-radius: 2.5rem;
      padding: 0.125rem 0.625rem;
      margin-left: 0.5rem;
    }
  }

  .ant-tabs-ink-bar {
    background: linear-gradient(111.64deg, #4ea3bf 8.84%, #1c9aba 97.87%);
    border-radius: 1px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #272d4e;

    .total-number {
      background: #272d4e;
      color: #ffffff;
    }
  }

  .selected-dates {
    color: #367f98;
  }

  width: 100%;
`
