import Layout from '@/layouts/Default'
import Table from '@/containers/Tables/Surveys'
import ButtonFilters from '@/components/Filters/FilterSurveys/ButtonFilters'
import InputSearch from '@/components/InputSearch'
import Caption from '@/components/Caption/Surveys'
import TabsSurvey from '@/components/Tabs/Survey'
import FilterCalendar from '@/components/Filters/FilterCalander'
import { MenuBarWrapper, Wrapper } from './styles'

const Page = () => {
  return (
    <Layout>
      <Caption />
      <Wrapper>
        <TabsSurvey />

        <MenuBarWrapper>
          <ButtonFilters />
          <FilterCalendar />
          <InputSearch />
        </MenuBarWrapper>

        <Table />
      </Wrapper>
    </Layout>
  )
}

export default Page
