import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useCustomHistory from '@/hooks/useCustomHistory'
import { AntTabs } from './styles'

const TabsReceipts = () => {
  const { t } = useTranslation()
  const { addSearchKeyValue, getValueBySearchKey } = useCustomHistory()

  const active = useMemo(() => {
    return getValueBySearchKey('tab') || 'all'
  }, [getValueBySearchKey])

  const onChange = (key) => {
    addSearchKeyValue('tab', key)
  }

  return (
    <AntTabs
      activeKey={active}
      onChange={onChange}
      items={[
        {
          label: t('components.table.tabs.receipts.all'),
          key: 'all'
        },
        {
          label: t('components.table.tabs.receipts.unprocessed'),
          key: 'unprocessed'
        },
        {
          label: t('components.table.tabs.receipts.valid'),
          key: 'valid'
        },
        {
          label: t('components.table.tabs.receipts.invalid'),
          key: 'invalid'
        },
        {
          label: t('components.table.tabs.receipts.markedAsDuplicate'),
          key: 'duplicate'
        },
        {
          label: t('components.table.tabs.receipts.escalated'),
          key: 'escalation'
        }
      ]}
    />
  )
}

export default TabsReceipts
