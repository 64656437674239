import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const RoutePublic = ({ children, ...props }) => {
  const { authenticatedUser } = useSelector((state) => state.authentication)

  return (
    <Route
      {...props}
      render={() =>
        authenticatedUser ? (
          <Redirect
            to={{
              pathname: '/dashboard'
            }}
          />
        ) : (
          children
        )
      }
    />
  )
}

export default RoutePublic
