import MarkDuplicateDisableIcon from '@/assets/icons/MarkDuplicateDisableIcon'
import MarkDuplicateIcon from '@/assets/icons/MarkDuplicateIcon'
import {
  ClockCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import {
  StyledWaitingTag,
  StyledInprocessTag,
  StyledInvalidTag,
  StyledValidTag,
  StyledPaidTag,
  StyledDuplicateTag,
  StyledEscalatedTag
} from './styles'

export const getReceiptTag = receipt => {
  const {
    duplicate,
    escalation,
    state,
    validation,
    paidShareOn,
    paidIncentivesOn
  } = receipt
  let tagsArray = []
  if (state === 'waiting-for-product')
    tagsArray.push(
      <StyledWaitingTag icon={<ClockCircleOutlined />}>
        Waiting
      </StyledWaitingTag>
    )
  if (state === 'finished' && validation === '')
    tagsArray.push(
      <StyledInprocessTag icon={<SyncOutlined />}>
        In process
      </StyledInprocessTag>
    )
  if (state === 'finished' && validation === 'invalid')
    tagsArray.push(
      <StyledInvalidTag icon={<CloseCircleOutlined />}>
        Invalid
      </StyledInvalidTag>
    )
  if (
    state === 'finished' &&
    validation === 'valid' &&
    paidShareOn === '0' &&
    paidIncentivesOn === '0'
  )
    tagsArray.push(
      <StyledValidTag icon={<CheckCircleOutlined />}>Valid</StyledValidTag>
    )
  if (
    state === 'finished' &&
    validation === 'valid' &&
    (paidShareOn !== '0' || paidIncentivesOn !== '0')
  )
    tagsArray.push(
      <StyledPaidTag icon={<CheckCircleOutlined />}>Paid</StyledPaidTag>
    )
  if (duplicate)
    tagsArray.push(
      <StyledDuplicateTag icon={<WarningOutlined />}>
        Duplicate
      </StyledDuplicateTag>
    )
  if (escalation)
    tagsArray.push(
      <StyledEscalatedTag icon={<ExclamationCircleOutlined />}>
        Escalated
      </StyledEscalatedTag>
    )
  return tagsArray
}

export const getMarkAsDuplicateIcon = (
  receipt,
  index,
  handleCreateDuplicate,
  handleDeleteDuplicate
) => {
  const { duplicate, validation, state, paidShareOn, paidIncentivesOn } =
    receipt
  if (duplicate) {
    return (
      <MarkDuplicateDisableIcon
        onClick={() => handleDeleteDuplicate(receipt._id)}
      />
    )
  } else if (
    validation === 'valid' &&
    state === 'finished' &&
    (paidShareOn !== '0' || paidIncentivesOn !== '0')
  ) {
    return null
  } else {
    return (
      <MarkDuplicateIcon
        onClick={() => handleCreateDuplicate(receipt, index)}
      />
    )
  }
}
