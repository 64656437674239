import { useHistory, useLocation } from 'react-router-dom'

const useCustomHistory = () => {
  const history = useHistory()
  const location = useLocation()
  const { search } = location
  const searchParams = new URLSearchParams(search)

  const removeDefaultValue = (key, value) => {
    //remove empty value
    if (
      key !== 'redflagged' &&
      key !== 'page' &&
      (!value || value.length === 0)
    ) {
      searchParams.delete(key)
    }
    //remove default surveyStatus, tab, and containImg
    if (key === 'surveyStatus' || key === 'tab' || key === 'containImg') {
      if (value.toLowerCase() === 'all') {
        searchParams.delete(key)
      }
    }
    //remove redflagged default value
    if (key === 'redflagged' && !value) {
      searchParams.delete(key)
    }
    //remove default limit
    if (key === 'limit' && value === '10') {
      searchParams.delete(key)
    }
    //remove default page number
    if (key === 'page' && value === 1) {
      searchParams.delete(key)
    }
  }

  const addSearchKeyValue = (key, value) => {
    searchParams.set(key, value)

    removeDefaultValue(key, value)

    history.push({
      search: searchParams.toString()
    })
  }

  const deleteSearchKey = (key) => {
    searchParams.delete(key)
    history.push({
      search: searchParams.toString()
    })
  }

  const checkSearchKeyExisted = (key) => {
    return searchParams.has(key)
  }

  const getValueBySearchKey = (key) => {
    if (key === 'country' || key === 'products' || key === 'validators') {
      return searchParams.get(key).split(',')
    }
    return searchParams.get(key)
  }

  const refreshHistory = () => {
    history.push({
      search: searchParams.toString()
    })
  }

  const navigateToPage = (endpoint) => {
    history.push(endpoint)
  }

  const resetFiltersInUrl = () => {
    searchParams.delete('country')
    searchParams.delete('products')
    searchParams.delete('surveyStatus')
    searchParams.delete('startDate')
    searchParams.delete('endDate')
    searchParams.delete('option')
    searchParams.delete('validators')
    searchParams.delete('containImg')
    searchParams.delete('redflagged')
    searchParams.delete('keyword')
    searchParams.delete('sort')
    searchParams.delete('sortCountry')
    searchParams.delete('sortValid')
    searchParams.delete('sortInvalid')
    searchParams.delete('limit')
    searchParams.delete('page')
    history.push({
      search: searchParams.toString()
    })
  }

  return {
    location,
    addSearchKeyValue,
    deleteSearchKey,
    checkSearchKeyExisted,
    getValueBySearchKey,
    refreshHistory,
    navigateToPage,
    resetFiltersInUrl
  }
}

export default useCustomHistory
