import styled from 'styled-components'
import { SyncOutlined } from '@ant-design/icons'

export const Wrapper = styled.div`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.25em;
  line-height: 28px;
  margin-bottom: 26px;
  color: #272d4e;
`
export const AntSyncOutlined = styled(SyncOutlined)`
  margin-left: 1rem;
  color: #231f20;
  font-size: 0.875rem;
  stroke-width: 2.5rem;
  stroke: #231f20;
  cursor: pointer;
`
