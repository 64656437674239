import React from 'react'
import { useTranslation } from 'react-i18next'
import Label from '../Label'
import { Information, TextMedium } from '../styles'

const Element = ({ data = {} }) => {
  const { t } = useTranslation()
  const { tasterID, userId, userName, email, paypalEmail } = data

  return (
    <React.Fragment>
      <Information>
        <Label
          text={t('components.enrollmentInformation.tasterRespondentLabel')}
          tooltip={t(
            'components.enrollmentInformation.tasterRespondentTooltip'
          )}
        />
        <TextMedium>{tasterID}</TextMedium>
      </Information>

      <Information>
        <Label
          text={t('components.enrollmentInformation.userId')}
          tooltip={t('components.enrollmentInformation.userIdTooltip')}
        />
        <TextMedium>{userId}</TextMedium>
      </Information>

      <Information>
        <label>{t('components.enrollmentInformation.userName')}</label>
        <TextMedium>{userName}</TextMedium>
      </Information>

      <Information>
        <label>{t('components.enrollmentInformation.userEmail')}</label>
        <TextMedium>{email}</TextMedium>
      </Information>

      <Information>
        <label>{t('components.enrollmentInformation.userPaypal')}</label>
        <TextMedium>{paypalEmail}</TextMedium>
      </Information>
    </React.Fragment>
  )
}

export default Element
