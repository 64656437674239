import { Row, Col, Form } from 'antd'
import { useSelector } from 'react-redux'
import { formatDateTimeString } from '@/utils/helperFunctions'
import ImageHolder from './ImageHolder'
import VideoHolder from './VideoHolder'
import UniversalValidator from '../UniversalValidator'
import { Section, Title, StyledValueContainer, ApprovedInfo } from './styles'

const NoRewardAnswers = ({
  form,
  enrollment = {},
  answers,
  isFraudCheck,
  isPreRecruit,
  onChangeUniversal
}) => {
  const { validator, validatedAt } = enrollment
  const user = useSelector(state => state.authentication.authenticatedUser)

  return (
    <Section>
      <Title>Other media for this enrollment</Title>

      <Form form={form}>
        <Row gutter={5}>
          <Col span={8}>
            <UniversalValidator
              form={form}
              isFraudCheck={isFraudCheck}
              isPreRecruit={isPreRecruit}
              onChangeUniversal={onChangeUniversal}
            />

            {validator && (
              <ApprovedInfo>
                <span>Approved by:</span>
                <span>{validator}</span>
              </ApprovedInfo>
            )}

            {validatedAt && (
              <ApprovedInfo>
                <span>Approved at:</span>
                <span>{formatDateTimeString(validatedAt)}</span>
              </ApprovedInfo>
            )}
          </Col>

          <Col span={16}>
            <StyledValueContainer>
              {answers.map(answer => {
                const { value, typeOfQuestion } = answer
                return value?.map((url, index) => {
                  const isUrl = typeof url === 'string'

                  if (isUrl && typeOfQuestion === 'upload-picture') {
                    return <ImageHolder key={index} url={url} />
                  }

                  if (isUrl && typeOfQuestion === 'upload-video') {
                    return <VideoHolder key={index} url={url} />
                  }

                  return null
                })
              })}
            </StyledValueContainer>
          </Col>
        </Row>
      </Form>
    </Section>
  )
}

export default NoRewardAnswers
