import moment from 'moment'

export const cleanObject = (rawData) => {
  if (typeof rawData !== 'object') return rawData
  return Object.fromEntries(
    Object.entries(rawData).filter(([_, value]) => value !== undefined)
  )
}

export const getNextProductUser = (productUsers, selectedProduct) => {
  const currentIndex = productUsers
    .map((item) => item.id)
    .indexOf(selectedProduct)
  for (let index = currentIndex; index < productUsers.length; index++) {
    const nextValue = productUsers[index + 1]
    if (nextValue === undefined) {
      return undefined
    } else if (nextValue?.value) {
      return nextValue
    }
  }
}

export const getPreviousProductUser = (productUsers, selectedProduct) => {
  const currentIndex = productUsers
    .map((item) => item.id)
    .indexOf(selectedProduct)
  for (let index = currentIndex; index > 0; index--) {
    const previousValue = productUsers[index - 1]
    if (previousValue === undefined) {
      return undefined
    } else if (previousValue?.value) {
      return previousValue
    }
  }
}

export const formatDate = (date) => {
  if (!date) return ''
  const isToday = moment(date).isSame(moment().startOf('day'), 'day')
  if (isToday) return 'Today'

  const isYesterday = moment(date).isSame(
    moment().subtract(1, 'days').startOf('day'),
    'day'
  )
  if (isYesterday) return 'Yesterday'

  return moment(date).format('MM/DD/YY')
}

export const groupLogsByDay = (logs) => {
  const data = logs.map((log) => {
    const { createdat: createdAt } = log

    return {
      ...log,
      formattedCreatedAt: formatDate(createdAt)
    }
  })

  return data
    .sort((a, b) => moment(b?.createdat) - moment(a?.createdat))
    .reduce((prev, curr) => {
      const { formattedCreatedAt } = curr

      const newData = { ...prev }

      if (Object.keys(prev).includes(formattedCreatedAt)) {
        newData[formattedCreatedAt] = {
          values: [...newData[formattedCreatedAt].values, curr],
          label: formattedCreatedAt
        }
      } else {
        newData[formattedCreatedAt] = {
          values: [curr],
          label: formattedCreatedAt
        }
      }

      return newData
    }, {})
}

export const formatDateTimeString = (time) => {
  return `${moment(time).format('DD/MM/YYYY')} ${new Date(time)
    .toTimeString()
    .slice(0, 5)}`
}
