import { Button } from 'antd'
import { FileSearchOutlined } from '@ant-design/icons'
import { Container } from './styles'

const Sidebar = () => {
  return (
    <Container>
      <Button type='primary' icon={<FileSearchOutlined />} />
    </Container>
  )
}

export default Sidebar
