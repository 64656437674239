import styled from 'styled-components'
import { Button } from 'antd'

export const RightLayout = styled.div`
  display: flex;
  align-items: center;
`

export const LeftLayout = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.STATS_GREY_COLOR};
`

export const AntButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.125rem 0.25rem;
  margin: 0 0.625rem 0 0.625rem;
  cursor: pointer;
  gap: 0.5rem;
  width: 1.25rem;
  height: 1.25em;
  background: #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16),
    0 0.125rem 0.25rem rgba(89, 96, 120, 0.1);
  border-radius: 0.625rem;
  font-size: 0.75em;

  .anticon {
    margin-top: 0.125rem;

    svg {
      width: 0.5rem;
      height: 0.5rem;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
