import styled from 'styled-components'

export const Container = styled.div`
  background: #f7fafe;
  border-radius: 0.5rem;
  padding: 0.75rem;
  margin-top: 0.75rem;
`

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.marginBottom || 0};
`

export const Information = styled(FlexBetween)`
  line-height: 2rem;

  label {
    color: #8c8f9f;
    font-size: 0.875rem;
  }
`

export const TextMedium = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  max-width: 9.375rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .anticon {
    color: #367f98;
  }
`

export const BreakLine = styled.div`
  height: 0.0625rem;
  background-color: #e5eaf4;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
`

export const StyledShowCollapseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 0.625rem;
  cursor: pointer;
  margin-top: 0.5rem;

  span {
    font-size: 12px;
    color: #367f98;
  }

  .anticon {
    color: #367f98;
    font-size: 9px;
  }
`
