import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Tooltip } from 'antd'
import useCustomHistory from '@/hooks/useCustomHistory'
import ModalValidation from '@/containers/ModalValidation'
import TablePagination from '@/components/Pagination'
import HeaderSelected from './HeaderSelected'
import Vector from '@/assets/images/Vector.svg'
import Smile from '@/assets/images/Smile.svg'
import { markEntry } from '@/apis/validationAPI'
import { useEnrollments } from '@/contexts/enrollmentsContext'
import SameUsers from './SameUsers'
import WarningsModal from './SameUsers/WarningsModal'

import {
  ValidationTable,
  ValidationTableWrapper,
  VectorIcon,
  EmptyText,
  EmptyDescription,
  AntUserIcon,
  NameEmailWraper,
  NameWrapper,
  EmailWrapper,
  IconNameWrapper,
  FinishedAtWrapper,
  ValidWrapper,
  InvalidWrapper,
  UnprocessedWrapper,
  ValidatorWrapper,
  ProductWrapper,
  AntTooltip,
  AntCheckOutlined,
  BulletTextWrapper,
  TitleWrapper,
  AntDivider,
  Layout,
  TextWrapper,
  IdWrapper,
  AntExclamationCircle,
  TextIconWrapper,
  AntCopyOutlined,
  IdIconWrapper,
  MainLayout,
  StatusMainLayout,
  DateWrapper,
  Escalation,
  Title,
  Wrapper,
  AntWarningOutlined,
  Text
} from './styles'

const TableEnrollments = ({
  validationList,
  pagination,
  unprocessedCount,
  userId,
  guideUrl,
  isFraudCheck,
  isPreRecruit
}) => {
  const { t } = useTranslation()
  const { addSearchKeyValue, deleteSearchKey, getValueBySearchKey } =
    useCustomHistory()
  const location = useLocation()
  const history = useHistory()
  const { id: surveyId } = useParams()
  const { currentEnrollment, setCurrentEnrollment, handleNextEnrollment } = useEnrollments()
  const [selectedRows, setSelectedRows] = useState([]) //selectedRows feature?
  const [tooltipText, setTooltipText] = useState('Click to copy')
  const [showHeader, setShowHeader] = useState(true)
  const [isAllSelected, setIsAllSelected] = useState(false)
  const searchParams = new URLSearchParams(location.search)
  const isOpen = searchParams.get('open')
  const [warnings, setIswarnings] = useState([]) //selectedRows feature?
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isValidateModalOpen, setIsValidateModalOpen] = useState(false)

  const [updated, setIsupdated] = useState(0)

  const unprocessedCountOnThisPage = validationList.filter(
    item => item.status === 'unprocessed'
  )

  const checkSortOrder = () => {
    const sortOrder = getValueBySearchKey('sort')
    if (sortOrder === 'ASC') {
      return 'ascend'
    } else if (sortOrder === 'DESC') {
      return 'descend'
    } else {
      return false
    }
  }

  const handleChecbox = () => {
    setShowHeader(true)
    setSelectedRows([])
  }

  useEffect(() => {
    /**
     * unselect the unprocessed entries
     * when filters/validationList changed
     */
    handleChecbox()
  }, [validationList])

  const copyToClipBoard = text => {
    navigator.clipboard.writeText(text)
    setTooltipText(t('components.table.columns.tooltipText'))
    setTimeout(
      () => setTooltipText(t('components.table.columns.tooltipTextCopy')),
      1000
    )
  }

  const handleNextOrClose = () => {
    const nextEnrollment = handleNextEnrollment()
    setCurrentEnrollment(nextEnrollment)

    if (!nextEnrollment) {
      setIsValidateModalOpen(false)
    }
  }

  const handleCloseEnrollmentValidation = () => {
    setCurrentEnrollment(null)
    setIsValidateModalOpen(false)
  }

  const formatData = selectedRows => {
    let data = []
    for (const itree of selectedRows) {
      const enrollment = validationList.find(ele => ele.tasterID === itree)
      if (enrollment) {
        data.push({
          enrollmentId: itree,
          sameUsers: enrollment?.sameUsers?.filter(
            item => item._id !== enrollment.userId
          ).length,
          email: enrollment.email,
          name: enrollment.userName,
          paypalEmail: enrollment.paypalEmail,
          userId: enrollment.userId
        })
      }
    }
    return data
  }

  const handleUpdate = async action => {
    if (!selectedRows?.length) return
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('page', 1)

    try {
      const data = await markEntry({
        enrollments: formatData(selectedRows),
        option: action,
        surveyId,
        allUnprocessed: isAllSelected,
        userId
      })
      setIswarnings(data.warnings)
      setIsupdated(data.updatedEnrollments)
      setIsModalOpen(data.warnings.length ? true : false)
      history.push({
        search: searchParams.toString()
      })
      // eslint-disable-next-line no-empty
    } catch {}
  }
  const handleValidate = record => {
    setCurrentEnrollment(record)

    setIsValidateModalOpen(!isValidateModalOpen)
    // update modal state
  }

  const columns = [
    {
      title: t('components.table.columns.user'),
      key: 'userName',
      width: '20%',
      ellipsis: {
        showTitle: false
      },
      render: record => (
        <div>
          <IconNameWrapper>
            <AntTooltip
              placement='bottomLeft'
              overlayInnerStyle={{
                backgroundColor: ' #ffffff',
                borderRadius: '0.75em',
                width: '455px'
              }}
              title={
                <MainLayout>
                  <TitleWrapper>
                    {t('component.validation.table.tooltip.title')}
                  </TitleWrapper>
                  <AntDivider />
                  <Layout>
                    <TextIconWrapper>
                      <TextWrapper>
                        {t('component.validation.table.tooltip.taster.id')}
                      </TextWrapper>
                      <Tooltip title='Taster respondent ID of this survey'>
                        <AntExclamationCircle />
                      </Tooltip>
                    </TextIconWrapper>
                    <AntTooltip
                      placement='topRight'
                      overlayInnerStyle={{
                        color: ' #ffffff',
                        backgroundColor: 'rgba(39, 45, 78, 0.9)',
                        borderRadius: '3px'
                      }}
                      title={
                        <div>
                          {tooltipText.includes('clipboard') && (
                            <AntCheckOutlined />
                          )}
                          {tooltipText}
                        </div>
                      }
                    >
                      <IdIconWrapper
                        onClick={() => copyToClipBoard(record.tasterID)}
                      >
                        <IdWrapper>{record.tasterID}</IdWrapper>
                        <AntCopyOutlined />
                      </IdIconWrapper>
                    </AntTooltip>
                  </Layout>
                  <Layout>
                    <TextIconWrapper>
                      <TextWrapper>
                        {t('component.validation.table.tooltip.taster.userId')}
                      </TextWrapper>

                      <Tooltip title='User ID who took the survey'>
                        <AntExclamationCircle />
                      </Tooltip>
                    </TextIconWrapper>
                    <AntTooltip
                      placement='topRight'
                      overlayInnerStyle={{
                        color: ' #ffffff',
                        backgroundColor: 'rgba(39, 45, 78, 0.9)',
                        borderRadius: '3px'
                      }}
                      title={
                        <div>
                          {tooltipText.includes('clipboard') && (
                            <AntCheckOutlined />
                          )}
                          {tooltipText}
                        </div>
                      }
                    >
                      <IdIconWrapper
                        onClick={() => copyToClipBoard(record.userId)}
                      >
                        <IdWrapper>{record.userId}</IdWrapper>
                        <AntCopyOutlined />
                      </IdIconWrapper>
                    </AntTooltip>
                  </Layout>
                  <Layout>
                    <TextWrapper>
                      {t(
                        'component.validation.table.tooltip.taster.paypalemail'
                      )}
                    </TextWrapper>
                    <AntTooltip
                      placement='topRight'
                      overlayInnerStyle={{
                        color: ' #ffffff',
                        backgroundColor: 'rgba(39, 45, 78, 0.9)',
                        borderRadius: '3px'
                      }}
                      title={
                        <div>
                          {tooltipText.includes('clipboard') && (
                            <AntCheckOutlined />
                          )}
                          {tooltipText}
                        </div>
                      }
                    >
                      <IdIconWrapper
                        onClick={() => copyToClipBoard(record.paypalEmail)}
                      >
                        <IdWrapper>{record.paypalEmail}</IdWrapper>
                        <AntCopyOutlined />
                      </IdIconWrapper>
                    </AntTooltip>
                  </Layout>
                </MainLayout>
              }
            >
              <AntUserIcon />
            </AntTooltip>
            <NameEmailWraper>
              <NameWrapper onClick={() => copyToClipBoard(record.userName)}>
                <AntTooltip
                  overlayInnerStyle={{
                    color: ' #ffffff',
                    backgroundColor: 'rgba(39, 45, 78, 0.9)',
                    borderRadius: '3px'
                  }}
                  title={
                    <div>
                      {tooltipText.includes('clipboard') && (
                        <AntCheckOutlined />
                      )}
                      {tooltipText}
                    </div>
                  }
                >
                  {record.userName}
                </AntTooltip>
              </NameWrapper>
              <EmailWrapper onClick={() => copyToClipBoard(record.email)}>
                <AntTooltip
                  overlayInnerStyle={{
                    color: ' #ffffff',
                    backgroundColor: 'rgba(39, 45, 78, 0.9)',
                    borderRadius: '3px'
                  }}
                  title={
                    <div>
                      {tooltipText.includes('clipboard') && (
                        <AntCheckOutlined />
                      )}
                      {tooltipText}
                    </div>
                  }
                >
                  {record.email}
                </AntTooltip>
              </EmailWrapper>
            </NameEmailWraper>
          </IconNameWrapper>
        </div>
      )
    },
    {
      title: t('components.table.columns.finishedAt'),
      key: 'finishedAt',
      width: '12%',
      dataIndex: 'finishedAt',
      defaultSortOrder: 'descend',
      sortOrder: checkSortOrder(),
      sorter: true,
      render: finishedAt => <FinishedAtWrapper>{finishedAt}</FinishedAtWrapper>
    },
    {
      title: t('components.table.columns.status'),
      key: 'status',
      width: '15%',
      render: record => (
        <div>
          {record.escalation ? (
            <StatusMainLayout>
              <Escalation>Escalated</Escalation>
              <DateWrapper>{record.date}</DateWrapper>
            </StatusMainLayout>
          ) : record.enrollmentstate === 'archived' ? (
            <StatusMainLayout>
              <InvalidWrapper>Archived</InvalidWrapper>
              <DateWrapper>{record.date}</DateWrapper>
            </StatusMainLayout>
          ) : record.status === 'valid' ? (
            <StatusMainLayout>
              <ValidWrapper>{t('components.survey.tabs.valid')}</ValidWrapper>
              <DateWrapper>{record.date}</DateWrapper>
            </StatusMainLayout>
          ) : record.status === 'invalid' ? (
            <StatusMainLayout>
              <InvalidWrapper>
                {t('components.survey.tabs.invalid')}
              </InvalidWrapper>
              <DateWrapper>{record.date}</DateWrapper>
            </StatusMainLayout>
          ) : record.status === 'unprocessed' && record.date.includes('Due') ? (
            <StatusMainLayout>
              <UnprocessedWrapper>
                {t('components.survey.tabs.unprocessed')}
              </UnprocessedWrapper>
              <DateWrapper>{record.date}</DateWrapper>
            </StatusMainLayout>
          ) : (
            <StatusMainLayout>
              <InvalidWrapper>
                {t('components.survey.tabs.overdue')}
              </InvalidWrapper>
              <DateWrapper>{record.date}</DateWrapper>
            </StatusMainLayout>
          )}
        </div>
      )
    },
    {
      title: t('components.table.columns.validator'),
      key: 'validator',
      width: '10%',
      dataIndex: 'validator',
      render: validator =>
        validator === null || validator === '' ? (
          '-'
        ) : (
          <Tooltip title={validator}>
            <ValidatorWrapper>{validator}</ValidatorWrapper>
          </Tooltip>
        )
    },
    {
      title: t('components.table.columns.product'),
      key: 'products',
      width: '10%',
      ellipsis: {
        showTitle: false
      },
      dataIndex: 'products',
      render: products => (
        <ProductWrapper>
          {products.length ? (
            <AntTooltip
              trigger='hover'
              placement='bottom'
              overlayInnerStyle={{
                color: '#E6EFFB',
                backgroundColor: '#161D34',
                borderRadius: '0.625rem'
              }}
              title={
                <div>
                  <BulletTextWrapper>
                    {products.map(item => (
                      <li>{item}</li>
                    ))}
                  </BulletTextWrapper>
                </div>
              }
            >
              {products.length ? <Text>{products[0]}</Text> : '-'}
            </AntTooltip>
          ) : (
            '-'
          )}
        </ProductWrapper>
      )
    },

    {
      title: t('components.table.columns.sameIPUsers'),
      key: 'sameip',
      width: '20%',

      render: record => (
        <SameUsers
          selectedEnrollment={record}
          setSelectedEnrollment={setCurrentEnrollment}
        />
      )
    },
    {
      title: '',
      key: 'validate',
      width: '10%',
      render: record => (
        <Button
          type='primary'
          shape='round'
          onClick={() => handleValidate(record)}
        >
          View Detail
        </Button>
      )
    }
  ]

  const caughtUpSmiley = {
    emptyText: (
      <span>
        <VectorIcon src={Smile} />
        <EmptyText>{t('component.survey.table.caughtup.text')}</EmptyText>
        <EmptyDescription>
          {t('component.survey.table.caughtup.description')}
        </EmptyDescription>
      </span>
    )
  }

  const notFoundSmiley = {
    emptyText: (
      <span>
        <VectorIcon src={Vector} />
        <EmptyText>{t('component.survey.table.notfound.text')}</EmptyText>
        <EmptyDescription>
          {t('component.survey.table.notfound.description')}
        </EmptyDescription>
      </span>
    )
  }

  const onChange = (antdPagination, antdFilters, antdSorter) => {
    if (antdSorter.order === 'ascend') {
      addSearchKeyValue('sort', 'ASC')
    } else if (antdSorter.order === 'descend') {
      addSearchKeyValue('sort', 'DESC')
    } else {
      deleteSearchKey('sort')
    }
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const selectionsOptions =
    unprocessedCountOnThisPage.length > 0
      ? [
          {
            key: 'unprocessedEntries',
            text: `Select ${unprocessedCountOnThisPage.length} unprocessed entries on this page`,
            onSelect: allKeys => {
              setSelectedRows(allKeys)
              setShowHeader(false)
            }
          },
          {
            key: 'allUnprocessedEntries',
            text: `Select all ${unprocessedCount} unprocessed entries`
          }
        ]
      : false

  useEffect(() => {
    if (isOpen && validationList?.length === 1) {
      setCurrentEnrollment(validationList[0])
      deleteSearchKey('open')
    }
  }, [isOpen, validationList])

  return (
    <React.Fragment>
      <ValidationTableWrapper>
        {!showHeader && (
          <HeaderSelected
            unprocessedCount={unprocessedCount}
            unprocessedCountOnThisPage={unprocessedCountOnThisPage}
            handleChecbox={handleChecbox}
            handleUpdate={handleUpdate}
            isAllSelected={isAllSelected}
            setIsAllSelected={setIsAllSelected}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        )}

        <ValidationTable
          rowClassName={(record, index) =>
            record.status === 'unprocessed' && !record.date.includes('Due')
              ? 'table-row-red'
              : index % 2 === 0
              ? 'table-row-light'
              : 'table-row-dark'
          }
          rowSelection={{
            selectedRowKeys: selectedRows,
            onChange: keys => {
              if (keys.length > 0) {
                setShowHeader(false)
              }
              setSelectedRows(keys)
            },
            getCheckboxProps: record => ({
              disabled:
                record.status === 'valid' ||
                record.status === 'invalid' ||
                record.escalation === true,
              defaultValue: selectedRows
            }),
            selections: selectionsOptions
          }}
          selections
          showHeader={showHeader}
          rowKey={record => record.tasterID}
          columns={columns}
          dataSource={validationList}
          locale={
            validationList &&
            !validationList.filter(item => item.status === 'unprocessed').length
              ? caughtUpSmiley
              : notFoundSmiley
          }
          scroll={{ y: 400 }}
          pagination={false}
          onChange={onChange}
          footer={() => (
            <TablePagination showSizeChanger={true} pagination={pagination} />
          )}
        />
      </ValidationTableWrapper>

      <ModalValidation
        selectedEnrollment={currentEnrollment}
        setSelectedEnrollment={setCurrentEnrollment}
        onNextOrClose={handleNextOrClose}
        onClose={handleCloseEnrollmentValidation}
        guideUrl={guideUrl}
        isFraudCheck={isFraudCheck}
        isPreRecruit={isPreRecruit}
        isValidateModalOpen={isValidateModalOpen}
        setIsValidateModalOpen={setIsValidateModalOpen}
      />

      <WarningsModal
        title={
          <Wrapper>
            <AntWarningOutlined />
            <Title> {t('components.modal.warnings.title')}</Title>
          </Wrapper>
        }
        open={isModalOpen}
        data={warnings}
        updated={updated}
        onCancel={() => setIsModalOpen(false)}
      ></WarningsModal>
    </React.Fragment>
  )
}

export default TableEnrollments
