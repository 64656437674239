import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from './styles'

const policyUrl = `${process.env.REACT_APP_LANDING_PAGE_URL}/privacy-policy`
const TextTerms = ({ className }) => {
  const { t } = useTranslation()

  return (
    <Text className={className}>
      <div>{t('components.welcome.agreement.text')}</div>
      <div
        dangerouslySetInnerHTML={{
          __html: t('components.welcome.agreement.links', { policyUrl })
        }}
      />
    </Text>
  )
}

export default TextTerms
