import React from 'react'
import { useTranslation } from 'react-i18next'
import { AntRadio, Wrapper, Text } from './styles'

const FilterRedFlagUsers = ({ redflagged, setFilterRedFlag }) => {
  const { t } = useTranslation()

  const onChange = (e) => {
    const value = e.target.value

    setFilterRedFlag(value)
  }

  return (
    <Wrapper>
      <Text>{t('components.survey.entries.filters.redflagged.users')}</Text>
      <AntRadio.Group value={redflagged} onChange={onChange}>
        <AntRadio value={false}>
          {t('components.survey.entries.filters.redflagged.exclude')}
        </AntRadio>
        <AntRadio value={true}>
          {t('components.survey.entries.filters.redflagged.only')}
        </AntRadio>
      </AntRadio.Group>
    </Wrapper>
  )
}

export default FilterRedFlagUsers
