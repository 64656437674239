import { GET, POST, PATCH, DELETE } from '@/utils/axios'

export const getValidatedByList = async () =>
  await GET('/users/validators', { useAuthorization: true })

export const getValidationsList = async (
  searchedData,
  query,
  page,
  limit,
  id
) =>
  await POST(
    `/survey/enrollments/${id}?search=${searchedData}&page=${page}&size=${limit}`,
    query,
    {
      useAuthorization: true
    }
  )

export const getValidationsListCount = async (
  searchedData,
  query,
  page,
  limit,
  id
) =>
  await POST(
    `/survey/enrollments/count/${id}?search=${searchedData}&page=${page}&size=${limit}`,
    query,
    {
      useAuthorization: true
    }
  )

export const getEnrollmentAnswers = async id => {
  const response = await POST(`/survey-enrollment/answers/${id}`, null, {
    useAuthorization: true
  })
  return response.data?.data
}

export const updateEnrollment = async (id, payload) => {
  const response = await PATCH(`/survey-enrollment/${id}`, payload, {
    useAuthorization: true
  })
}

export const getSurveyProductsReceipts = async id => {
  const data = await POST(`/survey/products/${id}`, null, {
    useAuthorization: true
  })
  return data.data
}

export const getProductsReceipts = async (surveyId, prodId, tab) => {
  const data = await POST(
    `/survey/products-receipts?survey=${surveyId}&product=${prodId}&receipts=${tab}`,
    null,
    {
      useAuthorization: true
    }
  )
  return data.data
}

export const createDuplicate = async payload => {
  const data = await POST('/validation-duplicates', payload, {
    useAuthorization: true
  })
  return data.data
}

export const deleteDuplicate = async duplicateId => {
  const data = await DELETE(`/validation-duplicates/${duplicateId}`, {
    useAuthorization: true
  })
  return data.data
}

export const checkProductUsers = async payload => {
  const data = await POST(
    '/survey-enrollment/update-enrolment-approval',
    payload,
    {
      useAuthorization: true
    }
  )
  return data.data
}

export const markEntry = async payload => {
  const data = await POST('/survey-enrollment/update-enrollments', payload, {
    useAuthorization: true
  })
  return data.data
}

export const markEntryForReceipts = async payload => {
  const data = await POST('/survey-enrollment/update-receipts', payload, {
    useAuthorization: true
  })
  return data.data
}

export const getValidationLogs = async id => {
  const response = await GET(`/validation-logs/${id}`, {
    useAuthorization: true
  })
  return response.data
}
