import { GET, POST, PATCH } from '@/utils/axios'

export const getSurvey = async (id) => {
  return await GET(`/survey/${id}`, { useAuthorization: true })
}

export const getProductsList = async () =>
  await GET('/products', { useAuthorization: true })

export const getSurveysList = async (searchedData, query, page, limit) =>
  await POST(
    `/survey/validation-surveys?search=${searchedData}&page=${page}&size=${limit}`,
    query,
    {
      useAuthorization: true
    }
  )

export const getSurveysListCount = async (searchedData, query, page, limit) =>
  await POST(
    `/survey/count-surveys?search=${searchedData}&page=${page}&size=${limit}`,
    query,
    {
      useAuthorization: true
    }
  )

export const getSameIPUsers = async (id, remoteAddress) => {
  return await GET(
    `/survey/${id}/same-ip-users?remoteAddress=${remoteAddress}`,
    { useAuthorization: true }
  )
}

export const updateSurvey = async (id, data) => {
  await PATCH(`/survey/${id}`, data, { useAuthorization: true })
}
