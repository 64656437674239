import styled from 'styled-components'
import { Button, Radio, Tabs as AntdTabs } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

export const DetailHeader = styled.div`
  padding: 12px 16px;
  border: 1px solid #e5eaf4;
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledCloseIcon = styled(CloseOutlined)`
  color: #272d4e;
  cursor: pointer;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StyledButton = styled(Button)`
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 40px;
  // display: flex;
  // justify-content: flex-start;
`

export const BodyWrapper = styled.div`
  border: 1px solid #e5eaf4;
  border-top: none;
  height: calc(100vh - 285px);
  overflow-y: auto;
`

export const NavigationLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  column-gap: 10px;
`

export const RadioButtonsWrapper = styled.div`
  display: flex;
`

export const NavigationButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: end;
`

export const StyledNavigationButton = styled(Button)`
  border-radius: 40px;
  height: 28px;
  width: 28px;
`

export const StyledRadio = styled(Radio)`
  .ant-radio-inner {
    display: none;
  }
  margin: 0;
`

export const StyledIconWrapper = styled.div`
  font-size: 1.7rem;
  cursor: pointer;

  .checkIcon {
    color: ${props => (props.value === 'approved' ? '#4ab9bb' : '#ccd2e3')};

    svg:hover {
      color: #4ab9bb;
    }
  }

  .closeIcon {
    color: ${props => (props.value === 'unapproved' ? '#da2f58' : '#ccd2e3')};

    svg:hover {
      color: #da2f58;
    }
  }
`

export const ProductRewardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`

export const ProductWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 14px;
  color: #272d4e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

export const RewardWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 10px;
  color: #8c8f9f;
`

export const ImageHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
  position: relative;
`

export const ImageContainer = styled.div`
  width: 10.5rem;
  height: 15.5rem;
  background-image: url('${props => props.imageUrl}');
  background-size: 10.5rem 15.5rem;
  background-repeat: no-repeat;

  .externalButton {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;

    svg {
      border-bottom-left-radius: 0.75em;
    }
  }
`

export const ButtonValid = styled(Button)`
  color: #dbdee5;
  background-color: #ffffff;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem;

  &:hover,
  &:focus,
  &.active {
    color: #4ab9bb;
    background-color: #f0f9fd;
    border-color: #4ab9bb;
  }
`

export const ButtonInvalid = styled(Button)`
  color: #dbdee5;
  background-color: #ffffff;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem;

  &:hover,
  &:focus,
  &.active {
    color: #da2f58;
    background-color: #ffe7ec;
    border-color: #da2f58;
  }
`

export const EntryTextWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
`

export const FooterWrapper = styled.div`
  position: sticky;
  bottom: 0;
  right: 0;
  padding: 12px 16px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`

export const SubmitButton = styled(Button)`
  background: #367f98;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 60px;
  color: #ffffff;
`

export const Tabs = styled(AntdTabs)`
  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 8rem;
  }

  .ant-tabs-tab-btn {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #8c8f9f;
  }
`

export const ReceiptDetailsWrapper = styled.div`
  padding: 0.5rem 1rem;
`

export const ExitReceipt = styled.span`
  cursor: pointer;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #8c8f9f;
`
