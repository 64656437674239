import { GET } from '@/utils/axios'

export const getSignedUrl = async (type) => {
  const response = await GET(`/signed-url`, {
    useAuthorization: true,
    params: { type }
  })

  return response.data
}
