import * as React from "react"

const ExternalIcon = (props) => (
  <svg
    width={30}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 0h30v30H8a8 8 0 0 1-8-8V0Z" fill="#272D4E" fillOpacity={0.9} />
    <path
      d="M20.444 20.444H9.556V9.556H15V8H9.556C8.692 8 8 8.7 8 9.556v10.888C8 21.3 8.692 22 9.556 22h10.888C21.3 22 22 21.3 22 20.444V15h-1.556v5.444ZM16.556 8v1.556h2.792L11.702 17.2l1.097 1.097 7.645-7.646v2.792H22V8h-5.444Z"
      fill="#fff"
    />
  </svg>
)

export default ExternalIcon
