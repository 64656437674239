import React, { useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ImageSlider from '../ImageSlider'
import ReceiptInformation from '../ReceiptInformation'
import {
  CheckCircleFilled,
  CloseCircleFilled,
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons'
import ExternalIcon from '@/assets/icons/ExternalIcon'
import {
  getNextProductUser,
  getPreviousProductUser
} from '@/utils/helperFunctions'
import Logs from '@/containers/Logs'
import { getCurrentIndex, isImage, isVideo } from './utils'
import {
  DetailHeader,
  StyledCloseIcon,
  StyledButton,
  ButtonsWrapper,
  BodyWrapper,
  StyledRadio,
  StyledIconWrapper,
  ProductRewardWrapper,
  ProductWrapper,
  RewardWrapper,
  StyledNavigationButton,
  NavigationButtonsWrapper,
  NavigationLayout,
  RadioButtonsWrapper,
  ImageHolder,
  ImageContainer,
  ButtonValid,
  ButtonInvalid,
  EntryTextWrapper,
  FooterWrapper,
  SubmitButton,
  Tabs,
  ReceiptDetailsWrapper,
  ExitReceipt
} from './styles'

const ReceiptDetail = ({
  receiptDetail,
  setReceiptDetail,
  products,
  selectedProduct,
  setSelectedProduct,
  productUsers,
  handleApproveDisapprove,
  setApproveValue,
  approveValue,
  showValidInvalidEntry,
  handleSubmit,
  receipts,
  nextDisable,
  setNextDisable,
  previousDisable,
  setPreviousDisable,
  validValue,
  setValidValue,
  setShowValidInvalidEntry
}) => {
  const { t } = useTranslation()

  const [currentProduct, setCurrentProduct] = useState({})
  const [currentImage, setCurrentImage] = useState()

  const imagePath = useMemo(() => {
    if (isImage(currentImage)) return `/attachment/${currentImage}?type=image`
    if (isVideo(currentImage)) return `/attachment/${currentImage}?type=video`
    return ''
  }, [currentImage])

  // Move to next product user on click of next navigation button.
  const nextProductUser = () => {
    const nextProduct = getNextProductUser(productUsers, selectedProduct)
    if (nextProduct === undefined) {
      setNextDisable(true)
    } else {
      setSelectedProduct(nextProduct.id)
      setPreviousDisable(false)
    }
  }

  // Move to previous product user on click of previous navigation button.
  const previousProductUser = () => {
    const previousProduct = getPreviousProductUser(
      productUsers,
      selectedProduct
    )
    if (previousProduct === undefined) {
      setPreviousDisable(true)
    } else {
      setSelectedProduct(previousProduct.id)
      setNextDisable(false)
      setShowValidInvalidEntry(false)
    }
  }

  // Move to next user on click of next user button
  const handleNextUser = () => {
    const currentIndex = getCurrentIndex(receipts, receiptDetail?.enrollmentid)
    setReceiptDetail(receipts[currentIndex + 1])
  }

  // Move to previous user on click of previous user button
  const handlePreviousUser = () => {
    const currentIndex = getCurrentIndex(receipts, receiptDetail?.enrollmentid)
    setReceiptDetail(receipts[currentIndex - 1])
  }

  // Handling product/images and approve/disapprove on change of product/receipt detail.
  useEffect(() => {
    const { value, savedRewards, validation } = receiptDetail
    const currentProduct = products?.find(prod => prod._id === selectedProduct)
    const currentSavedRewards = savedRewards?.find(
      ele => ele.id === selectedProduct
    )
    const imagesData = [...value, ...currentProduct.images]
    const item = { ...currentProduct, images: imagesData }

    if (validation === 'valid') {
      setApproveValue('approved')
    } else if (validation === 'invalid') {
      setApproveValue('unapproved')
    } else {
      setApproveValue(currentSavedRewards?.approved)
    }
    setCurrentProduct(item)
    setCurrentImage(value[0])
  }, [selectedProduct, receiptDetail])

  return (
    <>
      <DetailHeader>
        {showValidInvalidEntry ? (
          <ButtonsWrapper>
            <EntryTextWrapper>
              {t('components.receiptDetail.entry')}
            </EntryTextWrapper>
            <ButtonValid
              className={validValue === 'valid' && 'active'}
              icon={<CheckCircleFilled />}
              onClick={() => setValidValue('valid')}
            >
              {t('components.survey.tabs.valid')}
            </ButtonValid>

            <ButtonInvalid
              className={validValue === 'invalid' && 'active'}
              icon={<CloseCircleFilled />}
              onClick={() => setValidValue('invalid')}
            >
              {t('components.survey.tabs.invalid')}
            </ButtonInvalid>
          </ButtonsWrapper>
        ) : (
          <ButtonsWrapper>
            <StyledButton
              onClick={handlePreviousUser}
              disabled={
                getCurrentIndex(receipts, receiptDetail?.enrollmentid) === 0
              }
            >
              {t('components.survey.tabs.previousUser')}
            </StyledButton>
            <StyledButton
              onClick={handleNextUser}
              disabled={
                getCurrentIndex(receipts, receiptDetail?.enrollmentid) ===
                receipts.length - 1
              }
            >
              {t('components.survey.tabs.nextUser')}
            </StyledButton>
          </ButtonsWrapper>
        )}

        <ExitReceipt onClick={() => setReceiptDetail({})}>
          Exit receipt
        </ExitReceipt>
      </DetailHeader>
      <BodyWrapper>
        <Tabs
          centered
          items={[
            {
              label: 'Receipt details',
              key: 'Receipt details',
              children: (
                <ReceiptDetailsWrapper>
                  <NavigationLayout>
                    <StyledRadio.Group>
                      <RadioButtonsWrapper>
                        <StyledRadio
                          onClick={handleApproveDisapprove}
                          value='approved'
                        >
                          <StyledIconWrapper value={approveValue}>
                            <CheckCircleFilled className='checkIcon' />
                          </StyledIconWrapper>
                        </StyledRadio>
                        <StyledRadio
                          onClick={handleApproveDisapprove}
                          value='unapproved'
                        >
                          <StyledIconWrapper value={approveValue}>
                            <CloseCircleFilled className='closeIcon' />
                          </StyledIconWrapper>
                        </StyledRadio>
                      </RadioButtonsWrapper>
                    </StyledRadio.Group>
                    <ProductRewardWrapper>
                      <ProductWrapper>{currentProduct?.name}</ProductWrapper>
                      <RewardWrapper>
                        {t('components.survey.tabs.rewards')}:{' '}
                        {currentProduct?.reward}
                      </RewardWrapper>
                    </ProductRewardWrapper>
                    <NavigationButtonsWrapper>
                      <StyledNavigationButton
                        icon={<LeftOutlined style={{ fontSize: '15px' }} />}
                        onClick={previousProductUser}
                        disabled={previousDisable}
                      />
                      <StyledNavigationButton
                        icon={<RightOutlined style={{ fontSize: '15px' }} />}
                        onClick={nextProductUser}
                        disabled={nextDisable}
                      />
                    </NavigationButtonsWrapper>
                  </NavigationLayout>
                  <ImageHolder>
                    <ImageContainer imageUrl={currentImage}>
                      <Link
                        to={imagePath}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='externalButton'
                      >
                        <ExternalIcon />
                      </Link>
                    </ImageContainer>
                  </ImageHolder>
                  {Object.keys(currentProduct).length > 0 && (
                    <ImageSlider
                      currentProduct={currentProduct}
                      currentImage={currentImage}
                      setCurrentImage={setCurrentImage}
                    />
                  )}
                  <ReceiptInformation receiptDetail={receiptDetail} />
                </ReceiptDetailsWrapper>
              )
            },
            {
              label: 'Activity log',
              key: 'Activity log',
              children: <Logs enrollmentId={receiptDetail?.enrollmentid} />
            }
          ]}
        />
      </BodyWrapper>
      <FooterWrapper>
        {validValue && (
          <SubmitButton onClick={() => handleSubmit(validValue)}>
            {t('components.submit')}
          </SubmitButton>
        )}
      </FooterWrapper>
    </>
  )
}

export default ReceiptDetail
