import React, { useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Spin, Row, Select, Radio, Form, Checkbox, Tooltip } from 'antd'
import {
  InfoCircleOutlined,
  CheckOutlined,
  WarningFilled,
  EyeFilled,
  EyeInvisibleFilled
} from '@ant-design/icons'
import { SHOW_USER_OPTIONS } from '@/utils/constants/constants'
import Logs from '@/containers/Logs'
import UserInformation from '../UserInformation'
import EnrollmentInformation from '../EnrollmentInformation'
import FinishInformation from '../FinishInformation'
import SameUsers from '../../Tables/Enrollments/SameUsers'
import UserCountRecords from '../UserCountRecords'
import AnswerValidation from '../AnswerValidation'
import SupportGuide from '../SupportGuide'
import NoRewardAnswers from '../NoRewardAnswers'
import { mapAnswerWithReward } from '../AnswerValidation/utils'
import { cleanObject } from '@/utils/helperFunctions'
import {
  StyledAnswersContainer,
  StyledCol,
  StyledMidCol,
  StyledButton,
  BreakLine,
  StyledAnswersFooterWrapper,
  SpinnerWrapper,
  StyledLabelWrapper,
  TextArea,
  StyledHeaderContainer,
  StyledCountWrapper,
  StyledSupportWrapper,
  RadioGroup,
  FlexBetween,
  BasicEdit,
  Wrapper,
  Tabs,
  EntryDetails
} from './styles'

const { Option } = Select

const renderShowUserOptions = SHOW_USER_OPTIONS.map(option => (
  <Option value={option.value} key={option.value}>
    {option.label}
  </Option>
))

const ApprovalMain = ({
  onClose,
  selectedEnrollment,
  setSelectedEnrollment,
  guideUrl,
  onUpdate,
  isFraudCheck,
  isPreRecruit,
  isArchived
}) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { enrollmentAnswersLoading, enrollmentAnswers } = useSelector(
    state => state.modalValidationReducer
  )
  const { userEmail, authenticatedUser } = useSelector(
    state => state.authentication
  )
  const [showSupport, setShowSupport] = useState(false)
  const watchedRewards = Form.useWatch('rewards', form)
  const watchedValidation = Form.useWatch(['validation'], form)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const {
    userId,
    browserInfo,
    savedRewards = [],
    validation,
    notes,
    comment,
    cleaned,
    escalation,
    hiddenFromCharts,
    blackListed,
    validatedAt,
    validatedBy,
    tasterID,
    finishedAt,
    enrollmentState: state
  } = selectedEnrollment || {}

  useEffect(() => {
    if (form) {
      form.resetFields();
    }
  }, [tasterID])

  const noRewardAnswers = useMemo(() => {
    return enrollmentAnswers.filter(answer => {
      return answer.product_id === null
    })
  }, [enrollmentAnswers])

  const rewards = useMemo(() => {
    return savedRewards.map(reward =>
      mapAnswerWithReward(reward, enrollmentAnswers)
    )
  }, [enrollmentAnswers, savedRewards])

  const savedRewardValues = useMemo(() => {
    if (!savedRewards?.length) return {}
    return savedRewards?.reduce((prev, curr) => {
      const { id, payable, showOnCharts, approve } = curr
      const returnedValue = { ...prev }
      returnedValue[id] = {
        payable,
        showOnCharts,
        approve
      }
      return returnedValue
    }, {})
  }, [savedRewards])

  const watchedValidatedCount = useMemo(() => {
    if (!watchedRewards) return null
    const validatedRewards = Object.values(watchedRewards)?.filter(
      reward => reward?.approve
    )
    return validatedRewards?.length
  }, [watchedRewards])

  const handleChangeBlacklisted = e => {
    if (e.target.checked) {
      form.setFieldValue('validation', 'invalid')
    }
  }

  const handleValidateAnswer = async () => {
    const values = await form.validateFields()
    const {
      rewards,
      escalation,
      cleaned,
      notes,
      comment,
      validation,
      hiddenFromCharts,
      blackListed
    } = values || {}

    let rewardsChanged = 0

    const updatedSavedRewards = savedRewards?.map(reward => {
      const { id } = reward
      const updatedValues = cleanObject(rewards?.[id])

      if (
        !(
          reward.approve === updatedValues.approve &&
          reward.payable === updatedValues.payable &&
          reward.showOnCharts === updatedValues.showOnCharts
        )
      ) {
        rewardsChanged++
      }

      return {
        ...reward,
        ...updatedValues,
        approvedBy: userEmail,
        approvedAt: new Date().getTime()
      }
    })

    const currentValidation = selectedEnrollment.validation

    const updatedEnrollmentsPayload = {
      escalation,
      cleaned,
      notes,
      comment,
      validation,
      hiddenFromCharts,
      validatedAt:
        currentValidation !== validation ? new Date().getTime() : validatedAt,
      validatedBy:
        currentValidation !== validation ? authenticatedUser?.id : validatedBy,
      finishedAt,
      state,
      ...(validation && {
        processed: true
      })
    }

    const updatedUserDetailPayload =
      typeof blackListed !== 'undefined' &&
      blackListed !== selectedEnrollment?.blackListed
        ? { blackListed }
        : {}

    onUpdate(
      cleanObject(updatedEnrollmentsPayload),
      {
        ...(rewardsChanged > 0 && {
          savedRewards: updatedSavedRewards
        })
      },
      updatedUserDetailPayload
    )
  }

  const formulateCount = (validCount, totalCount) => {
    if (validCount === totalCount)
      return (
        <StyledCountWrapper>
          <CheckOutlined />
          <span className='textContent'>All products checked</span>
        </StyledCountWrapper>
      )
    else
      return (
        <StyledCountWrapper type='warning'>
          <WarningFilled />
          <span className='textContent'>
            {validCount}/{totalCount} products left to be checked
          </span>
        </StyledCountWrapper>
      )
  }

  const universalUpdateSavedRewards = isApprove => {
    rewards?.forEach((reward, index) => {
      const { id } = reward
      form.setFieldValue(
        ['rewards', `${id}-${index}`, 'approve'],
        isApprove ? 'approved' : 'unapproved'
      )
    })
  }

  useEffect(() => {
    if (watchedValidation === 'valid') {
      universalUpdateSavedRewards(true)
    }

    if (watchedValidation === 'invalid') {
      universalUpdateSavedRewards(false)
    }
  }, [watchedValidation])

  const onChangeUniversal = (newValue) => {
    form.setFieldValue("validation", newValue)

    if (newValue === "valid") {
      form.setFieldValue("blackListed", false)
    }

    setSubmitDisabled(false)
  }

  const onValuesChange = () => {
    setSubmitDisabled(false)
  }

  return (
    <Wrapper>
      <Form
        form={form}
        layout='vertical'
        initialValues={{
          validation,
          notes,
          comment,
          escalation,
          cleaned,
          hiddenFromCharts,
          blackListed,
          rewards: savedRewardValues
        }}
        onValuesChange={onValuesChange}
      >
        <Row>
          <StyledMidCol span={15}>
            {enrollmentAnswersLoading ? (
              <SpinnerWrapper>
                <Spin />
              </SpinnerWrapper>
            ) : (
              <>
                <StyledHeaderContainer>
                  {savedRewards &&
                    formulateCount(
                      watchedValidatedCount,
                      watchedRewards ? Object.values(watchedRewards).length : 0
                    )}

                  {showSupport ? (
                    <StyledSupportWrapper onClick={() => setShowSupport(false)}>
                      <span>{t('components.approvalMain.supportGuide')}</span>
                      <EyeFilled />
                    </StyledSupportWrapper>
                  ) : (
                    <StyledSupportWrapper onClick={() => setShowSupport(true)}>
                      <span>{t('components.approvalMain.viewGuide')}</span>
                      <EyeInvisibleFilled />
                    </StyledSupportWrapper>
                  )}
                </StyledHeaderContainer>

                <StyledAnswersContainer>
                  {noRewardAnswers.length > 0 && (
                    <NoRewardAnswers
                      form={form}
                      answers={noRewardAnswers}
                      enrollment={selectedEnrollment}
                      isFraudCheck={isFraudCheck}
                      isPreRecruit={isPreRecruit}
                      isArchived={isArchived}
                      onChangeUniversal={onChangeUniversal}
                    />
                  )}

                  {rewards.map(reward => (
                    <AnswerValidation
                      form={form}
                      key={reward?.id}
                      savedReward={reward}
                      isFraudCheck={isFraudCheck}
                      isPreRecruit={isPreRecruit}
                      isArchived={isArchived}
                      onChangeUniversal={onChangeUniversal}
                    />
                  ))}
                </StyledAnswersContainer>

                <StyledAnswersFooterWrapper>
                  <StyledButton
                    type='primary'
                    disabled={submitDisabled || isArchived}
                    onClick={handleValidateAnswer}
                  >
                    {t('components.submit')}
                  </StyledButton>
                  <StyledButton onClick={onClose}>Skip entry</StyledButton>
                </StyledAnswersFooterWrapper>
              </>
            )}
          </StyledMidCol>

          <StyledCol span={9}>
            {showSupport ? ( // check for external validator
              <SupportGuide url={guideUrl} />
            ) : (
              <Tabs
                centered
                items={[
                  {
                    label: 'Entry details',
                    key: 'Entry details',
                    children: (
                      <EntryDetails>
                        <EnrollmentInformation data={selectedEnrollment} />

                        <BreakLine />

                        <FinishInformation data={selectedEnrollment} />

                        <BreakLine />

                        <SameUsers
                          selectedEnrollment={selectedEnrollment}
                          setSelectedEnrollment={setSelectedEnrollment}
                        />

                        <BasicEdit>
                          <FlexBetween marginBottom='0.75rem'>
                            <Form.Item name='hiddenFromCharts' noStyle>
                              <Select
                                className='select-hidden-from-chart'
                                disabled={isArchived}
                              >
                                {renderShowUserOptions}
                              </Select>
                            </Form.Item>

                            <UserInformation data={selectedEnrollment} />
                          </FlexBetween>

                          <FlexBetween>
                            <Form.Item
                              name='escalation'
                              valuePropName='checked'
                            >
                              <Checkbox disabled={isArchived}>
                                {t('components.approvalMain.addEscalation')}
                              </Checkbox>
                            </Form.Item>

                            {userId && (
                              <Form.Item
                                name='blackListed'
                                valuePropName='checked'
                              >
                                <Checkbox
                                  onChange={handleChangeBlacklisted}
                                  disabled={isArchived}
                                >
                                  {t('components.approvalMain.addBlackList')}
                                </Checkbox>
                              </Form.Item>
                            )}
                          </FlexBetween>

                          <Form.Item name='cleaned'>
                            <RadioGroup disabled={isArchived}>
                              <Radio value={false}>
                                {t('components.approvalMain.toBeCleaned')}
                              </Radio>

                              <Radio value={true}>
                                {t('components.approvalMain.cleaned')}
                              </Radio>
                            </RadioGroup>
                          </Form.Item>
                        </BasicEdit>

                        <UserCountRecords userId={userId} />

                        <Form.Item
                          name='notes'
                          label={
                            <StyledLabelWrapper>
                              <span>{t('components.approvalMain.notes')}</span>

                              <Tooltip
                                placement='right'
                                title={t(
                                  'components.approvalMain.notesTooltip'
                                )}
                              >
                                <InfoCircleOutlined />
                              </Tooltip>
                            </StyledLabelWrapper>
                          }
                        >
                          <TextArea
                            showCount
                            maxLength={200}
                            placeholder={t('components.approvalMain.notesType')}
                            disabled={isArchived}
                          />
                        </Form.Item>

                        <Form.Item
                          name='comment'
                          label={
                            <StyledLabelWrapper>
                              <span>
                                {t('components.approvalMain.comments')}
                              </span>

                              <Tooltip
                                placement='right'
                                title={t(
                                  'components.approvalMain.commentTooltip'
                                )}
                              >
                                <InfoCircleOutlined />
                              </Tooltip>
                            </StyledLabelWrapper>
                          }
                        >
                          <TextArea
                            showCount
                            maxLength={200}
                            placeholder={t(
                              'components.approvalMain.commentType'
                            )}
                            disabled={isArchived}
                          />
                        </Form.Item>
                      </EntryDetails>
                    )
                  },
                  {
                    label: 'Activity log',
                    key: 'Activity log',
                    children: <Logs enrollmentId={tasterID} />
                  }
                ]}
              />
            )}
          </StyledCol>
        </Row>
      </Form>
    </Wrapper>
  )
}

export default ApprovalMain
