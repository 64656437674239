import styled from 'styled-components'
import { Modal } from 'antd'

export const StyledModalTitle = styled.div`
  display: flex;
  align-items: center;

  .textTitle {
    margin-left: 1rem;
  }
`

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-top-right-radius: 1.25em;
    border-top-left-radius: 1.25em;
  }

  .ant-modal-content {
    border-radius: 1.25em;
  }

  .ant-modal-body {
    padding: ${props => (props.padding ? '0 1.25rem 0 0' : '0')};

    > .ant-row {
      height: calc(100vh - 100px);
      overflow: auto;
    }
  }
`

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.marginBottom || 0};
`

export const Information = styled(FlexBetween)`
  line-height: 2rem;

  label {
    color: #8c8f9f;
    font-size: 0.875rem;
  }
`

export const TextMedium = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.875rem;
  font-weight: 500;
  padding-left: 0.5rem;

  &.red {
    color: #da2f58;
  }

  &.green {
    color: #4ab9bb;
  }
`

export const StyledLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .anticon {
    color: #367f98;
  }
`

export const TabsWrapper = styled.div`
  .ant-tabs {
    padding-top: 2rem;
    padding-left: 1rem;
  }
`

export const ReceiptsWrapper = styled.div`
  .select-bar {
    background: rgba(247, 250, 254, 0.9);
    border-radius: 8px;
    margin: 1.5rem 0;
  }
`
