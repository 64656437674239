import styled from 'styled-components'
import { Button as AntButton, Form } from 'antd'

export const Label = styled.label`
  span {
    margin-right: 0.5rem;
  }

  .anticon {
    color: #367f98;
  }
`

export const ButtonValid = styled(AntButton)`
  color: #dbdee5;
  background-color: #ffffff;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;

  &:hover,
  &:focus,
  &.active {
    color: #4ab9bb;
    background-color: #f0f9fd;
    border-color: #4ab9bb;
  }
`

export const ButtonInvalid = styled(AntButton)`
  color: #dbdee5;
  background-color: #ffffff;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem;

  &:hover,
  &:focus,
  &.active {
    color: #da2f58;
    background-color: #ffe7ec;
    border-color: #da2f58;
  }
`

export const FormItem = styled(Form.Item)`
  display: inline-block;
  margin-bottom: 0.75rem;
`
