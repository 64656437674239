import { Link } from 'react-router-dom'
import ExternalIcon from '@/assets/icons/ExternalIcon'
import { VideoContainer } from './styles'

const VideoHolder = ({ url }) => {
  const route = `/attachment/${url}?type=video`

  return (
    <VideoContainer>
      <video width="200" height="200" src={url} controls />

      {route && (
        <div className="externalButton">
          <Link to={route} target="_blank" rel="noopener noreferrer">
            <ExternalIcon />
          </Link>
        </div>
      )}
    </VideoContainer>
  )
}

export default VideoHolder
