import styled from 'styled-components'

export const Footer = styled.footer`
  text-align: center;
  padding: 2rem;
`

export const FooterText = styled.div`
font-family: 'DM Sans';
font-style: normal;
font-weight: 400;
font-size: 0.625rem;
line-height: 150%;
color: #73738D;
`
