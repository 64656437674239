import styled from 'styled-components'

export const Text = styled.div`
  font-family: 'Inter';
  font-style: normal;
  text-align: center;
  color: #94A2B3;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 0.875rem;
`
 