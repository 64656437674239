import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FilterSurveys from '..'
import { Dropdown, Button } from 'antd'
import { FilterOutlined } from '@ant-design/icons'

const ButtonSurveyFilters = () => {
  const [openFilter, setOpenFilter] = useState(false)
  const { t } = useTranslation()

  const handleOpenChange = flag => {
    setOpenFilter(flag)
  }

  return (
    <Dropdown
      overlay={<FilterSurveys setOpenFilter={setOpenFilter} />}
      open={openFilter}
      trigger={['click']}
      placement='bottomLeft'
      arrow
      onOpenChange={handleOpenChange}
    >
      <Button icon={<FilterOutlined />} type='text'>
        {t('components.survey.tabs.filters.filter')}
      </Button>
    </Dropdown>
  )
}

export default ButtonSurveyFilters
