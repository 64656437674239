import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CountryFilter from './Country'
import SurveyStatusFilter from './SurveyStatus'
import ProductFilter from '../SelectProduct'
import useCustomHistory from '@/hooks/useCustomHistory'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import {
  FilterWrapper,
  FilterHeader,
  FilterBody,
  FilterFooter,
  BodyContentWrapper
} from './styles'

const FilterSurveys = ({ setOpenFilter }) => {
  const { t } = useTranslation()
  const {
    location,
    addSearchKeyValue,
    deleteSearchKey,
    checkSearchKeyExisted,
    getValueBySearchKey
  } = useCustomHistory()

  const [country, setCountry] = useState([])
  const [products, setProducts] = useState([])
  const [surveyStatus, setSurveyStatus] = useState('All')

  useEffect(() => {
    setCountry([])
    setProducts([])
    setSurveyStatus('All')

    if (checkSearchKeyExisted('country')) {
      setCountry(getValueBySearchKey('country'))
    }
    if (checkSearchKeyExisted('products')) {
      setProducts(getValueBySearchKey('products'))
    }
    if (checkSearchKeyExisted('surveyStatus')) {
      setSurveyStatus(getValueBySearchKey('surveyStatus'))
    }
  }, [location])

  const handleSaveFilters = () => {
    addSearchKeyValue('country', country)
    addSearchKeyValue('products', products)
    addSearchKeyValue('surveyStatus', surveyStatus)
    deleteSearchKey('page')

    setOpenFilter(false)
  }

  const handleClearFilter = () => {
    setCountry([])
    setProducts([])
    setSurveyStatus('All')

    deleteSearchKey('country')
    deleteSearchKey('products')
    deleteSearchKey('surveyStatus')
    deleteSearchKey('page')

    setOpenFilter(false)
  }

  return (
    <FilterWrapper>
      <FilterHeader>
        <h3>{t('components.survey.tabs.filters.header')}</h3>
        <Button
          type="danger"
          size="small"
          icon={<CloseOutlined />}
          onClick={() => setOpenFilter(false)}
        ></Button>
      </FilterHeader>
      <FilterBody>
        <BodyContentWrapper>
          <CountryFilter country={country} setFilterCountry={setCountry} />
          <ProductFilter
            filterType="survey"
            products={products}
            setFilterProduct={setProducts}
          />
        </BodyContentWrapper>

        <SurveyStatusFilter
          surveyStatus={surveyStatus}
          setFilterSurveyStatus={setSurveyStatus}
        />
      </FilterBody>
      <FilterFooter>
        <Button type="text" onClick={handleClearFilter}>
          <strong>{t('components.survey.tabs.calander.clear')}</strong>
        </Button>
        <Button shape="round" onClick={handleSaveFilters}>
          {t('components.survey.tabs.filters.save')}
        </Button>
      </FilterFooter>
    </FilterWrapper>
  )
}

export default FilterSurveys
