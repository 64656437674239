import React from 'react'
import { useTranslation } from 'react-i18next'
import { COUNTRY_PHONE_CODES } from '@/utils/constants/countries'
import { Wrapper, Text, SelectBox } from './styles'

const CountryFilter = ({ country, setFilterCountry }) => {
  const { t } = useTranslation()

  const changeCountry = (value) => {
    setFilterCountry(value)
  }

  const filterOption = (input = '', option) => {
    const lowerCaseSearch = input.toLowerCase()
    const lowerCaseValue = option.value.toLowerCase()
    const lowerCaseLabel = option.children.toLowerCase()

    return (
      lowerCaseValue.includes(lowerCaseSearch) ||
      lowerCaseLabel.includes(lowerCaseSearch)
    )
  }

  return (
    <Wrapper>
      <Text>{t('components.survey.tabs.filters.country')}</Text>
      <SelectBox
        mode="multiple"
        placeholder="All"
        showArrow
        showSearch
        defaultValue={country}
        value={country}
        filterOption={filterOption}
        onChange={changeCountry}
      >
        {COUNTRY_PHONE_CODES.map((country) => (
          <SelectBox.Option key={country.code} value={country.code}>
            {country.name}
          </SelectBox.Option>
        ))}
      </SelectBox>
    </Wrapper>
  )
}
export default CountryFilter
