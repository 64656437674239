import React from 'react'
import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Label } from './styles'

const Element = ({ text, tooltip }) => {
  return (
    <Label>
      <label>{text}</label>

      {tooltip && (
        <Tooltip placement="right" title={tooltip}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </Label>
  )
}

export default Element
