import styled from 'styled-components'
import { Radio } from 'antd'

export const StyledWrapper = styled.div`
  background-color: #f7fafe;
  border: 1px solid #e5eaf4;
  border-radius: 1.25em;
  padding: 1rem;
  margin-bottom: 1rem;

  .answerHeader {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;

    .ant-form-item {
      margin: 0;

      .ant-radio-wrapper {
        margin: 0;
      }
    }
  }

  .answerTitle {
    margin-left: 0.625rem;
    .mainTitle {
      font-size: 0.875em;
      line-height: 1.25em;
      letter-spacing: 0.0075em;
      color: #272d4e;
    }

    .subTitle {
      font-size: 0.625rem;
      line-height: 0.875em;
      color: #8c8f9f;
    }
  }
`

export const AdditionalInformation = styled.div`
  border-bottom: 1px solid #e5eaf4;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
`

export const StyledSwitchRow = styled.div`
  display: flex;

  .ant-form-item {
    margin-bottom: 0.1rem;
  }
`

export const StyledIconRadio = styled(Radio)`
  .ant-radio-inner {
    display: none;
  }
`

export const StyledIconWrapper = styled.div`
  font-size: 1.25rem;
  cursor: pointer;

  .checkIcon {
    color: ${props => (props.value === 'approved' ? '#4ab9bb' : '#ccd2e3')};

    svg:hover {
      color: #4ab9bb;
    }
  }

  .closeIcon {
    color: ${props => (props.value === 'unapproved' ? '#da2f58' : '#ccd2e3')};

    svg:hover {
      color: #da2f58;
    }
  }
`

export const StyledApprovedContainer = styled.div`
  font-size: 0.625rem;

  span:nth-child(1) {
    color: #8c8f9f;
    margin-right: 0.25rem;
  }
`

export const StyledLabelWrapper = styled.div`
  margin-left: 1rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;

  .anticon {
    color: #367f98;
  }
`

export const StyledValueContainer = styled.div`
  display: flex;
  gap: 1rem;
  overflow-x: auto;
`
