import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'

import ModalValidation from '@/components/ModalValidation'
import { getEnrollmentAnswers, updateEnrollment } from './thunk'

const ModalValidationContainer = ({
  selectedEnrollment,
  setSelectedEnrollment,
  onNextOrClose,
  onClose,
  guideUrl,
  isFraudCheck,
  isPreRecruit,
  isValidateModalOpen,
  setIsValidateModalOpen
}) => {
  const dispatch = useDispatch()
  const authenticationReducer = useSelector(state => state.authentication)
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const handleRefresh = () => {
    history.push({
      search: searchParams.toString()
    })
  }

  const { tasterID, enrollmentInfoId, userId, survey_id, isRedFlagged } =
    selectedEnrollment || {}

  useEffect(() => {
    if (tasterID) {
      dispatch(getEnrollmentAnswers(tasterID))
    }
  }, [tasterID])

  const handleUpdate = (
    enrollmentPayload,
    enrollmentInfoPayload,
    userDetailPayload
  ) => {
    const payload = {
      surveyId: survey_id,
      surveyEnrollmentPayload: enrollmentPayload,
      surveyEnrollmentInfoPayload: {
        ...enrollmentInfoPayload,
        id: enrollmentInfoId
      },
      userDetailPayload: {
        ...userDetailPayload,
        id: userId,
        isRedFlagged
      },
      userId: authenticationReducer?.authenticatedUser?.id
    }
    if (tasterID && Object.keys(enrollmentPayload)?.length) {
      dispatch(updateEnrollment({ id: tasterID, payload })).then(result => {
        if (result.meta?.requestStatus === 'fulfilled') {
          message.success('Enrollment validated successfully')
          handleRefresh()
          onNextOrClose()
        }

        if (result?.error) {
          message.error('Enrollment validated failed. Please try again')
        }
      })
    }
  }

  // to fix form initialValues is not reset
  if (!selectedEnrollment) return <div></div>

  return (
    <ModalValidation
      selectedEnrollment={selectedEnrollment}
      setSelectedEnrollment={setSelectedEnrollment}
      guideUrl={guideUrl}
      isFraudCheck={isFraudCheck}
      isPreRecruit={isPreRecruit}
      onNextOrClose={onNextOrClose}
      onClose={onClose}
      onUpdate={handleUpdate}
      isValidateModalOpen={isValidateModalOpen}
      setIsValidateModalOpen={setIsValidateModalOpen}
    />
  )
}

export default ModalValidationContainer
