import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CommentIcon from '@/assets/icons/CommentIcon'
import ReceiptIcon from '@/assets/icons/ReceiptIcon'
import { StyledProductApproval, StyledApproval, StyledReceipt } from './styles'

const SideMenu = ({ showReceipts, setShowReceipts }) => {
  const { t } = useTranslation()
  const user = useSelector(state => state.authentication.authenticatedUser)

  return (
    <StyledProductApproval>
      <div className='product'>{t('components.sidebar.product')}</div>
      <StyledApproval
        onClick={() => setShowReceipts(false)}
        selected={showReceipts ? false : true}
      >
        <CommentIcon />
        <span>{t('components.sidebar.approval')}</span>
      </StyledApproval>

      <StyledReceipt
        className='receipts'
        onClick={() => setShowReceipts(true)}
        selected={showReceipts ? true : false}
      >
        <ReceiptIcon />
        <span>{t('components.sidebar.receipts')}</span>
      </StyledReceipt>
    </StyledProductApproval>
  )
}

export default SideMenu
