import React from 'react'
import { useTranslation } from 'react-i18next'
import { Footer, FooterText } from './styles'

const LayoutFooter = () => {
  const { t } = useTranslation()

  return (
    <Footer>
      <FooterText>{t('components.welcome.copyrightText')}</FooterText>

      <FooterText
        dangerouslySetInnerHTML={{
          __html: t('components.welcome.contactSupport', {
            email: 'support@flavorwiki.info'
          })
        }}
      />
    </Footer>
  )
}

export default LayoutFooter
