import { useMemo } from 'react'
import { Radio } from 'antd'
import { useSelector } from 'react-redux'

import { useLocation, useHistory } from 'react-router-dom'
import { Wrapper } from './styles'

const Button = () => {
  const location = useLocation()
  const history = useHistory()
  const user = useSelector(state => state.authentication.authenticatedUser)
  const paths = location.pathname.split('/')
  const section = paths[3]
  const id = paths[2]

  const value = useMemo(() => {
    return section === 'receipts' ? 'receipts' : 'entries'
  }, [section])

  const handleChange = e => {
    const value = e.target.value

    if (value === 'entries') {
      history.push(`/survey/${id}/enrollments`)
    }

    if (value === 'receipts') {
      history.push(`/survey/${id}/receipts`)
    }
  }

  return (
    <Wrapper>
      <span className='text'>View by: </span>

      <Radio.Group value={value} onChange={handleChange}>
        <Radio.Button value='entries'>Entries</Radio.Button>
        <Radio.Button value='receipts'>Receipts</Radio.Button>
      </Radio.Group>
    </Wrapper>
  )
}

export default Button
