import styled from 'styled-components'
import { Button as AntdButton } from 'antd'

export const Title = styled.h2`
  font-weight: 700;
  font-size: 1.25rem;
  color: #272d4e;
  margin-bottom: 2rem;
`

export const Wrapper = styled.section`
  text-align: center;
`

export const SectionTitle = styled.h4`
  font-weight: 400;
  font-size: 1rem;
  color: #8c8f9f;
  margin: 1.5rem 0 0.75rem 0;
`

export const PrimaryText = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  color: #272d4e;
`

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  color: #8c8f9f;
`

export const SecondaryText = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: #8c8f9f;
`

export const Button = styled(AntdButton)`
  border-radius: 6.25rem;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #367f98;
  padding: 0.375rem 1rem;

  &:hover {
    color: #ffffff;
    background: #367f98;
  }
`

export const SectionContent = styled.div`
  max-height: calc(100vh - 20rem);
  overflow-y: auto;
`
