import styled from 'styled-components'
import { Spin as AntdSpin } from 'antd'

export const Container = styled.div`
  padding: 1rem;
`

export const Date = styled.div`
  font-size: 0.625rem;
  font-weight: 400;
  color: #8c8f9f;
  margin-bottom: 0.5rem;
`

export const Line = styled.div`
  padding: 0.75rem 1rem;
  background: #eff3f9;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
`

export const Timestamp = styled.div`
  font-size: 0.625rem;
  font-weight: 400;
  color: #272d4e;
  margin-bottom: 0.125rem;
`

export const Content = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  color: #272d4e;
`

export const ValidatorName = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  color: #000000;
`

export const LogSentence = styled.div`
  font-size: 0.75rem;

  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

export const Valid = styled.span`
  font-weight: 500;
  color: #4ab9bb;
`

export const Invalid = styled.span`
  font-weight: 500;
  color: #da2f58;
`

export const FieldName = styled.span`
  font-weight: 500;
  color: #8c8f9f;
`

export const Spin = styled(AntdSpin)`
  width: 100%;
`
