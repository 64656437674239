export const getCurrentIndex = (receipts, enrollId) => {
  const currentIndex = receipts.findIndex(
    (object) => object.enrollmentid === enrollId
  )
  return currentIndex
}

export const isImage = (url) => /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)

export const isVideo = (url) => /\.(mov|mp4|webm|amv|mpg|mpeg|wmv)$/.test(url)
