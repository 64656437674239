import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import {
  getValidationsList,
  getValidationsListCount
} from '@/apis/validationAPI'
import useAuthentication from '@/hooks/useAuthentication'
import { notification, message } from 'antd'
import { CheckCircleTwoTone } from '@ant-design/icons'
import Table from '@/components/Tables/Enrollments'
import LoaderPage from '@/components/LoaderPage'
import { useEnrollments } from '@/contexts/enrollmentsContext'

const Container = ({ survey, isFraudCheck, isPreRecruit }) => {
  const { t } = useTranslation()
  const { authenticatedUser } = useSelector((state) => state.authentication)
  const { dataSource, setDataSource, pagination, setPagination } = useEnrollments()
  const { id } = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const authentication = useAuthentication()

  const [unprocessedCount, setUnprocessedCount] = useState(0)

  const [loading, setLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)

  const defaultQuery = {
    products: [],
    validators: [],
    selectedRows: [],
    containImage: 'all',
    redflagged: false,
    startDate: '',
    endDate: '',
    option: 'All',
    sort: 'DESC',
    validationStatus: 'all'
  }
  const defaultPageAndSearch = {
    page: 1,
    limit: 10,
    keyword: ''
  }

  const checkURLParams = (defaultQuery) => {
    if (searchParams.has('products')) {
      defaultQuery.products = searchParams.get('products').split(',')
    }
    if (searchParams.has('validators')) {
      defaultQuery.validators = searchParams.get('validators').split(',')
    }
    if (searchParams.has('containImage')) {
      defaultQuery.containImage = searchParams.get('containImage')
    }
    if (searchParams.has('redflagged')) {
      defaultQuery.redflagged = searchParams.get('redflagged') === 'true'
    }
    if (searchParams.has('sort')) {
      defaultQuery.sort = searchParams.get('sort')
    }

    if (searchParams.has('startDate')) {
      defaultQuery.startDate = searchParams.get('startDate')
    }
    if (searchParams.has('endDate')) {
      defaultQuery.endDate = searchParams.get('endDate')
    }
    if (searchParams.has('option')) {
      defaultQuery.option = searchParams.get('option')
    }

    if (searchParams.has('tab')) {
      defaultQuery.validationStatus = searchParams.get('tab')
    }
  }

  const checkPageAndSearch = (defaultPageAndSearch) => {
    if (searchParams.has('page')) {
      defaultPageAndSearch.page = searchParams.get('page')
    }

    if (searchParams.has('limit')) {
      defaultPageAndSearch.limit = searchParams.get('limit')
    }

    if (searchParams.has('keyword')) {
      defaultPageAndSearch.keyword = decodeURIComponent(
        searchParams.get('keyword')
      )
    }
  }

  const openNotification = () => {
    notification.open({
      message: t('components.table.notifications.dataInfo'),
      description: t('components.table.notifications.dataMessage'),
      duration: 3,
      icon: <CheckCircleTwoTone />
    })
  }

  const getTableData = () => {
    getValidationsList(
      defaultPageAndSearch.keyword,
      defaultQuery,
      defaultPageAndSearch.page,
      defaultPageAndSearch.limit,
      id
    )
      .then((data) => {
        setDataSource(data.data.data)
        setUnprocessedCount(data.data.unprocessedCount)
        setLoading(false)
        if (!firstLoad) {
          openNotification()
        } else {
          setFirstLoad(false)
        }
      })
      .catch((err) => {
        setLoading(false)
        message.error(
          t('components.table.notifications.errorInfo') + err.errorCode.message
        )
      })
  }

  const getPagination = () => {
    getValidationsListCount(
      defaultPageAndSearch.keyword,
      defaultQuery,
      defaultPageAndSearch.page,
      defaultPageAndSearch.limit,
      id
    )
      .then((res) => {
        setPagination(res.data.pagination.pagination)
      })
      .catch((err) =>
        message.error(t('components.table.notifications.errorPagination'), err)
      )
  }

  useEffect(() => {
    setLoading(true)
    setDataSource([])
    checkURLParams(defaultQuery)
    checkPageAndSearch(defaultPageAndSearch)
    getTableData()
    getPagination()
  }, [location])

  return (
    <>
      {loading && <LoaderPage />}
      <Table
        validationList={dataSource}
        unprocessedCount={unprocessedCount}
        pagination={pagination}
        userId={authenticatedUser?.id}
        guideUrl={survey?.guideUrl}
        isFraudCheck={isFraudCheck}
        isPreRecruit={isPreRecruit}
      />
    </>
  )
}

export default Container
