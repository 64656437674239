import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  StatusMainLayout,
  ValidWrapper,
  InvalidWrapper,
  UnprocessedWrapper,
  DateWrapper
} from '@/components/Tables/Enrollments/styles'
import { PrimaryText, SubTitle, SecondaryText, Button } from '../styles'
import { Wrapper } from './styles'

const Enrollment = ({ data }) => {
  const { t } = useTranslation()
  const {
    username,
    email,
    finishedat,
    validator,
    status,
    date,
    surveyid,
    surveyname
  } = data

  const searchParams = new URLSearchParams({
    keyword: encodeURIComponent(email),
    open: true
  }).toString()

  const renderStatus = (status, date) => {
    if (status === 'valid') {
      return (
        <StatusMainLayout>
          <ValidWrapper>{t('components.survey.tabs.valid')}</ValidWrapper>
          <DateWrapper>{date}</DateWrapper>
        </StatusMainLayout>
      )
    }

    if (status === 'invalid') {
      return (
        <StatusMainLayout>
          <InvalidWrapper>{t('components.survey.tabs.invalid')}</InvalidWrapper>
          <DateWrapper>{date}</DateWrapper>
        </StatusMainLayout>
      )
    }

    if (status === 'unprocessed' && date.includes('Due')) {
      return (
        <StatusMainLayout>
          <UnprocessedWrapper>
            {t('components.survey.tabs.unprocessed')}
          </UnprocessedWrapper>
          <DateWrapper>{date}</DateWrapper>
        </StatusMainLayout>
      )
    }

    return (
      <StatusMainLayout>
        <InvalidWrapper>{t('components.survey.tabs.overdue')}</InvalidWrapper>
        <DateWrapper>{date}</DateWrapper>
      </StatusMainLayout>
    )
  }

  return (
    <Wrapper>
      <div>
        <PrimaryText>{username}</PrimaryText>
        <SubTitle>{email}</SubTitle>
      </div>

      <div>
        <SecondaryText>FINISHED AT</SecondaryText>
        <PrimaryText>{moment(finishedat).format('MM-DD-YY HH:MM')}</PrimaryText>
      </div>

      <div>
        <SecondaryText>VALIDATOR</SecondaryText>
        <PrimaryText>{validator || 'N/A'}</PrimaryText>
      </div>

      <div>
        <SecondaryText>SURVEY</SecondaryText>
        <PrimaryText>{surveyname}</PrimaryText>
      </div>

      {renderStatus(status, date)}

      <Button>
        <Link to={`/survey/${surveyid}/enrollments?${searchParams}`}>View</Link>
      </Button>
    </Wrapper>
  )
}

export default Enrollment
