import styled from 'styled-components'

export const Wrapper = styled.div`
  text-align: end;

  .text {
    color: #8c8f9f;
    font-weight: 400;
    font-size: 0.875rem;
    margin-right: 0.75rem;
  }

  .ant-radio-button-checked {
    background: #f0f9fd;
  }
`
