import React from 'react'
import { Wrapper, Content } from './styles'
import { LoadingOutlined } from '@ant-design/icons'

const LoaderPage = () => {
  return (
    <Wrapper>
      <Content>
        <LoadingOutlined />
      </Content>
    </Wrapper>
  )
}

export default LoaderPage
