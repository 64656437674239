import styled from 'styled-components'
import { Table, Button as AntButton, Tooltip, Divider } from 'antd'
import {
  UserOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
  CopyOutlined,
  WarningOutlined
} from '@ant-design/icons'

export const ValidationTable = styled(Table)`
  width: 100%;
  white-space: pre;

  .ant-table-thead .ant-table-cell {
    color: #8c8f9f;
    font-size: 0.75em;
    text-align: left;

    .ant-table-selection {
      .ant-table-selection-extra {
        margin-top: 0.25rem;
      }
    }
  }

  .ant-table-tbody {
    color: #272d4e;
    font-size: 0.875em;
    letter-spacing: 0.0075em;
    text-align: left;
  }

  .ant-pagination-item-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.125rem 0.25rem;
    gap: 0.5rem;
    width: 1.25rem;
    height: 1.25em;
    background: #ffffff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(70, 79, 96, 0.16),
      0 0.125rem 0.25rem rgba(89, 96, 120, 0.1);
    border-radius: 0.625rem;
  }

  .ant-pagination-item {
    min-width: 22px px;
    height: 1.25em;
    line-height: 21px;
    border: 0;
    backgroud-color: #f7fafe;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 1.25em;
    border: 0;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 19px;
  }

  .ant-select-arrow {
    color: #272d4e;
  }

  .ant-table-column-title {
    z-index: 0;
  }

  .table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
    background-color: #f7fafe;
  }

  .table-row-red {
    background-color: #fff7fa;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: #367f98;
    border-radius: 0.25rem;
    border-color: #367f98;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #367f98;
  }
`

export const AntTooltip = styled(Tooltip)``

export const AntDivider = styled(Divider)`
  margin: 0 0 0.75em 0;
`

export const EmptyText = styled.div`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 1.25em;
  color: #a3a6b9;
`

export const EmptyDescription = styled.div`
  font-family: 'DM Sans';
  font-size: 1rem;
  color: #a3a6b9;
`

export const VectorIcon = styled.img``

export const ValidationTableWrapper = styled.div``

export const AntUserIcon = styled(UserOutlined)`
  color: #8c8f9f;
  stroke-width: 30px;
  stroke: #8c8f9f;
  cursor: pointer;
`

export const AntCheckOutlined = styled(CheckOutlined)`
  color: #1c9aba;
  margin-right: 0.625rem;
`

export const NameEmailWraper = styled.div`
  display: flex;
  flex-direction: column;
`

export const NameWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 500;
  line-height: 1.25em;
  color: #272d4e;
  cursor: pointer;
  &:hover {
    color: #1c9aba;
  }
`

export const EmailWrapper = styled.div`
  font-family: 'DM Sans';
  line-height: 1.25em;
  color: #8c8f9f;
  margin-top: 0.25rem;
  cursor: pointer;
  &:hover {
    color: #1c9aba;
  }
`

export const IconNameWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

export const FinishedAtWrapper = styled.div`
  font-family: 'DM Sans';
  color: #272d4e;
`

export const ValidWrapper = styled.div`
  width: fit-content;
  background: #e1fcef;
  color: #14804a;
  border-radius: 0.625rem;

  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.125rem 0.625rem;
`

export const InvalidWrapper = styled.div`
  width: fit-content;
  background: #fff0f6;
  color: #da2f58;
  border-radius: 0.625rem;

  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.125rem 0.625rem;
`

export const UnprocessedWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  background: #fff8d8;
  color: #c8a200;
  border-radius: 0.625rem;

  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
`

export const ValidatorWrapper = styled.div`
  font-family: 'DM Sans';
  color: #272d4e;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const ProductWrapper = styled.div`
  font-family: 'DM Sans';
  color: #272d4e;
  cursor: pointer;
`

export const BulletTextWrapper = styled.ul`
  padding: 0.625rem 0 0.625rem 0.875rem;
  margin: 0;
`

export const TitleWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.875em;
  letter-spacing: 0.0075em;
  color: #272d4e;
  margin: 0 0 0.625rem 0;
`

export const MainLayout = styled.div`
  padding: 1rem;
`

export const Layout = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TextWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 0.875em;
  color: #8c8f9f;
`

export const IdWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 0.875em;
  color: #272d4e;
  &:hover {
    color: #1c9aba;
  }
`

export const AntExclamationCircle = styled(ExclamationCircleOutlined)`
  color: #1c9aba;
`

export const AntCopyOutlined = styled(CopyOutlined)`
  color: #272d4e;
  &:hover {
    color: #1c9aba;
  }
`

export const TextIconWrapper = styled.div`
display: flex;
align-items: center;
gap 0.5rem
`

export const IdIconWrapper = styled.div`
display: flex;
align-items: center;
gap 0.5rem;
`

export const StatusMainLayout = styled.div`
  display: flex;
  flex-direction: column;
`

export const DateWrapper = styled.div`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 0.75em;
  line-height: 1rem;
  color: #272d4e;
  opacity: 0.6;
  margin-top: 0.5rem;
`

export const Escalation = styled.div`
  display: flex;
  justify-content: center;
  width: 5rem;
  background: #e7d5ff;
  color: #a969fc;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  border-radius: 0.625rem;
  padding: 0.125rem 0.625rem;
`

export const Title = styled.span`
  color: #ff471a;
`

export const Wrapper = styled.div`
  display: flex;
  gap: 3px;
`

export const AntWarningOutlined = styled(WarningOutlined)`
  color: #ff471a;
  margin-top: 3px;
`

export const Text = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
