import styled from 'styled-components'

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .anticon {
    color: #367f98;
  }
`
