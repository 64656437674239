import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1.5fr 1fr 0.5fr;
  grid-gap: 0.5rem;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 1rem 1.25rem;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`
