import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getProductsList } from '@/apis/surveyAPI'
import messageError from '@/utils/messageError'
import { Wrapper, Text, SelectBox } from './styles'

const ProductFilter = ({ filterType, products, setFilterProduct }) => {
  const { t } = useTranslation()
  const surveyInfoResponse = useSelector((state) => state.surveyInfo)
  const { survey } = surveyInfoResponse || {}
  const { products: enrollmentProducts } = survey || []

  const [productsList, setProductsList] = useState([])

  const fetchProductListData = async () => {
    getProductsList()
      .then(({ data }) => {
        setProductsList(data.data)
      })
      .catch(({ errorCode }) => {
        if (errorCode.message === 'Unauthorized') {
          errorCode = errorCode.message
        }

        messageError({
          modal: true,
          code: errorCode,
          defaultError: 'There was a problem with our server. Please try again or contact support.'
        })
      })
  }

  useEffect(() => {
    if (filterType === 'validation') {
      setProductsList(enrollmentProducts)
    } else {
      fetchProductListData()
    }
  }, [])

  const filterOption = (input = '', option) => {
    const lowerCaseSearch = input.toLowerCase()
    const lowerCaseValue = option.value.toLowerCase()
    const lowerCaseLabel = option.children.toLowerCase()

    return (
      lowerCaseValue.includes(lowerCaseSearch) ||
      lowerCaseLabel.includes(lowerCaseSearch)
    )
  }

  const handleProductsChange = (value) => {
    setFilterProduct(value)
  }

  return (
    <React.Fragment>
      <Wrapper>
        <Text>{t('components.survey.tabs.filters.product')}</Text>
        <SelectBox
          mode="multiple"
          placeholder="All"
          showArrow
          showSearch
          defaultValue={products}
          value={products}
          onChange={handleProductsChange}
          filterOption={filterOption}
        >
          {productsList.map((product) => (
            <SelectBox.Option key={product._id} value={product._id}>
              {product.name}
            </SelectBox.Option>
          ))}
        </SelectBox>
      </Wrapper>
    </React.Fragment>
  )
}

export default ProductFilter
