import { combineReducers } from 'redux'
import modalValidationReducer from '@/containers/ModalValidation/slice'
import authenticationReducer from './authentication'
import userReducer from './users'
import validationResponseReducer from './validationResponse'
import surveyInfo from './surveySlice'

const reducer = combineReducers({
  authentication: authenticationReducer,
  user: userReducer,
  validationResponse: validationResponseReducer,
  modalValidationReducer,
  surveyInfo
})

export default reducer
