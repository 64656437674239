import React from 'react'
import { useTranslation } from 'react-i18next'
import { CheckOutlined } from '@ant-design/icons'
import {
  MainLayout,
  SelectionHeaderMain,
  AntCheckbox,
  SelectedText,
  SelectAllText,
  SelectedMain,
  SetSelectedText,
  ValidText,
  InvalidText
} from './styles'

const HeaderSelected = ({
  unprocessedCount,
  unprocessedCountOnThisPage,
  handleChecbox,
  handleUpdate,
  isAllSelected,
  setIsAllSelected,
  selectedRows,
  setSelectedRows
}) => {
  const { t } = useTranslation()

  const handleValidate = option => () => {
    handleUpdate(option)
  }

  const handleTick = () => {
    if (unprocessedCountOnThisPage.length === selectedRows.length) {
      handleChecbox()
    } else {
      setSelectedRows(unprocessedCountOnThisPage.map(entry => entry.tasterID))
    }
  }

  return (
    <MainLayout>
      <SelectionHeaderMain>
        <AntCheckbox
          checked={unprocessedCountOnThisPage.length === selectedRows.length}
          onChange={handleTick}
        />
        <SelectedText>
          {unprocessedCountOnThisPage.length === selectedRows?.length ? (
            <>
              {t('component.validation.selected.entries', {
                unprocessedCountOnThisPage: unprocessedCountOnThisPage.length
              })}
            </>
          ) : (
            <>
              Select all {unprocessedCountOnThisPage.length} unprocessed entries
              on this page
            </>
          )}
        </SelectedText>
        {isAllSelected ? (
          <SelectAllText onClick={() => setIsAllSelected(false)}>
            <CheckOutlined /> All {unprocessedCount} entries have been selected
          </SelectAllText>
        ) : (
          <SelectAllText onClick={() => setIsAllSelected(true)}>
            {t('component.validation.unprocessed.entries', {
              unprocessedCount: unprocessedCount
            })}
          </SelectAllText>
        )}
      </SelectionHeaderMain>
      <SelectedMain>
        <SetSelectedText>
          {t('component.validation.setselectedentries')}
        </SetSelectedText>
        <ValidText onClick={handleValidate('valid')}>
          {t('components.survey.tabs.valid')}
        </ValidText>
        <SetSelectedText>{`/`}</SetSelectedText>
        <InvalidText onClick={handleValidate('invalid')}>
          {t('components.survey.tabs.invalid')}
        </InvalidText>
      </SelectedMain>
    </MainLayout>
  )
}

export default HeaderSelected
