import { GET, PATCH, POST } from '@/utils/axios'

const DEFAULT_HEADER = {
  useAuthorization: true
}

export const getUserCountRecords = async (id, surveyId) => {
  const url = `/users/${id}/count-records?surveyId=${surveyId}`
  return await GET(url, DEFAULT_HEADER)
}

export const updateUser = async (id, formData) => {
  const url = `/user-details/${id}`
  return await PATCH(url, formData, DEFAULT_HEADER)
}
export const blackListAllUsers = async users => {
  const url = `/user-details/blacklist-all`
  return await POST(url, users, DEFAULT_HEADER)
}
