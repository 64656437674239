import Flavorwiki from '@/components/Flavorwiki'
import SearchInput from '@/components/SearchInput'
import Sidebar from './Sidebar'
import User from './User'
import { Container, Content, Header, Main, LeftSide } from './styles'

const Layout = ({ children }) => {
  return (
    <Container>
      <Sidebar />

      <Content>
        <Header>
          <LeftSide>
            <Flavorwiki />
            <SearchInput />
          </LeftSide>
          <User />
        </Header>

        <Main>{children}</Main>
      </Content>
    </Container>
  )
}

export default Layout
