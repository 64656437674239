import React from 'react'
import { useTranslation } from 'react-i18next'
import Layout from '@/layouts/Public'
import FormSignin from '@/containers/FormSignin'
import TextTerms from '@/components/TextTerms'

const Welcome = () => {
  const { t } = useTranslation()

  return (
    <Layout
      title={t('components.welcome.title')}
      description={t('components.welcome.description')}
      mainComponent={<FormSignin />}
      extraRedirect={<TextTerms />}
    />
  )
}

export default Welcome
