import styled from 'styled-components'
import { Input as AntdInput } from 'antd'

export const Input = styled(AntdInput)`
  width: 20rem;
  background: transparent;
  border-radius: 6.25rem;
  border: 1px solid #e5eaf4;

  &:hover {
    background: #f0f9fd;
  }

  input {
    background: transparent;
  }

  .anticon {
    color: #367f98;
  }
`
