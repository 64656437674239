import React from 'react'
import { Section, SectionFooter } from './styles'

const ScreenMain = ({ mainComponent, extraRedirect }) => (
  <Section>
    {mainComponent}
    {extraRedirect && <SectionFooter>{extraRedirect}</SectionFooter>}
  </Section>
)

export default ScreenMain
