import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from './styles'
import src from '@/assets/images/dashboard-logo.png'

const Flavorwiki = () => {
  return (
    <Link to='/'>
      <Icon style={{ backgroundImage: `url(${src})` }} />
    </Link>
  )
}

export default Flavorwiki
