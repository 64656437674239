import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useValidateRules = () => {
  const { t } = useTranslation()

  const rules = useMemo(() => {
    return {
      email: [
        {
          type: 'email',
          message: t('components.welcome.email.email')
        },
        {
          required: true,
          message: t('components.welcome.email.required')
        },
        () => ({
          validator (_, email) {
            if (!email) return Promise.resolve()
            const hasPLus = email.includes('+')
            const isFW = email.includes('@flavorwiki.com')
            const isGL = email.includes('@gigalabs.co')
            if (hasPLus && !isFW && !isGL)
              return Promise.reject(t('components.welcome.email.invalid'))
            return Promise.resolve()
          }
        })
      ],

      password: [
        {
          required: true,
          message: t('components.welcome.password.required')
        },
        {
          min: 8,
          message: t('components.welcome.validate.min', { min: 8 })
        },
        {
          max: 30,
          message: t('components.welcome.validate.max', { max: 30 })
        }
      ]
    }
  }, [t])

  return rules
}

export default useValidateRules
