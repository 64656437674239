import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useCustomHistory from '@/hooks/useCustomHistory'
import { AntTabs } from './styles'

const TabsSurvey = () => {
  const { t } = useTranslation()
  const {
    addSearchKeyValue,
    deleteSearchKey,
    checkSearchKeyExisted,
    getValueBySearchKey,
    resetFiltersInUrl
  } = useCustomHistory()

  const [active, setActive] = useState('all')

  const onChange = (key) => {
    setActive(key)
    addSearchKeyValue('tab', key)
    deleteSearchKey('page')
    resetFiltersInUrl()
  }

  useEffect(() => {
    if (checkSearchKeyExisted('tab')) {
      setActive(getValueBySearchKey('tab'))
    }
  }, [active])

  return (
    <AntTabs
      activeKey={active}
      onChange={onChange}
      items={[
        {
          label: t('components.survey.tabs.all'),
          key: 'all'
        },
        {
          label: t('components.survey.tabs.unprocessed'),
          key: 'unprocessed'
        },
        {
          label: t('components.survey.tabs.noentries'),
          key: 'noentries'
        }
      ]}
    />
  )
}

export default TabsSurvey
