import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Form, Input } from 'antd'
import useValidateRules from '@/hooks/useValidateRules'
import useFormFields from '@/hooks/useFormFields'

const FormItemEmail = ({
  initialValue,
  disabled = false,
  autoComplete,
  onPressEnter
}) => {
  const location = useLocation()
  const rules = useValidateRules()
  const formFields = useFormFields()

  const initialLocalValue = useMemo(() => {
    const urlSearchParams = new URLSearchParams(location.search)

    if (initialValue) {
      return initialValue
    }

    if (urlSearchParams.has('email')) {
      return urlSearchParams.get('email')
    }

    return null
  }, [location])

  return (
    <Form.Item
      name='email'
      label={formFields.email.label}
      initialValue={initialLocalValue}
      rules={rules.email}
      validateFirst
    >
      <Input
        placeholder={formFields.email.placeholder}
        autoComplete={autoComplete}
        disabled={disabled}
        onPressEnter={onPressEnter}
      />
    </Form.Item>
  )
}

export default FormItemEmail
