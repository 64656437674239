import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import useCustomHistory from '@/hooks/useCustomHistory'
import ModalGuide from '@/components/ModalGuide'
import {
  Container,
  CaptionTagWrapper,
  Caption,
  CodeWrapper,
  TagWrapper,
  RouteWrapper,
  SurveyListText,
  AntRightOutlined,
  ValidationListText,
  EditSupportGuide,
  Text
} from './styles'

const CaptionEnrollment = ({ data }) => {
  const { t } = useTranslation()
  const { navigateToPage } = useCustomHistory()
  const user = useSelector(state => state.authentication.authenticatedUser)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const goBackToSurvey = () => {
    navigateToPage('/dashboard')
  }

  const onToggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <>
      <Container>
        <div>
          <CaptionTagWrapper>
            <Caption>
              {t('components.survey.entries.header')} ({data.name})
            </Caption>
            <TagWrapper>
              <Text>{t('components.survey.entries.live')}</Text>
            </TagWrapper>
          </CaptionTagWrapper>

          {data.management && <CodeWrapper>{data.management.code}</CodeWrapper>}

          <RouteWrapper>
            <SurveyListText onClick={goBackToSurvey}>
              {t('components.survey.entries.list')}
            </SurveyListText>
            <AntRightOutlined />
            <ValidationListText>
              {t('components.survey.entries.header')}
            </ValidationListText>
          </RouteWrapper>
        </div>
        <EditSupportGuide onClick={onToggleModal}>
          <span>Edit support guide</span>

          <EditOutlined />
        </EditSupportGuide>
      </Container>

      <ModalGuide survey={data} open={isModalOpen} onClose={onToggleModal} />
    </>
  )
}

export default CaptionEnrollment
