import { useMemo } from "react"
import { Form, Tooltip } from "antd"
import { useTranslation } from "react-i18next"

import {
  InfoCircleOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons"
import { FormItem, Label, ButtonValid, ButtonInvalid } from "./styles"

const Element = ({ form, isArchived, isPreRecruit, isFraudCheck, onChangeUniversal }) => {
  const { t } = useTranslation()
  const value = Form.useWatch(["validation"], form)


  const { validLabel, invalidLabel, tooltipText } = useMemo(() => {
    let validLabel = t("components.survey.tabs.valid")
    let invalidLabel = t("components.survey.tabs.invalid")
    let tooltipText = t("components.universalValidator.tooltip")

    if (isFraudCheck) {
      validLabel = "Fraud Check Valid"
      invalidLabel = "Fraud Check Invalid"
      tooltipText =
        "This universal validator has the ability to change the status of the enrollment to be fraud-check Valid or fraud-check Invalid in one click from any product entry."
    }

    if (isPreRecruit) {
      validLabel = "Pre Recruit Valid"
      invalidLabel = "Pre Recruit Invalid"
      tooltipText =
        "This universal validator has the ability to change the status of the enrollment to be pre-recruit Valid or pre-recruit Invalid in one click from any product entry."
    }

    return { validLabel, invalidLabel, tooltipText }
  }, [isFraudCheck, isPreRecruit])

  return (
    <FormItem
      name="validation"
      label={
        <Label>
          <span>{t("components.universalValidator.title")}</span>
          <Tooltip placement="right" title={tooltipText}>
            <InfoCircleOutlined />
          </Tooltip>
        </Label>
      }
    >
      <ButtonValid
        className={value === "valid" && "active"}
        disabled={isArchived}
        icon={<CheckCircleFilled />}
        onClick={() => {
          onChangeUniversal("valid")
        }}
      >
        {validLabel}
      </ButtonValid>

      <ButtonInvalid
        className={value === "invalid" && "active"}
        disabled={isArchived}
        icon={<CloseCircleFilled />}
        onClick={() => {
          onChangeUniversal("invalid")
        }}
      >
        {invalidLabel}
      </ButtonInvalid>
    </FormItem>
  )
}

export default Element
