import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useLocation } from 'react-router-dom'
import { getSurveysList, getSurveysListCount } from '@/apis/surveyAPI'
import LoaderPage from '@/components/LoaderPage'
import Table from '@/components/Tables/Surveys'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { notification, message } from 'antd'

const Container = () => {
  const { t } = useTranslation()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const [surveyList, setSurveyList] = useState([])
  const [pagination, setPagination] = useState({ limit: 10, page: 1, total: 0 })
  const [loading, setLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)

  const defaultUrl = {
    country: [],
    surveyStatus: 'All',
    surveyLive: 'all',
    products: [],
    startDate: '',
    endDate: '',
    option: 'All',
    sortCountry: '',
    sortValid: '',
    sortInvalid: ''
  }
  const defaultPageAndSearch = {
    page: 1,
    limit: 10,
    keyword: ''
  }

  const checkURLParams = defaultUrl => {
    if (searchParams.has('country')) {
      defaultUrl.country = searchParams.get('country').split(',')
    }
    if (searchParams.has('products')) {
      defaultUrl.products = searchParams.get('products').split(',')
    }
    if (searchParams.has('surveyStatus')) {
      defaultUrl.surveyStatus = searchParams.get('surveyStatus')
    }
    if (searchParams.has('startDate')) {
      defaultUrl.startDate = searchParams.get('startDate')
    }
    if (searchParams.has('endDate')) {
      defaultUrl.endDate = searchParams.get('endDate')
    }
    if (searchParams.has('option')) {
      defaultUrl.option = searchParams.get('option')
    }
    if (searchParams.has('tab')) {
      defaultUrl.surveyLive = searchParams.get('tab')
    }
    if (searchParams.has('sortCountry')) {
      defaultUrl.sortCountry = searchParams.get('sortCountry')
    }
    if (searchParams.has('sortValid')) {
      defaultUrl.sortValid = searchParams.get('sortValid')
    }
    if (searchParams.has('sortInvalid')) {
      defaultUrl.sortInvalid = searchParams.get('sortInvalid')
    }
  }

  const checkPageAndSearch = defaultPageAndSearch => {
    if (searchParams.has('page')) {
      defaultPageAndSearch.page = searchParams.get('page')
    }

    if (searchParams.has('limit')) {
      defaultPageAndSearch.limit = searchParams.get('limit')
    }

    if (searchParams.has('keyword')) {
      defaultPageAndSearch.keyword = searchParams.get('keyword')
    }
  }

  const openNotification = () => {
    notification.open({
      message: t('components.table.notifications.dataInfo'),
      description: t('components.table.notifications.dataMessage'),
      duration: 3,
      icon: <CheckCircleTwoTone />
    })
  }

  const getTableData = () => {
    getSurveysList(
      defaultPageAndSearch.keyword,
      defaultUrl,
      defaultPageAndSearch.page,
      defaultPageAndSearch.limit
    )
      .then(data => {
        setSurveyList(data.data.data)

        setLoading(false)
        if (!firstLoad) {
          openNotification()
        } else {
          setFirstLoad(false)
        }
      })
      .catch(err => {
        setLoading(false)
        message.error(
          t('components.table.notifications.errorInfo') +
            err?.errorCode?.message
        )
      })
  }
  const getPagination = () => {
    getSurveysListCount(
      defaultPageAndSearch.keyword,
      defaultUrl,
      defaultPageAndSearch.page,
      defaultPageAndSearch.limit
    )
      .then(response => {
        setPagination(response.data.pagination.pagination)
      })
      .catch(err =>
        message.error(t('components.table.notifications.errorPagination'), err)
      )
  }

  useEffect(() => {
    setLoading(true)
    setSurveyList([])
    checkURLParams(defaultUrl)
    checkPageAndSearch(defaultPageAndSearch)

    getTableData()

    getPagination()
  }, [location])

  return (
    <>
      {loading && <LoaderPage />}
      <Table surveyList={surveyList} pagination={pagination} />
    </>
  )
}

export default Container
